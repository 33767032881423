import { Circle, InfoWindow, Marker, Polygon } from '@react-google-maps/api'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { StyledInfoWindow } from '../Treeview/TreeviewStyledEle';
import { Box, Divider, FormLabel, Grid, Stack, Tab, Tabs, Typography, alpha, useTheme } from '@mui/material';
import Scrollbars from 'react-custom-scrollbars-2';


function RouteMarkers(props) {
    const { data } = props;
    const theme = useTheme();
    const [openMarkerId, setOpenMarkerId] = useState(false);
    let getMarkerData = (zoneDetails) => {
        setOpenMarkerId(zoneDetails);
    };

    const circleOptions = {
      fillColor:theme.palette.secondary.main,
      fillOpacity:.5,
      strokeWeight:0,
    }



    // return (
    //             <>
    //             {
    //                 data.map((item, index) => {
    //                     let number = item.sequenceNo.toString();
    //                     let coords = item.geoZoneType === 'Polygons' && JSON.parse(item.cordinates !== undefined ? item.cordinates : "");
                        
    //                     return(
    //                       item.geoZoneType !== 'Polygons' ?
    //                         <>
    //                         <Marker
    //                         position={{lat:parseFloat(item.latitude), lng:parseFloat(item.longitude)}}
    //                         label={{text:number, color:'#fff'}}
    //                         zIndex={9999}
    //                         onClick={() => getMarkerData(index)}
    //                         clickable
    //                         />
    //                          <Circle 
    //                             center={{lat:parseFloat(item.latitude), lng:parseFloat(item.longitude)}}
    //                             radius={item.radius}
    //                             options={circleOptions}
    //                          />
    //                 { openMarkerId === index &&
    //                     <InfoWindow
    //                     options={{
    //                       pixelOffset: new window.google.maps.Size(0, -80),
    //                     }}
    //                     position={{
    //                       lat: parseFloat(item.latitude),
    //                       lng: parseFloat(item.longitude),
    //                     }}
    //                     onCloseClick={() => getMarkerData(false)}
    //                   >
    //                     <StyledInfoWindow sx={{background:alpha(theme.palette.background.paper, .8)}}>
    //                     <Box
    //                           className='infowindowHHeader'
    //                           sx={{background:theme.palette.warning.main, width:'100%'}}
    //                       >
    //                          <Stack spacing={2} direction={'row'} divider={<Divider />} alignItems={'baseline'}>
    //                         <Box>
    //                           <Typography variant='caption'>Zone Name</Typography>
    //                           <Typography variant='subtitle2'>{item?.geoZoneName}</Typography>
    //                         </Box>
    //                     </Stack>
    //                       </Box>
    //                       <Box sx={{padding:'5px 15px', width:'100%'}}>
    //                       <Stack
    //                         direction="column"
    //                         justifyContent="space-between"
    //                         spacing={.5}
    //                       >
    //                         <Box sx={{ display: "flex" }}>
    //                           <Typography variant="caption" sx={{ width: "25%" }}>
    //                             Radius
    //                           </Typography>
    //                           <Typography variant="subtitle2">
    //                             {item?.radius} m
    //                           </Typography>
    //                         </Box>
    //                         <Box>
    //                           <Typography variant="caption">Address</Typography>
    //                           <Typography variant="subtitle2">
    //                             {item.address === undefined ? "" : item.address}
    //                           </Typography>
    //                         </Box>
    //                       </Stack>
    //                       </Box>
    //                     </StyledInfoWindow>
    //                     </InfoWindow>
    //                 }
                
                
    //                         </> : 
    //                         <>
    //                           <Marker
    //                             position={coords[0]}
    //                             label={{text:number, color:'#fff'}}
    //                             zIndex={9999}
    //                             onClick={() => getMarkerData(index)}
    //                             clickable
    //                         />
    //                         <Polygon
    //                           path={coords}
    //                           options={{ 
    //                             strokeColor: theme.palette.secondary.main,
    //                             fillColor: theme.palette.secondary.main,
    //                            }}
    //                         />
    //                         { openMarkerId === index &&
    //                     <InfoWindow
    //                     options={{
    //                       pixelOffset: new window.google.maps.Size(0, -80),
    //                     }}
    //                     position={coords[0]}
    //                     onCloseClick={() => getMarkerData(false)}
    //                   >
    //                     <StyledInfoWindow sx={{background:alpha(theme.palette.background.paper, .8)}}>
    //                     <Box
    //                           className='infowindowHHeader'
    //                           sx={{background:theme.palette.warning.main, width:'100%'}}
    //                       >
    //                          <Stack spacing={2} direction={'row'} divider={<Divider />} alignItems={'baseline'}>
    //                         <Box>
    //                           <Typography variant='caption'>Zone Name</Typography>
    //                           <Typography variant='subtitle2'>{item?.geoZoneName}</Typography>
    //                         </Box>
    //                     </Stack>
    //                       </Box>
    //                       <Box sx={{padding:'5px 15px', width:'100%'}}>
    //                       <Stack
    //                         direction="column"
    //                         justifyContent="space-between"
    //                         spacing={.5}
    //                       >
    //                         <Box>
    //                           <Typography variant="caption">Address</Typography>
    //                           <Typography variant="subtitle2">
    //                             {item.address === undefined ? "" : item.address}
    //                           </Typography>
    //                         </Box>
    //                       </Stack>
    //                       </Box>
    //                     </StyledInfoWindow>
    //                     </InfoWindow>
    //                 }
    //                         </>
    //                     )
    //                 })
    //             }
                
               
    //             </>
                
    // )

    const MapData = ({label, value}) => {
        return(
          <Stack>
            <Typography variant='caption' sx={{color:theme.palette.grey[600]}}>{label}</Typography>
            <Typography variant='subtitle2'>{value}</Typography>
          </Stack>
        )
    }

    const ZoneInfoTap = (props) => {
      const { zoneData, index, length } = props;
      const [tab, setTab] = useState(1);
        return(
          <Box>
              <Tabs value={tab} sx={{minHeight:'auto'}}>
                  <Tab label='Zone Summary' value={1} onClick={() => setTab(1)} sx={{minHeight:'auto', textTransform:'capitalize', py:1.2, fontSize:'14px'}}/>
                  {
                    index !== length -1 && <Tab label={`Between Summary (${index + 1} - ${index + 2})`} value={2} onClick={() => setTab(2)} sx={{minHeight:'auto', textTransform:'capitalize',  py:1.2, fontSize:'14px'}}/>
                  }
                  
              </Tabs>
              <Divider />
              <Box sx={{height:'200px'}}>
              <Scrollbars height={250}>
              {
                tab === 1 &&
                <Box sx={{px:1, py:.8}}>
                  <Grid container spacing={1}>
                      <Grid item lg={6}>
                          <MapData label={'Entry Time'} value={zoneData?.entryTime}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Exit Time'} value={zoneData?.exitTime}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Entry SOC (%)'} value={zoneData?.startSOC}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Exit SOC (%)'} value={zoneData?.endSOC}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Start Odometer (km)'} value={zoneData?.startOdometer}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'End Odometer (km)'} value={zoneData?.endOdometer}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Distance Travelled (km)'} value={zoneData?.distanceTraveled}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Avg Speed (km/h)'} value={zoneData?.avgSpeed}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Max Speed (km/h)'} value={zoneData?.maxSpeed}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Planned Duration (hh:mm)'} value={zoneData?.plannedDuration}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Actual Duration (hh:mm)'} value={zoneData?.actualDuration}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Duration Diff (hh:mm)'} value={zoneData?.durationDifference}/>
                      </Grid>
                  </Grid>
                </Box>
              }
              {
                tab === 2 && 
                <Box sx={{px:1, py:.8}}>
                  <Grid container spacing={1}>
                      <Grid item lg={12}>
                          <MapData label={'Zone Name'} value={zoneData.betweenSummary.tripDataName}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Start SOC(%)'} value={zoneData.betweenSummary.startSOC}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'End SOC(%)'} value={zoneData.betweenSummary.endSOC}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Efficiency'} value={zoneData.betweenSummary.efficiency}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Planned Duaration (hh:mm) '} value={zoneData.betweenSummary.plannedDuration}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Actual Duaration (hh:mm)'} value={zoneData.betweenSummary.actualDuration}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Duration Diff (hh:mm)'} value={zoneData.betweenSummary.durationDifference}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Start Odometer (km)'} value={zoneData.betweenSummary.startOdometer}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'End Odometer (km)'} value={zoneData.betweenSummary.endOdometer}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Distance Travelled (km)'} value={zoneData.betweenSummary.distanceTraveled}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Avg Speed (km/h)'} value={zoneData.betweenSummary.avgSpeed}/>
                      </Grid>
                      <Grid item lg={6}>
                          <MapData label={'Max Speed (km/h)'} value={zoneData.betweenSummary.maxSpeed}/>
                      </Grid>
                  </Grid>
                  {/* <Grid container spacing={1}>
                      <Grid item lg={4}>
                          <MapData label={'Energy Effici'} value={'-'}/>
                      </Grid>
                      <Grid item lg={4}>
                          <MapData label={'Battery Temp'} value={'-'}/>
                      </Grid>
                      <Grid item lg={4}>
                          <MapData label={'Max Battery Temp'} value={'-'}/>
                      </Grid>
                      <Grid item lg={4}>
                          <MapData label={'Avg Battery Temp'} value={'-'}/>
                      </Grid>
                      <Grid item lg={4}>
                          <MapData label={'Battery Current'} value={'-'}/>
                      </Grid>
                      <Grid item lg={4}>
                          <MapData label={'Load Condition'} value={'-'}/>
                      </Grid>
                  </Grid> */}
                </Box>
              }
              </Scrollbars>
              </Box>
          </Box>
        )
    }
    console.log('data', data);
    return (
      <>
      {
          data !== undefined && data !== null && data?.map((item, index) => {
              let number = index + 1;
              let order = number?.toString()
              //let coords = item.geoZoneType === 'Polygons' && JSON.parse(item.cordinates !== undefined ? item.cordinates : "");
              let latLng = item?.geoZoneLatLong?.split(',');
              let radius = item?.radiu
              return(
                <>
                  <Marker
                  position={{lat:parseFloat(item.latitude), lng:parseFloat(item.longitude)}}
                  label={{text:order, color:'#fff'}}
                  zIndex={9999}
                  onClick={() => getMarkerData(index)}
                  clickable
                  />
                  {
                    item?.zoneType === 'Circles' && 
                    <Circle 
                        center={{lat:parseFloat(item.latitude), lng:parseFloat(item.longitude)}}
                        radius={parseInt(item?.radius)}
                        options={circleOptions}
                    />
                  }
                  
                                    { openMarkerId === index &&
                        <InfoWindow
                        options={{
                          pixelOffset: new window.google.maps.Size(0, -80),
                        }}
                        position={{lat:parseFloat(item.latitude), lng:parseFloat(item.longitude)}}
                        onCloseClick={() => getMarkerData(false)}
                      >
                        <StyledInfoWindow sx={{background:alpha(theme.palette.background.paper, .8)}}>
                        <Box
                              className='infowindowHHeader'
                              sx={{background:theme.palette.success.main, width:'100%'}}
                          >
                             <Stack spacing={2} direction={'row'} divider={<Divider />} alignItems={'baseline'}>
                              <Box>
                                <Typography variant='caption'>Zone Name</Typography>
                                <Typography variant='subtitle2'>{item?.tripDataName}</Typography>
                              </Box>
                              <Box>
                                <Typography variant='caption'>Zone Category</Typography>
                                <Typography variant='subtitle2'>{item?.tripDataCategory}</Typography>
                              </Box>
                            </Stack>
                          </Box>
                          <ZoneInfoTap zoneData={item} index={index} length={data.length}/>
                        </StyledInfoWindow>
                        </InfoWindow>
                    }
                </>
              )
          })
      }
      
     
      </>
      
)
}

export default RouteMarkers