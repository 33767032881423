import { Checkbox, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useGetRouteTripForGeoZoneMutation } from '../../../store/slice/RouteTripSlice/RouteTripSlice';
import { useDispatch } from 'react-redux';
import { tripsList } from '../../../store/slice/ControlsSlice';
import moment from 'moment';
import { useSelector } from 'react-redux';

function TripList(props) {
    const { index, item, selectedVehicle, map } = props;
    const tripList = useSelector((state) => state.controls.tripsList);
    const [checked, setChecked] = useState(false);
    const [getTripZones, {data:tripZoneData, isLoading:tripZoneLoading, isSuccess:tripZoneSuccess}] = useGetRouteTripForGeoZoneMutation();
    const dispatch = useDispatch();
    let tripsPayload = {
        //vehicleId:selectedVehicle
    }
    const handleChecked = async (event, tripData) => {
        map.setZoom(map.zoom);
        setChecked(!checked);
        if(event.target.checked){
            tripsPayload.routeTripId = tripData.routeTripId;
            tripsPayload.startDate = moment(item.startTime, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
            tripsPayload.endDate =  moment( item.endTime, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
            let tripsZones = await getTripZones(tripsPayload);
            let tripObj = {
                id:tripData.routeTripId,
                data:tripsZones.data.data,
                startDate:moment(tripData.startTime, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                endDate:moment(tripData.endTime, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            }
            dispatch(tripsList([tripObj, 'add']));
        }else{
            dispatch(tripsList([tripData.id, 'remove']));
        }
       
    }


    

    console.log(tripList, item.routeTripId );
  return (
    <ListItem dense sx={{p:0,}}
        secondaryAction={
            <Checkbox 
                size='small'
                color='primary'
                checked={tripList.length > 0 ? tripList[0].id === item.routeTripId ? true : false : false}
                onChange={(event) => handleChecked(event, item)} 
            />
        }
    >
        <ListItemButton dense>
            <ListItemText 
                primary={<Typography variant='body2'>Trip {index + 1}</Typography>}
                secondary={<Typography variant='caption'>{item.startTime} to {item.endTime} </Typography>}
            />
        </ListItemButton>
    </ListItem>
  )
}

export default TripList