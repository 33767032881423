import { dashboardApi } from "../ApiSlice";

const AdminSlice = dashboardApi.injectEndpoints({
    endpoints: builder => ({
        //Upload Validate
        lisenceGen: builder.mutation({
            query: (body) => {
            return {
                url: "/license/generateLicenseKey",
                method: "POST",
                body: body,
            }},
        }),

        //create Dealer
        createDealer: builder.mutation({
            query: (body) => {
            return {
                url: "/Dealer/createDealerKYC",
                method: "POST",
                body: body,
            }},
        }),
         //Update Dealer
         updateDealer: builder.mutation({
            query: (body) => {
            return {
                url: "/Dealer/updateDealerKYCDetails",
                method: "POST",
                body: body,
            }},
        }),
        ///Dealer/getKYCFeed
        kycFeedList: builder.mutation({
            query: (body) => {
            return {
                url: "/Dealer/getKYCFeed",
                method: "POST",
                body: body,
            }},
        }),
         ///Dealer/getKYCFeed
         kycProcessedList: builder.mutation({
            query: (body) => {
            return {
                url: "/Dealer/getKYCProcessedFeed",
                method: "POST",
                body: body,
            }},
        }),
        //Save TargetDistance
        updateTargetDist: builder.mutation({
            query: (body) => {
            return {
                url: "/targetdistance/SaveOrUpdateTargetDistance",
                method: "POST",
                body: body,
            }},
        }),
        
         //get TargetDistance
         getTargetDist: builder.mutation({
            query: (body) => {
            return {
                url: "/targetdistance/getTargettedDistForEdit",
                method: "POST",
                body: body,
            }},
        }),
        // Vehicle
        getVehicleActivity: builder.mutation({
            query: (body) => {
            return {
                url: "/vehicle/getSingleVehicleHistory",
                method: "POST",
                body: body,
            }},
        }),
        // Vehicle/swapImeiForDevice
        swapImeiForDevice: builder.mutation({
            query: (body) => {
            return {
                url: "/vehicle/swapImeiForDevice",
                method: "POST",
                body: body,
            }},
        }),

         // User
         getUserActivity: builder.mutation({
            query: (body) => {
            return {
                url: "/user/singleUserHistory",
                method: "POST",
                body: body,
            }},
        }),

         // Organization
         getOrgActivity: builder.mutation({
            query: (body) => {
            return {
                url: "/organization/listOrganizationHistory",
                method: "POST",
                body: body,
            }},
        }),
    })
})
export const { useLisenceGenMutation, useCreateDealerMutation, useUpdateDealerMutation, useKycFeedListMutation ,useKycProcessedListMutation,useUpdateTargetDistMutation,useGetTargetDistMutation, useGetVehicleActivityMutation,useSwapImeiForDeviceMutation, useGetUserActivityMutation, useGetOrgActivityMutation} = AdminSlice;
