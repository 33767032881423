import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  List,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import { Menus } from "./ps-list";
import ProfileDetails from "./profileDetails";
import ChangePassword from "./ps-password";
import Alert from "./ps-alert";
import TreeView from "./ps-treeview";
import profileBG from "../../assets/images/profilePattern.png";
import { Paper } from "@mui/material";
import { useTheme } from "@mui/material";
import { StyledSidebarListBtn } from "../../theme/StyledEle";
import { useDispatch, useSelector } from "react-redux";
import { profileImg, snackService } from "../../store/slice/ControlsSlice";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import DocumentUpload from "./documentUpload"
import {
  useGetUserImageMutation,
  useUpdateUserImageMutation,
} from "../../store/slice/ProfileSlice/ProfileSlice";
import {useAuditLogMutation} from "../../store/slice/ApiSlice";
import ChangeCustomerSettings from "./CustomerSettings";


export default function ProfileSettings() {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData?.resellerId;
  let orgId = userData?.orgId;
  let userId = userData?.id;
  let orgName = userData?.orgName;
  let userName = userData?.userName;
  let roleId = userData?.roleId;
  let userOrgType = userData?.userOrgType;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  const [hideDetails, setHidedetails] = useState("Profile");
  const [userImg, setUserImg] = useState(null);
  const theme = useTheme();
  const isSuccess = useSelector((state) => state.controls.alert);
  const dispatch = useDispatch();

  const [
    getUserImage,
    {
      data: userImagedata,
      isLoading: getUserImageLoading,
      isSuccess: getUserImageSuccess,
    },
  ] = useGetUserImageMutation();
  
  useEffect(async () => {
    var payload = {};
    payload.id = userId;
    const response = await getUserImage(payload);
    setUserImg(`https://${response.data.data.userImage}`);
    dispatch(profileImg(`https://${response.data.data.userImage}`));
  },[]);

  useEffect(() => {
    if(getUserImageSuccess){
      dispatch(profileImg(`https://${userImagedata?.data?.userImage}`));
    }
  },[getUserImageSuccess])

  const handleChange = (event) => {
    setHidedetails(event.target.innerText);

    auditPayload.message = "Profile Settings > "+event.target.innerText;
    auditPayload.action = "Select";
    auditPayload.featureName = "Profile Settings";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };


  useEffect(() => {
    if (isSuccess.isSuccess) {
      setTimeout(() => dispatch(snackService([null, null, null])), 6000);
    }
  }, [isSuccess.isSuccess]);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
    zIndex: 999,
  });
  //Get Image call//

  //Update Image call//
  const [
    updateUserImage,
    {
      data: updateUserImagedata,
      isLoading: updateUserImageLoading,
      isSuccess: updateUserImageSuccess,
    },
  ] = useUpdateUserImageMutation();

  const handleImageUpload = async (e) => {
    setUserImg(URL.createObjectURL(e.target.files[0]));
    dispatch(profileImg(URL.createObjectURL(e.target.files[0])));
    const formData = new FormData();
    // var payload = {};
    // payload.userId = userId;

    formData.append("userId", userId);
    formData.append(`file`, e.target.files[0]);

    const response = await updateUserImage(formData);
    dispatch(
      snackService([
        response?.data?.resultMessage,
        response?.data?.resultText,
        true,
      ])
    );
  };

  return (
    <Box>
      <Box
        sx={{
          height: "150px",
          background: `url(${profileBG})`,
          pt: 10,
          px: 15,
          position: "sticky",
          top: "0px",
          zIndex: 1,
        }}
      ></Box>
      <Box
        maxWidth="lg"
        sx={{ margin: "auto", mt: -10, zIndex: 999, position: "relative", px:{'lx':0, 'lg':10, 'md':5}}}
      >
        <Typography variant="h6" color="#fff" gutterBottom>
          Profile Settings
        </Typography>
        <Paper sx={{ mt: 2 }}>
          <Grid container>
            <Grid item lg={3} md={4} sm={12}>
              <Box
                sx={{
                  px: 3,
                  py: 2,
                  borderRight: "1px solid",
                  borderColor: theme.palette.divider,
                }}
              >
                <Box sx={{ textAlign: "center", mb: 3 }}>
                  {/* <input type="file" className="overlay" /> */}

                  <Box
                    component="label"
                    sx={{
                      "&:hover .overlay": { opacity: 0.3 },
                      position: "relative",
                      cursor: "pointer",
                      display: "flex",
                      margin: "auto",
                      width: "fit-content",
                    }}
                  >
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(e) => handleImageUpload(e)}
                      accept='image/png,image/jpeg'
                    />
                    <Avatar
                      src="https://cdn-icons-png.flaticon.com/512/32/32339.png"
                      sx={{
                        width: 60,
                        height: 60,
                        margin: "auto",
                        mb: 1,
                        opacity: 0,
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 999,
                      }}
                      className="overlay"
                    />
                    <Avatar
                      src={
                        userImg === null
                          ? "https://www.svgrepo.com/show/382104/male-avatar-boy-face-man-user-3.svg"
                          : userImg
                      }
                      sx={{ width: 120, height: 120, margin: "auto", mb: 1 }}
                    />
                  </Box>

                  <Typography variant="h6">
                    {JSON.parse(localStorage.getItem("userData"))?.firstName}{" "}
                    {JSON.parse(localStorage.getItem("userData"))?.lastName}
                  </Typography>
                  <Chip
                    label={`${
                      JSON.parse(localStorage.getItem("userData"))?.userRole
                    }`}
                    color="secondary"
                    size="small"
                  />
                </Box>
                <Box>
                  <List>
                    {Menus.map((menu) => (
                      <StyledSidebarListBtn
                        className="settings"
                        key={menu?.id}
                        onClick={handleChange}
                        selected={menu.title === hideDetails && true}
                      >
                        <ListItemIcon
                        //onClick={handleChange}
                          sx={{
                            color:
                              menu.title === hideDetails && "#fff !important",
                              pointerEvents:'none'
                          }}
                        >
                          <menu.icon/>
                        </ListItemIcon>
                        <ListItemText primary={menu.title} />
                      </StyledSidebarListBtn>
                    ))}
                  </List>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={9}  md={8} sm={12}>
              <Box sx={{ p: 3 }}>
                {hideDetails === "Profile" && <ProfileDetails />}
                {hideDetails === "Password" && <ChangePassword />}
                {hideDetails === "Alert Preference" && <Alert />}
                {hideDetails === "Tree View Settings" && <TreeView />}
                {hideDetails === "Document Upload" && <DocumentUpload />}
               {hideDetails === "Customer Settings" && userOrgType !== 5 && userOrgType !== 3 && userOrgType !== 1  && <ChangeCustomerSettings />}   
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      {/* <Box>
        
      </Box> */}
      {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )}
    </Box>
  );
}
