import {
  Box,
  List,
  Toolbar,
  useTheme
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SidebarListItem,
  StyledSideBarBox,
  StyledSideBarDrawer
} from "../../../theme/StyledEle";
import { Menus } from "./Menus";

//Icons
import Scrollbars from "react-custom-scrollbars-2";

function SidebarNew(){
  const theme = useTheme();
  //Redux Color Theme
  const themeCustom = useSelector(
    (state) => state.controls.customizer.themeCustom
  );
  const sidebarBg = useSelector(
    (state) => state.controls.customizer.sidebar.primary
  );
  const sidebarText = useSelector(
    (state) => state.controls.customizer.sidebar.secondary
  );
  const mini = useSelector((state) => state.controls.customizer.mini);
  const authToken = useSelector((state) => state.controls.authToken);

  //Menu list
  // const [menuList, setMenuList] = useState([]);

  const menuList = JSON.parse(localStorage.getItem("menuList"));


  const userData = JSON.parse(localStorage.getItem("userData"));

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  //Mixin
  const sidebarBgImg = useSelector(
    (state) => state.controls.customizer.sidebar.backgroundImg
  );
  const openedMixin = () => ({
    width: theme.sidebar.width,
    //background: theme.palette.mode === 'light' ? theme.palette.primary.dark : theme.palette.background.paper,
    // background:
    //   themeCustom === false
    //     ? theme.palette.mode === "light" ? theme.palette.primary.dark : theme.sidebar.background
    //     : sidebarBg === null
    //     ? theme.palette.primary.dark
    //     : sidebarBg,
    background: theme.palette.mode === "light" ? theme.sidebar.background :'#000000',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    [theme.breakpoints.not("xl")]: {
      width: "180px",
    },
    overflowX: "hidden",
    position: "relative",
    "&:before": {
      position: "absolute",
      left: "0",
      right: "0",
      content: '""',
      width: "100%",
      height: "100%",
      backgroundImage: `url(${sidebarBgImg}) !important`,
      opacity: 0.2,
      backgroundPosition: "bottom",
    },
  });

  const closedMixin = () => ({
    width: theme.sidebar.width,
    //background: theme.palette.mode === 'light' ? theme.palette.primary.dark : theme.sidebar.background,
    // background:
    //   themeCustom === false
    //     ? theme.palette.mode === "light"
    //       ? theme.palette.primary.dark
    //       : theme.sidebar.background
    //     : sidebarBg === null
    //     ? theme.palette.primary.dark
    //     : sidebarBg,
    background: theme.palette.mode === "light" ? theme.sidebar.background : '#000000',
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "hidden",
    width: `calc(${theme.spacing(6)} + 3px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(7.5)} + 3px) `,
    },
    [theme.breakpoints.down("sm")]: {
      width: `0px`,
    },
  });

  // Drawer Style
  const drawerStyles = {
    transition: "all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
    ...(!mini && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
    ...(mini && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
  };

  //Icons

  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [collapseEl, setCollapseEl] = useState(null);
  const [openSub, setOpenSub] = useState(false);

  const handleChange = (panel) => (event) => {
    // setExpanded(title ? panel : false);
    setAnchorEl(event.currentTarget);
    setCollapseEl(panel);
    if (panel === collapseEl) {
      setOpenSub(!openSub);
    } else {
      setOpenSub(true);
    }
  };
  const onClose = () => {
    setOpenSub(false);
  };

  useEffect(() => {
    setOpenSub(false);
    setCollapseEl(null);
  },[mini, userData?.resellerId])
  
  return (
    location.pathname !== "/" &&
    location.pathname !== "/userActivation" &&
    location.pathname !== "/forgotPassword" &&
    location.pathname !== "/testRide" &&
    location.pathname !== "/publictrack/Tracking" && (
      <StyledSideBarDrawer
        variant="permanent"
        open={true}
        sx={drawerStyles}
        className="sidebarScroll"
    
      >
        <Toolbar />
        <Scrollbars style={{padding:'10px', height:'95%'}} autoHeightMin={'100%'}>
          <StyledSideBarBox sx={{mb:1}}>
            <Box>
              {/* <List> */}
              {menuList?.length > 0 &&
                menuList?.map((menu, index) => (
                  <Menus
                    number={index}
                    menuItem={menu}
                    themeCustom={themeCustom}
                    sidebarBg={sidebarBg}
                    sidebarText={sidebarText}
                    open={mini}
                    handleChange={handleChange}
                    colapseOpen={openSub}
                    colTitle={collapseEl}
                    onClose={onClose}
                    anchorEl={anchorEl}
                    key={index}
                  />
                ))}
            </Box>
            {/* </List> */}
            <Box className="logoutBox" sx={{mb:1}}>
              <List className="ul">
                <SidebarListItem
                  disableGutters
                  disablePadding
                  className="listItem"
                  
                >
                  {/* <StyledSidebarListBtn
                    onClick={handleCloseUserMenu1}
                    className="logoutButton"
                  >
                    <Tooltip title="Logout" placement="right" arrow>
                      <ListItemIcon sx={{ color: "#fff" }}>
                        <RiLogoutCircleRLine />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText sx={{ opacity: !mini && 0 }}>
                      Logout
                    </ListItemText>
                  </StyledSidebarListBtn> */}
                </SidebarListItem>
              </List>
            </Box>
          </StyledSideBarBox>
        </Scrollbars>
      </StyledSideBarDrawer>
    )
  );
}

export default memo(SidebarNew);
