import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  MenuItem,
  Button,
  FormControl,
  FormHelperText,
  CardActions,
  CardHeader,
  Box,
  Grid,
  CardContent,
  useTheme,
  Stack,
  Divider,
  Collapse,
  ListSubheader,
} from "@mui/material";
import {
  SoftButton,
  StyledCardShadow,
  StyledDropDown,
  StyledFormButtonsRow,
  StyledFormControl,
  StyledFormControlLabel,
  StyledTableTitle,
  StyledTextField,
} from "../../theme/StyledEle";
import userServices from "../../services/userServices";
import DraggableDialog from "../shared/DailogDrag";
import CommonDropDown from "./commonCreate";
import { BackDropLoader } from "../shared/StyledLoader";
import logServices from "../../services/logService";
import {
  useAuditLogMutation,
  useSaveUserMutation,
  useGetUserMutation,
  useUpdateUserMutation,
} from "../../store/slice/ApiSlice";
import OrganizationServices from "../../services/organizationServices";
import { snackService } from "../../store/slice/ControlsSlice";
import { useDispatch } from "react-redux";
import { PulseLoader } from "react-spinners";
import dropdownServices from "../../services/dropdownServices";

function CreateUserForm1({ editUser, onCancel }) {
  const re = /^[0-9\b]+$/;
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let roleId = userData.roleId;
  let userOrgType = userData.userOrgType;

  const theme = useTheme();

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  const [country, setCountry] = useState([]);
  const [roles, setRoles] = useState([]);
  const [commonDropDownSelData, setCommonDropDownSelData] = useState({});
  const [selectedOrgType, setSelectedOrgType] = useState(1);
  const [seltdLastOrgId, setSeltdLastOrgId] = useState(0);
  const [seltdResellerId, setSeltdResellerId] = useState(0);
  const [seltdCustomerId, setSeltdCustomerId] = useState(0);
  const [seltdFleetId, setSeltdFleetId] = useState(0);
  const [chkUser, setChkUser] = useState("");
  const [chkEmailId, setChkEmailId] = useState("");
  const [clickedval, setClickedval] = React.useState(false);
  const [dealers, setDealers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [fleets, setFleets] = useState([]);
  const [organization, setOrganization] = useState([]);
  const [orgType, setOrgType] = useState(userOrgType === 8?8:2);
  const [org, setOrg] = useState(0);
  const [dealer, setDealer] = useState(0);
  const [customer, setCustomer] = useState(0);
  const [fleet, setFleet] = useState(0);
  const [emailId, setEmailId] = useState(null);

  let url = window.location.origin;
  
  
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues:{
      roleId:'0',
    }
  });

  useEffect(async () => {
    if (editUser) {
      responseData();
    } else {
      auditPayload.message = "Admin > User > Create User";
      auditPayload.action = "View";
      auditPayload.featureName = "user";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
    }
    const { data: mobiledetails } = await OrganizationServices.loadMobCode();
    setCountry(mobiledetails);
    getOrganization();
    getDealer();
  }, []);
  const getOrganization = async () => {
    const resellerPayload = {
      value: "reseller",
      orgType: "2",
      resellerId: resellerId,
      dealerId: 0,
      roleId: roleId,
      id: resellerId,
      searchText: "",
    };
    const { data: resellerResponseNew } = await dropdownServices.getResellers(
      resellerPayload
    );
    const { content: orgnListJSON } = resellerResponseNew.data;

    setOrganization(orgnListJSON);
    setOrg(orgnListJSON.length > 0 ? orgnListJSON[0].key : 0);
  };

  const dealerListPayload = {
    value: "dealer",
    resellerId: resellerId,
    searchText: "",
    regionId: -1,
  };

  const getDealer = async () => {
    const { data: dealerListResponse } = await dropdownServices.getResellers(
      dealerListPayload
    );
    const { content: dealerList } = dealerListResponse.data;
    setDealers(dealerList);
    if(!search){
      setDealer(dealerList.length > 0 ? dealerList[0].key : 0);
    }
    getCustomer(dealerList.length > 0 ? dealerList[0].key : 0);
  };

  const customersListPayload = {
    value: "customer",
    resellerId: resellerId,
    searchText: "",
    paramValue:"vehicle"
  };

  const getCustomer = async (dealerId) => {
    customersListPayload.dealerId= dealerId;
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayload
    );
    const { content: customersList } = customerListResponse.data;
    setCustomers(customersList);
    if(!search){
      setCustomer(customersList.length > 0 ? customersList[0].key : 0);
    }
    setSearch(false);
    getFleet(customersList.length > 0 ? customersList[0].key : 0);
  };

  //Feelt
  
  const fleetListPayload = {
    value: "fleet",
    dealerId:"0",
    resellerId: resellerId,
    searchText: "",
  };

  const getFleet = async (customerId) => {
    fleetListPayload.customerId = customerId;
    const { data: fleetListResponse } = await dropdownServices.getResellers(
      fleetListPayload
    );
    const { content: fleetList } = fleetListResponse.data;
    setFleets(fleetList);
    if(!search){
      setFleet(fleetList.length > 0 ? fleetList[0].key : 0);
    }
    setSearch(false);
  }




  useEffect(() => {
    roleOption(orgType);
  }, [orgType]);

  const roleOption = (orgType) => {
    switch (orgType) {
      case 1:
        setRoles([
          // { title: "Super Admin", value: 1 },
          { title: "Fleet Admin", value: 2 },
          { title: "Fleet Supervisor", value: 3 },
          { title: "Fleet Executive", value: 4 },
          { title: "Driver", value: 15 },
        ]);
        break;
      case 2:
        if(roleId === 17){
          setRoles([
            { title: "Organization Admin", value: 6 },
            { title: "Organization Supervisor", value: 7 },
            { title: "Organization Executive", value: 8 },
            { title: "System", value: 17 },

          ]);
        }
        else{
          setRoles([
            { title: "Organization Admin", value: 6 },
            { title: "Organization Supervisor", value: 7 },
            { title: "Organization Executive", value: 8 }
       
          ]);
        }
        break;

      case 3:
        setRoles([
          { title: "Customer Admin", value: 9 },
          { title: "Customer Supervisor", value: 10 },
          { title: "Customer Executive", value: 11 },
        ]);
        break;
      case 4:
        setRoles([
          { title: "Agency Admin", value: 12 },
          { title: "Agency Supervisor", value: 13 },
          { title: "Agency Executive", value: 14 },
        ]);
        break;
      case 5:
        setRoles([
          { title: "Dealer Admin", value: 20 },
          { title: "Dealer Supervisor", value: 21 },
          { title: "Dealer Executive", value: 22 },
        ]);
        break;
        case 8:
          setRoles([
            { title: "Organization Admin", value: 6 },
            { title: "Organization Supervisor", value: 7 },
            { title: "Organization Executive", value: 8 },
            { title: "System", value: 17 },
            { title: "Rider", value: 37 },
          ]);
          break;
      default:
        setRoles([
          { title: "Dealer Admin", value: 20 },
          { title: "Dealer Supervisor", value: 21 },
          { title: "Dealer Executive", value: 22 },
        ]);
    }
  };
  // API Services
  const [
    getUser,
    {
      data: userResponse,
      isLoading: getUserLoading,
      isSuccess: getUserSuccess,
    },
  ] = useGetUserMutation();

  const [
    saveUserData,
    { data: saveData, isLoading: isSaveLoading, isSuccess: isSaveSuccess },
  ] = useSaveUserMutation();

  const [
    updateUserData,
    {
      data: updateData,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateUserMutation();

  const responseData = () => {
    var payload = {};
    payload.id = editUser.id;
    getUser(payload);
  };
  const dispatch = useDispatch();
  // const handlesmt = async (
  //   seltdOrgTypeId1,
  //   seltdResellerId1,
  //   seltdCustomerId1,
  //   seltdFleetId1,
  //   seltdLastOrgId1
  // ) => {
  //   setSeltdLastOrgId(seltdLastOrgId1);
  //   setSeltdResellerId(seltdResellerId1);
  //   setSeltdFleetId(seltdFleetId1);
  //   setSeltdCustomerId(seltdCustomerId1);
  //   setSelectedOrgType(parseInt(seltdOrgTypeId1));
  // };

  const onSubmit = (data) => {
    if (!editUser) {
      saveUser(data);
    } else {
      updateUser(data);
    }
  };

  const handleCancelClick = () => {
    onCancel();
  };
  const saveUser = async (obj) => {
    let lastorgid = 0;
    if (orgType === 2) {
      lastorgid = resellerId;
    } else if (orgType === 3) {
      lastorgid = customer;
    } else if (orgType === 5) {
      lastorgid = dealer;
    }else if(orgType === 1){
      lastorgid = fleet;
    }
    obj.orgId = lastorgid;
    obj.createdBy = userId;
    obj.resellerId = resellerId;
    obj.customerId = orgType === 1 ? customer : 0;
    obj.dealerId = dealer;
    obj.userOrgType = orgType;
    obj.path = url;
    obj.email = emailId;
    saveUserData(obj);
  };

  const updateUser = async (obj) => {
    obj.createdBy = userId;
    obj.id = editUser.id;
    obj.email = emailId;
    updateUserData(obj);
  };

  useEffect(() => {
    if (getUserSuccess) {
      if (userResponse.data !== null) {
        reset({
          firstName: userResponse.data.firstName,
          lastName: userResponse.data.lastName,
          userName: userResponse.data.userName,
          email: userResponse.data.email,
          mobile: userResponse.data.mobile,
        });
        setOrgType(userResponse.data.userOrgType);
        setEmailId(userResponse.data.email);
        if (editUser.clickedval === "view") {
          setClickedval(true);
        } else {
          setClickedval(false);
        }
        var commonDataSend = {};
        commonDataSend.orgType = userResponse.data.userOrgType.toString();
        commonDataSend.resellerId = userResponse.data.resellerId.toString();
        commonDataSend.orgId = userResponse.data.orgId;
        commonDataSend.fleetId = userResponse.data.orgId;
        commonDataSend.customerId = userResponse.data.customerId;
        commonDataSend.regionId = userResponse.data.regionId;
        commonDataSend.dealerId = userResponse.data.dealerId;
        commonDataSend.customerName = userResponse.data.customerName;
        commonDataSend.resellerName = userResponse.data.resellerName;
        commonDataSend.fleetName = userResponse.data.fleetName;
        setCommonDropDownSelData(commonDataSend);
      }
    }
  }, [getUserSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(
        snackService([
          updateData?.resultMessage,
          updateData?.resultText,
          isUpdateSuccess,
        ])
      );
      onCancel();
    } else if (isSaveSuccess) {
      dispatch(
        snackService([
          saveData?.resultMessage,
          saveData?.resultText,
          isSaveSuccess,
        ])
      );
      onCancel();
    }
  }, [isUpdateSuccess, isSaveSuccess]);


    let [search , setSearch] = useState(null);
    let [searchBy, setSearchBy] = useState(null);
    const searchOption = async (event, name) => {
      setSearchBy(name);
      setSearch(true);
      if(name === 'dealer'){
        dealerListPayload.searchText = event.target.value
        const { data: dealerListResponse } = await dropdownServices.getResellers(
          dealerListPayload
        );
        const { content: dealerList } = dealerListResponse.data;
        setDealers(dealerList);
      }else if(name === 'customer'){
        customersListPayload.dealerId = dealer;
        customersListPayload.searchText = event.target.value
        const { data: customerListResponse } = await dropdownServices.getResellers(
          customersListPayload
        );
        const { content: customersList } = customerListResponse.data;
        setCustomers(customersList);
      }else if(name === 'fleet'){
        customersListPayload.dealerId = 0;
        customersListPayload.customerId = customer;
        customersListPayload.searchText = event.target.value
        const { data: fleetListResponse } = await dropdownServices.getResellers(
          customersListPayload
        );
        const { content: fleetList } = fleetListResponse.data;
        setFleets(fleetList);
      }
    }

    useEffect(() => {
      if(search && searchBy === 'dealer'){
          setSearch(null);
          setSearchBy(null);
          getDealer();
      }
      else if(search && searchBy === 'customer'){
        setSearch(null);
        setSearchBy(null);
        getCustomer(dealer);
      }else if(search && searchBy === 'fleet'){
        setSearch(null);
        setSearchBy(null);
        getFleet(customer);
      }
    },[dealer, customer])


    // if(!search){
    //   if(searchBy === 'dealer'){
    //     setSearchBy(null);
    //     //getDealer('');
    //   }
    // }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <CommonDropDown
          handleSmt={handlesmt}
          commonDropDownSelData={commonDropDownSelData}
          editUser={editUser}
          clickedval={clickedval}
        /> */}

        <StyledCardShadow elevation={0} sx={{ mt: 2, my: 2 }}>
          <CardHeader
            title={<StyledTableTitle>Create User</StyledTableTitle>}
          />
          {!editUser && (
            <Box sx={{ padding: "15px" }}>
              <Collapse
                in={true}
                sx={{
                  border: `.5px solid ${theme.palette.divider}`,
                  borderRadius: "10px",
                }}
              >
                <Box sx={{ px: 2, py: 1, my: 0.5 }}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"end"}
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"end"}
                      spacing={1.5}
                      sx={{ Width: "100%" }}
                    >
                      {userOrgType !== 8 && (
                        <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                          <StyledFormControlLabel required>
                            Organization Type
                          </StyledFormControlLabel>
                          <StyledDropDown
                            size="small"
                            placeholder=""
                            value={orgType}
                            onChange={(event, newType) =>
                              setOrgType(event.target.value)
                            }
                          >
                            
                            <MenuItem value={2}>Organization</MenuItem>
                            <MenuItem value={5}>Dealer</MenuItem>
                            <MenuItem value={3}>Customer</MenuItem>
                            <MenuItem value={1}>Fleet</MenuItem>
                          </StyledDropDown>
                        </StyledFormControl>
                      )}
                      <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                        <StyledFormControlLabel required>
                          Organization
                        </StyledFormControlLabel>
                        <StyledDropDown
                          size="small"
                          placeholder=""
                          value={org}
                          onChange={(event, newCommond) =>
                            setOrg(event.target.value)
                          }
                        >
                          {organization.length > 0 &&
                            organization.map((option, index) => {
                              return (
                                <MenuItem key={index} value={option.key}>
                                  {option.value}
                                </MenuItem>
                              );
                            })}
                        </StyledDropDown>
                      </StyledFormControl>
                      {(orgType === 5 || orgType === 3 || orgType === 1) && (
                        <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                          <StyledFormControlLabel required>
                            Dealer
                          </StyledFormControlLabel>
                          <StyledDropDown
                            size="small"
                            placeholder=""
                            value={dealer}
                            onChange={(event, newPlan) => {
                              setDealer(event.target.value);
                              getCustomer(event.target.value);
                            }}
                            MenuProps={{
                              MenuListProps:{
                                autoFocus:false,
                                autoFocusItem:false
                              }
                            }}
                          >
                              <ListSubheader>
                                <StyledTextField size="small" fullWidth onChange={(event) => searchOption(event, 'dealer')} onKeyDown={(e) => e.stopPropagation()} />
                            </ListSubheader>
                            
                            {dealers.length > 0 &&
                              dealers.map((option, index) => {
                                return (
                                  <MenuItem key={index} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                );
                              })}
                          </StyledDropDown>
                        </StyledFormControl>
                      )}
                      {(orgType === 3 || orgType === 1) && (
                        <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                          <StyledFormControlLabel required>
                            Customer
                          </StyledFormControlLabel>
                          <StyledDropDown
                            size="small"
                            placeholder=""
                            value={customer}
                            onChange={(event, newTestPlan) => {
                              setCustomer(event.target.value)
                              getFleet(event.target.value)
                            }
                            }
                            MenuProps={{
                              MenuListProps:{
                                autoFocus:false,
                                autoFocusItem:false
                              }
                            }}
                          >
                              <ListSubheader>
                                <StyledTextField size="small" fullWidth onChange={(event) => searchOption(event, 'customer')} onKeyDown={(e) => e.stopPropagation()}/>
                              </ListSubheader>
                           
                            {customers.length > 0 &&
                              customers.map((option, index) => {
                                return (
                                  <MenuItem key={index} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                );
                              })}
                          </StyledDropDown>
                        </StyledFormControl>
                      )}
                      {orgType === 1 && (
                        <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                          <StyledFormControlLabel required>
                            Fleet
                          </StyledFormControlLabel>
                          <StyledDropDown
                            size="small"
                            placeholder=""
                            value={fleet}
                            onChange={(event, newTestPlan) =>
                              setFleet(event.target.value)
                            }
                            MenuProps={{
                              MenuListProps:{
                                autoFocus:false,
                                autoFocusItem:false
                              }
                            }}
                          >
                              <ListSubheader>
                                <StyledTextField size="small" fullWidth onChange={(event) => searchOption(event, 'customer')} onKeyDown={(e) => e.stopPropagation()}/>
                              </ListSubheader>
                           
                            {fleets.length > 0 &&
                              fleets.map((option, index) => {
                                return (
                                  <MenuItem key={index} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                );
                              })}
                          </StyledDropDown>
                        </StyledFormControl>
                      )}
                    </Stack>
                  </Stack>
                </Box>
              </Collapse>
            </Box>
          )}
          <CardContent>
            <Grid container spacing={3}>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    First Name
                  </StyledFormControlLabel>
                  <StyledTextField
                    error={errors.firstName && true}
                    size="small"
                    fullWidth
                    {...register("firstName", {
                      required: {
                        value: true,
                        message: "First Name required",
                      },
                    })}
                    disabled={clickedval}
                    onChange={(e) => setValue("firstName", e.target.value)}
                    helperText={errors.firstName && errors.firstName.message}
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Last Name
                  </StyledFormControlLabel>
                  <StyledTextField
                    error={errors.lastName && true}
                    size="small"
                    fullWidth
                    {...register("lastName", {
                      required: {
                        value: true,
                        message: "Last Name required ",
                      },
                    })}
                    disabled={clickedval}
                    onChange={(e) => setValue("lastName", e.target.value)}
                    helperText={errors.lastName && errors.lastName.message}
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    User Name [Eg: abc@gmail.com]
                  </StyledFormControlLabel>
                  <StyledTextField
                    error={errors.userName && true}
                    size="small"
                    fullWidth
                    {...register("userName", {
                      required: {
                        value: editUser ? false : true,
                        message: "Username required",
                      },
                      pattern: {
                        value: editUser ? '' : /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                        message: "Invalid Email Address",
                      },
                     
                      validate: {
                        emailAvailable: async(fieldValue) => {
                           var request = {};
                            request.userName = fieldValue.trim();
                            request.resellerId = resellerId;
                            const chkUserName = await userServices.chkUserName(request);
      
                          return (
                            !editUser && chkUserName.data.resultMessage === "UserName Already Exists" ? "UserName Already Exists" : true
                          );

                        },
                      },
                      
                    })}
                    disabled={clickedval || editUser ? true : false}
                    onChange={(e) => {
                      setValue("userName", e.target.value);
                      setEmailId(e.target.value);
                    }}
                    helperText={errors.userName && errors.userName.message}
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Country Code
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.countryId && true}
                    {...register("countryId", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return (
                          fieldValue !== "0" || " Please select the country"
                        );
                      },
                    })}
                    onChange={(e) => {
                      setValue("countryId", e.target.value);
                    }}
                    disabled={clickedval}
                    defaultValue={
                      editUser !== undefined ? editUser.countryId : "0"
                    }
                    size="small"
                    inputProps={{ maxLength: "6" }}
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {country.length > 0 &&
                      country.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>

                  {errors.countryId && (
                    <FormHelperText error={errors.countryId && true}>
                      {errors.countryId.message}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Mobile
                  </StyledFormControlLabel>
                  <StyledTextField
                    fullWidth
                    size="small"
                    error={errors.mobile && true}
                    {...register("mobile", {
                      required: {
                        value: true,
                        message: "Mobile number required",
                      },
                      minLength: {
                        value: 10,
                        message: "Invalid",
                      },
                      maxLength: {
                        value: 10,
                        message: "Invalid",
                      },
                    })}
                    disabled={clickedval}
                    onChange={(event) =>
                      event.target.value === "" || re.test(event.target.value)
                        ? setValue("mobile", event.target.value)
                        : setValue("mobile", "")
                    }
                    helperText={errors.mobile && errors.mobile.message}
                    inputProps={{ maxLength: "10" }}
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Email Id
                  </StyledFormControlLabel>
                  <StyledTextField
                    //error={errors.email && true}
                    size="small"
                    value={emailId}
                    fullWidth
                    // {...register("email", {
                    // required: {
                    //   value: true,
                    //   message: "Email Id requied",
                    // },
                    // pattern: {
                    //   value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    //   message: "Enter valid address",
                    // },
                    // validate: (fieldValue) => {
                    //   return (
                    //     chkEmailId !== fieldValue ||
                    //     "This email ID not in list"
                    //   );
                    // },
                    // })}
                    disabled={true}
                    // onChange={(e) => setValue("email", e.target.value)}
                    //helperText={errors.email && errors.email.message}
                  />
                </StyledFormControl>
              </Grid>

              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Roles
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.roleId && true}
                    {...register("roleId", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Please select roles";
                      },
                    })}
                    onChange={(e) => {
                      setValue("roleId", e.target.value);
                    }}
                    disabled={clickedval}
                    defaultValue={
                      editUser === undefined ? "0" : editUser.roleId
                    }
                    size="small"
                    // value={watch('roleId')}
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {roles.length > 0 &&
                      roles.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                  <FormHelperText error={errors.roleId && true}>
                    {errors.roleId?.message}
                  </FormHelperText>
                </StyledFormControl>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <StyledFormButtonsRow direction="row" sx={{ mt: 2 }} spacing={2}>
              <SoftButton
                size="small"
                onClick={handleCancelClick}
                color="error"
                className="error"
              >
                Cancel
              </SoftButton>
              {clickedval === false && (
                <SoftButton
                  size="small"
                  variant="contained"
                  type="submit"
                  className="success"
                >
                  {isSaveLoading || isUpdateLoading ? (
                    <PulseLoader
                      color={theme.palette.success.contrastText}
                      size={6}
                    />
                  ) : editUser ? (
                    "Update"
                  ) : (
                    "Submit"
                  )}
                </SoftButton>
              )}
            </StyledFormButtonsRow>
          </CardActions>
        </StyledCardShadow>
      </form>
    </Box>
  );
}

export default CreateUserForm1;
