import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import { StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import { CardContent, CardHeader, IconButton, Stack, Typography } from "@mui/material";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import { useFeedLogListMutation } from "../../store/slice/LogsSlice/LogsSlice";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useGetFeedDetailsMutation } from "../../store/slice/ReportSlice/ReportSlice";
import { useLocation, useNavigate } from "react-router-dom";
import FormDialog from "../../Utils/FormDialog";
import FeedLogModel from "../models/FeedLog.model";

const text =
  "Note: RAW feeds MUST have VALID Header. This is for security reasons. Feeds with Invalid Header will be moved to RAW REJECTED. All other feeds will be stored and displayed here.From RAW, feeds are validated and then moved to STAGING if validation is successful.";

const RawFeed = ({ data, payload, isLoadingOut }) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let navigate = useNavigate();
  let location = useLocation();
  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const theme = useTheme();
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  useEffect(async () => {
    auditPayload.message = "Logs > FeedLog > Raw Feed";
    auditPayload.action = "View";
    auditPayload.featureName = "FeedLog";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  }, []);


  const [getFeedDetails, {data:feedData, isLoading:isFeedLoading}] = useGetFeedDetailsMutation();
  const getFeedDetail = async (feed) => {
    navigate('#view');
    let payload = {
      "vehicleId": 0,
      "orgId": 0,
      "startDate": "string",
      "endDate": "string",
      "sortBy": "string",
      "orderdir": "string",
      "search": "string",
      "imei": "string",
      "sortId": 0,
      "url": 0,
      "page": 0,
      "size": 0,
      "resellerId": 0,
      "xlsfeed": "string",
      "gpsFeed": feed,
    }
    const res = await getFeedDetails(payload);
  }


  const columns = useMemo(
    () => [
      {
        Header: "Device Captured Date",
        accessor: "gpsDate",
        width:150,
      },

      {
        Header: "GpsFeed",
        accessor: "gpsFeed",
        width:800,
        Cell:(row)=>{
          let feedType = row.row.original.gpsFeed.split('|')[0];
          return(
            <Stack direction={'row'} alignItems={'center'}>
                <Typography>{row.row.original.gpsFeed}</Typography>
                 <IconButton size="small" color="primary" onClick={() => getFeedDetail(row.row.original.gpsFeed)}><MdOutlineRemoveRedEye /></IconButton>
            </Stack>
          )
      }
      },

      {
        Header: "Portal Received Date",
        accessor: "activityDate",
      },
      {
        Header: "Description",
        accessor: "message",
      },
    ],
    []
  );

  const [getFeedList, { data: rawFeedListdata, isLoading, isSuccess }] =
    useFeedLogListMutation();

  useEffect(() => {
    if (data !== undefined) {
      setResponseData([]);
      if (data.data !== null) {
        setResponseData(data.data.content);
        setTotalElements(data.data.totalElements);
        setTotalPages(data.data.totalPages);
      } else {
        setResponseData([]);
        setTotalElements(0);
        setTotalPages(0);
      }
    }
  }, [data]);

  const onChangeXcl = async () => {
    let xlsfeed = "rawfeed";
    try {
      payload.feedStage = xlsfeed;
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "FeedLog/FeedlogXLS.csv?vehicleId=" +
        payload.vehicleId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&imei=" +
        payload.imei +
        "&resellerId=" +
        payload.resellerId +
        "&xlsfeed=" +
        xlsfeed +
        "&url=" +
        payload.url;

      window.open(url);

    auditPayload.message = "Logs > FeedLog > Raw Feed > Export >Excel";
    auditPayload.action = "Download";
    auditPayload.featureName = "FeedLog";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
    } catch (error) {}
  };

  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      getFeedList(payload);
    } else if (payload.search.length === 0) {
      getFeedList(payload);
    }
  };
  //Page Change

  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getFeedList(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getFeedList(payload);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (rawFeedListdata.data !== null) {
        setResponseData(rawFeedListdata.data.content);

        setTotalElements(rawFeedListdata.data.totalElements);
        setTotalPages(rawFeedListdata.data.totalPages);
      }
    }
  }, [isSuccess]);


  return (
    <>
      <MouseOverPopover text={text} title={"Raw Feed"} />
      <Box>
        {/* <CanFeedDropDown
          onSubmit={onSubmit}
          isshowimei={true}
          callbackLoadFn={callbackLoadFn}
        />  */}

        <StyledCardShadow>
          <CardHeader title={<StyledTableTitle>Raw Feed</StyledTableTitle>} />
          <CardContent sx={{ py: 0 }}>
            <DataTable
              isLoading={isLoading || isLoadingOut}
              includeSearchBox={true}
              handleChange={handleSearch}
              onChangeXcl={onChangeXcl}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              //callbck={handleSort}
              totalPages={totalPages}
            />
          </CardContent>
        </StyledCardShadow>
        { 
          location.hash === '#view' && 
          <FormDialog title={'View Feed'} size="md" padding={false}>
              <FeedLogModel data={feedData} loading={isFeedLoading}/>
          </FormDialog>
        }
      </Box>
    </>
  );
};

export default RawFeed;
