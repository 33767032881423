import React, { useCallback, useState } from 'react'
import { StyledPageLayout, StyledToggleButton, StyledToggleButtonGroup } from '../../theme/StyledEle';
import { Breadcrumb } from '../shared/Breadcrumbs';
import MouseOverPopover from '../shared/PopOver';
import AlertDropdown from '../view-alerts/AlertsDropdown/AlertDropdown';
import { Box, useTheme } from '@mui/material';
import LisrUsers from './LisrUsers';

function NewAssingUsers() {

    const theme = useTheme();
    const [value, setValue] = useState(1);
    const text = "Email's which are already verified will be listed.";
    const getData = useCallback( async (data) => {

    },[])

  return (
    <StyledPageLayout>
        <Breadcrumb
        mainDiv="Alerts"
        subDiv="Assign Users & Alerts"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Assign Users & Alerts"} />
      <AlertDropdown getData={getData} isShowAll={false} />

        {/* Tab Show */}

        <Box sx={{width:'100%', margin:'auto', display:'flex', justifyContent:'center', my:1}}>
            <StyledToggleButtonGroup sx={{background:theme.palette.background.paper}} value={value}>
                <StyledToggleButton value={1}>Assign Users</StyledToggleButton>
                <StyledToggleButton value={2}>Assign Alerts</StyledToggleButton>
            </StyledToggleButtonGroup>
        </Box>

        <Box maxWidth={'md'} sx={{m:'auto', background:'#fff', p:2}} >
            {
                value === 1 && <LisrUsers />
            }
        </Box>

    </StyledPageLayout>
  )
}

export default NewAssingUsers