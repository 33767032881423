import { Avatar, Box, Button, CardMedia, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { RiDeleteBin6Line } from 'react-icons/ri';
import ReactImageUploading from 'react-images-uploading'

function ImageUpload({images, fileHandleChange}) {
    let theme = useTheme();

    const UploadImage = ({onImageUpload, dragProps}) => {
        return(
            <Box 
                onClick={onImageUpload}
                {...dragProps}
                sx={{
                    height:'120px',
                    width:'100%',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    border:`0.5px solid ${theme.palette.info.main}`,
                    borderRadius:'10px',
                    borderStyle:'dashed',
                    alignItems:'center'
                }}
            >   
                <CardMedia component={'img'} src='https://cdn-icons-png.flaticon.com/512/6788/6788461.png' sx={{height:'45%', width:'auto', mb:1}}/>
                <Typography variant='body2'>Image upload</Typography>
            </Box>
        )
    }
    
  return (
    <Box sx={{width:'100%'}}>
        <ReactImageUploading
            multiple = {true}
            maxNumber={69}
            onChange={fileHandleChange}
            dataURLKey='data_url'
            value={images}
        >   
           {({
                imageList,
                onImageUpload,
                isDragging,
                dragProps,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,

           }) => (
                <Box sx={{width:'100%'}}>
                    <UploadImage onImageUpload={onImageUpload} dragProps={dragProps}/>
                    <List>
                        {
                            imageList?.map((image, index) => {
                                return (
                                    <ListItem
                                        secondaryAction={
                                        <IconButton size='small' onClick={() => onImageRemove(index)}><RiDeleteBin6Line  /></IconButton>
                                        }
                                        sx={{borderBottom:`.5px solid ${theme.palette.divider}`}}
                                        >
                                        <ListItemAvatar>
                                            <Avatar src={image.data_url} variant='square' sizes='small' sx={{objectFit:'contain'}}/>
                                        </ListItemAvatar>
                                            <ListItemText primary={<Typography variant='body2'>{image.file?.name}</Typography>}/>
                                    </ListItem>
                                )
                            }
                            )
                        }
                        
                    </List>
                </Box>
           )}
        </ReactImageUploading>
    </Box>
  )
}

export default ImageUpload