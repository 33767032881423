import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import {
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItemButton,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { useTheme } from "@mui/styles";
import ReportDropDown from "../shared/ReportDropDown";
import { Box, ListItem, ListItemText } from "@mui/material";
import OrgSettingsService from "../../services/orgSettingsService";
import { alpha } from "@mui/system";
import { FormControl } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import AlertList from "./AlertList";

import {
  SoftButton,
  StyledPaperShadow,
  StyledTab,
  StyledTabsGroup,
  StyledTextField,
  StyledSwitch
} from "../../theme/StyledEle";
import AdminDropdown from "../AdminDropdown/AdminDropdown";
import { useLisenceGenMutation } from "../../store/slice/AdminSlice/AdminSlice";
import { useUpdateGeoZoneCountMutation, useUpdateHarshSettingsMutation, useGetAlertStatusListMutation, useGetAllAlertListMutation, useUpdateHealthInspectorMutation,
   useUpdateVehicleDisplayMutation, useReadVehicleDisplayMutation, useReadHealthInspectorStatusMutation, useReadGeoZoneCountMutation, useReadHarshSettingsMutation} from "../../store/slice/OrgSettingSlice/OrgSettingSlice"
import MapSettings from "./MapSettings";


const initialvalues = {
  vehicleNoDisplay: "",
  harshBrake: "",
  harshAcceleration: "",
  healthInspectorCancel: "",
  mapView: "",
  addressDisplay: "",
  geoCode: "",
  geoZoneCancel: "",
  kycStatusCancel: "",
};


export default function OrgSettings() {
  const [responseData, setResponseData] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [vehicleDisplay, setVehicleDisplay] = useState([]);
  const [mapDisplay, setMapDisplay] = useState([]);
  const [harshSetting, setHarshSetting] = useState([]);
  const [healthInspector, setHealthInspector] = useState([]);
  const [kycStatus, setKycStatus] = useState([]);
  const [geoCount, setGeoCount] = useState([]);
  const [harsh, setHarsh] = useState();
  const [harshAcc, setHarshAcc] = useState();
  const [assignedObj, setAssignedObj] = useState(initialvalues);
  //const [value, setValue] = React.useState(null);
  const [mapValue, setMapValue] = React.useState(null);
  const [addressValue, setAddressValue] = React.useState(null);
  const [geocodeValue, setGeocodeValue] = React.useState(null);
  const [expanded1, setExpanded1] = React.useState(true);
  const [expanded2, setExpanded2] = React.useState(true);
  const [expanded3, setExpanded3] = React.useState(true);
  const [enable, setDisable] = useState("2");
  const [payload, setPayload] = useState(null);

  const {
    register,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
  });


  //OrgSettings
  const [allAlertList, setAllAlertList] = useState([]);

  const userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let RoleId = userData.roleId;
  let userOrgType = userData.userOrgType;

  const handleChange = (id) => {
    if (id === "panel1a-header") {
      setExpanded1(!expanded1);
    } else if (id === "panel2a-header") {
      setExpanded2(!expanded2);
    } else if (id === "panel3a-header") {
      setExpanded3(!expanded3);
    }
  };
  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };
  const theme = useTheme();

 
  //Tab State
  const [tabValue, setTabValue]  = useState(0);

  //Tab Handle


  const change = (e,tabValue) => {
    setTabValue(tabValue);
  }

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };



  const [ createLisence, {data, isLoading, isSuccess:liGenSuccess} ]  =useLisenceGenMutation();

  const [ updateHarshSettings,  {data: harshData,isLoading: harshDataLoading,isSuccess: harshDataSuccess,isError: harshDataError,},] = useUpdateHarshSettingsMutation();

  const [ updateGeoZoneCount,  {data: geoZoneData,isLoading: geoZoneDataLoading,isSuccess: geoZoneDataSuccess,isError: geoZoneDataError,},] = useUpdateGeoZoneCountMutation();

  const [ updateHealthInspector,  {data: healthData,isLoading: healthLoading,isSuccess: healthSuccess,},] = useUpdateHealthInspectorMutation();

  const [ getAllAlertList,  {data: alertData,isSuccess:alertDataSuccess,},] = useGetAllAlertListMutation();

  const [ updateVehicleDisplay,  {data: vehicleData,isLoading: vehicleLoading,isSuccess: vehicleSuccess,},] = useUpdateVehicleDisplayMutation();
  
  const [ readVehicleDisplay,  {data: readVehicleData,isSuccess: readVehicleSuccess,},] = useReadVehicleDisplayMutation();

  const [ readHealthInspectorStatus,  {data: readHealthData,isSuccess: readHealthSuccess,},] = useReadHealthInspectorStatusMutation();

  const [ readHarshSettings,  {data: readHarshData,isSuccess: readHarshDataSuccess,},] = useReadHarshSettingsMutation();

  const [ readGeoZoneCount,  {data: readGeoZoneData,isSuccess: readGeoZoneSuccess,},] = useReadGeoZoneCountMutation();



  //License 
  const [licenseCount, setLicenseCount] = useState(1);

  //Harsh settings
  const [harshBrakeValue, setHarshBrakeValue] = useState(null);
  const [harshAccelerationValue, setHarshAccelerationValue] = useState(null);

  //GeoZone Count
  const [geoZoneCount, setGeoZoneCount] = useState(null);

 //Health Inspector Value
  const [healthInspectorVal, setHealthInspectorVal] = useState(0);
 
//Vehicle Display Value
const [vehicleDisplayVal, setVehicleDisplayVal] = useState(0);
const [vinDisplayVal, setVinDisplayVal] = useState(0);



  //handleLicenseCount
  const handleLicenseCount = (event) => {
    if(event.target.value > 0){
      setLicenseCount(event.target.value);
    }
  }

  //Handle Harsh Settings
  const handleHarshBrakeValue = (event) => {
   setHarshBrakeValue(event.target.value);
  }

  const handleHarshAccelerationValue = (event) => {
    
   }

   //handleGeoZoneCount
  const handleGeoZoneCount = (event) => {
    if(event.target.value > 0){
      setGeoZoneCount(event.target.value);
    }
  }
  
  const [formData, setformData] = useState(null);
  const getData = (data) => {
    var CommonPayload = {};
    CommonPayload.orgId = data.fleet;
    CommonPayload.customerId = data.customer;
    CommonPayload.resellerId = data.orgnization;
    CommonPayload.fleetOrgId = data.fleet;
    setPayload(CommonPayload);
    setformData(data);
    if(data.fleet !== '-1'){
      
      readVehicleDisplay(CommonPayload);
      readHealthInspectorStatus(CommonPayload);
      readHarshSettings(CommonPayload);
      readGeoZoneCount(CommonPayload);
      testCase();

    }
    getAllAlertList();

   
  }

  const testCase=() => {
    if(payload !== null && payload.orgId!=='-1'){
      reset({
        harshBrake:readHarshData.length>0 && (readHarshData[0].keyz==="HB" && readHarshData[1].keyz==="HA")?readHarshData[0].value:(readHarshData[0].keyz==="HA" && readHarshData[1].keyz==="HB"?readHarshData[1].value:0),
        harshAcceleration:readHarshData.length>0 && (readHarshData[0].keyz==="HA" && readHarshData[1].keyz==="HB")?readHarshData[0].value:(readHarshData[0].keyz==="HB" && readHarshData[1].keyz==="HA"?readHarshData[1].value:0),
        geoZoneCount:readGeoZoneData.length>0?readGeoZoneData[0].value:"0",
       });
    }
   
 }

  const generateLicense = () => {
      let payload = {
        'resellerOrgId':formData.orgnization,
        'noOfLicense':licenseCount,
      }
        createLisence(payload);    
  }

  const handleHarshUpdate = () => {
    var request={};
    request.orgId=payload.orgId;
    request.harshBrake=getValues("harshBrake");
    request.harshAcceleration=getValues("harshAcceleration");
    updateHarshSettings(request)
  }

  const handleGeozoneUpdate = () => {
    var request={};
    request.orgId=payload.orgId;
    request.geoZoneCount=getValues("geoZoneCount");
    updateGeoZoneCount(request)
  }

  const handleUpdateHealthInspector = () => {
    var request={};
    request.orgId=payload.orgId;
    request.status=healthInspectorVal;
    updateHealthInspector(request);
  }


  const handleUpdateVehicleDisplay = () => {
    var request={};
    request.fleetOrgId=payload.orgId;
    request.resellerOrgId=payload.resellerId;
    request.customerOrgId=payload.customerId
    request.vehicleNo=vehicleDisplayVal;
    updateVehicleDisplay(request);
  }

  
  const cancelHealthStatus = () => {
    var request={};
    request.fleetOrgId = payload.orgId;
    readHealthInspectorStatus(request);
  }

  const cancelGeoZoneCount = () => {
    readGeoZoneCount(payload);
    testCase();
  }

  const cancelHarshSettings = () => {
    readHarshSettings(payload);
    testCase();
  }


  const cancelVehicleDisplay = () => {
    readVehicleDisplay(payload);

  }

 

  useEffect(() => {
    if(liGenSuccess){
    }
  },[liGenSuccess])

  useEffect(() => {
    if(alertDataSuccess){
      setAllAlertList(alertData);
    }
  },[alertDataSuccess])

  
  useEffect(() => {
    if(readVehicleSuccess){
      setVehicleDisplayVal(readVehicleData.length!==0?readVehicleData[0].vehicleNoDisplay:0);
    }
  },[readVehicleSuccess])

  useEffect(() => {
    if(readHealthSuccess){
      setHealthInspectorVal(readHealthData.length>0?readHealthData[0].status:0);
    }
  },[readHealthSuccess])

  useEffect(() => {
    if(readHarshDataSuccess){
      setHarshAccelerationValue(readHarshData.length!==0 && readHarshData[0].keyz==="HA"?readHarshData[0].value:0);
      setHarshBrakeValue(readHarshData.length!==0 && readHarshData[0].keyz==="HB"?readHarshData[0].value:0);
    }
  },[readHarshDataSuccess])

  useEffect(() => {
    if(readGeoZoneSuccess){
      setHealthInspectorVal(readGeoZoneData.length!==0?readGeoZoneData[0].status:0);
    }
  },[readGeoZoneSuccess])
  const handleHealthSwitchChange = (e) => {
    if(e.target.checked===true){
      setHealthInspectorVal(1);
    }
    else{
      setHealthInspectorVal(0);
    }
   }

   const handleVehiclehSwitchChange = (e, value) => {
    if (value === 'vehicle'){
      if(e.target.checked===true ){
        setVehicleDisplayVal(1);
      }
      else{
        setVehicleDisplayVal(0);
      }
    }
  else{
    if(e.target.checked===true ){
      setVinDisplayVal(1);
    }
    else{
      setVinDisplayVal(0);
    }
  }
   }

  

  return (
    <Box>
      {/* <ReportDropDown /> */}
      <AdminDropdown getData={getData}/>
     

      {/* Show */}
      <Grid container sx={{my:2}} columnSpacing={3}>
          <Grid item lg={3}> 
              <StyledPaperShadow>
                <Grid item lg={12}> 
                    <Typography sx={{px:2}} variant="body2" gutterBottom> Organization Settings </Typography>
                      <StyledTabsGroup
                      orientation="vertical"
                      variant="scrollable"
                      value={tabValue}
                      onChange={change}
                      aria-label="Org Settings"
                      sx={{ borderRight: 1, borderColor: 'divider' }}
                      className="verticalTab"
                      >
                        <StyledTab label="License Generate" {...a11yProps(0)} />
                        <StyledTab label="Harsh Settings" {...a11yProps(1)} />
                        <StyledTab label="Geo Zone Count" {...a11yProps(2)} />
                        <StyledTab label="Application Alert" {...a11yProps(3)} />
                        <StyledTab label="Health Inspector" {...a11yProps(4)} /> 
                        <StyledTab label="Map Settings" {...a11yProps(5)} /> 
                       {/* <StyledTab label="Vehicle Display" {...a11yProps(5)} /> */}
                      </StyledTabsGroup>
                </Grid>
              </StyledPaperShadow>
          </Grid>
          {tabValue ===0 &&
          <Grid item lg={4}> 
              <TabPanel value={tabValue} key={0} index={0} className='tabsPanel'>
                  <StyledPaperShadow sx={{display:'block'}}>
                      <Typography gutterBottom sx={{mb:1}}>License Generate</Typography>
                      <Box>
                          <Grid container spacing={1}>
                              <Grid item lg={12}>
                                <FormLabel required>License No Count</FormLabel>
                              </Grid>
                              <Grid item lg={12}>
                                <StyledTextField 
                                    size="small"
                                    type="number"
                                    onChange={handleLicenseCount}
                                    value={licenseCount}
                                    fullWidth
                                />
                              </Grid>
                          </Grid>
                          <SoftButton variant="contained" color="success"  size="small" sx={{mt:1}} onClick={generateLicense}>Submit</SoftButton>
                      </Box>
                  </StyledPaperShadow>
              </TabPanel>
          </Grid>
}
{tabValue ===1 &&

          <Grid item lg={4}> 
              <TabPanel value={tabValue} key={1} index={1} className='tabsPanel'>
                  <StyledPaperShadow sx={{display:'block'}}>
                      <Typography gutterBottom sx={{mb:1}}>Harsh Settings</Typography>
                      <Box>
                          <Grid container spacing={1}>
                              <Grid item lg={12}>
                                <FormLabel required>Harsh Brake</FormLabel>
                              </Grid>
                              <Grid item lg={12}>
                                <StyledTextField 
                                    size="small"
                                    onChange={(event) => setValue(event.target.value)}
                                    fullWidth
                                    inputProps={{ maxLength: "2" }}
                                    {...register("harshBrake", )}
                                />
                              </Grid>
                              <Grid item lg={12}>
                                <FormLabel required>Harsh Acceleration</FormLabel>
                              </Grid>
                              <Grid item lg={12}>
                                <StyledTextField 
                                    size="small"
                                    onChange={(event) => setValue(event.target.value)}
                                    fullWidth
                                    inputProps={{ maxLength: "2" }}
                                    {...register("harshAcceleration",)}
                                />
                              </Grid>
                          </Grid>
                          <SoftButton variant="contained" color="success"  size="small" sx={{mt:1}} onClick={handleHarshUpdate}>Update</SoftButton>
                          <SoftButton variant="contained" color="success"  size="small" sx={{mt:1, mx:1}} onClick={cancelHarshSettings}>Cancel</SoftButton>
                      </Box>
                  </StyledPaperShadow>
              </TabPanel>
          </Grid>
}
{tabValue ===2 &&

<Grid item lg={4}> 
    <TabPanel value={tabValue} key={2} index={2} className='tabsPanel'>
        <StyledPaperShadow sx={{display:'block'}}>
            <Typography gutterBottom sx={{mb:1}}>Geo Zone Count</Typography>
            <Box>
                <Grid container spacing={1}>
                    <Grid item lg={12}>
                      <FormLabel required>Geo Zone Count</FormLabel>
                    </Grid>
                    <Grid item lg={12}>
                      <StyledTextField 
                          size="small"
                          onChange={(event) => setValue(event.target.value)}
                          fullWidth
                          inputProps={{ maxLength: "2" }}
                          {...register("geoZoneCount",)}
                          />
                    </Grid>
                </Grid>
                <SoftButton variant="contained" color="success"  size="small" sx={{mt:1}} onClick={handleGeozoneUpdate}>Update</SoftButton>
                <SoftButton variant="contained" color="success"  size="small" sx={{mt:1, mx:1}} onClick={cancelGeoZoneCount}>Cancel</SoftButton>
            </Box>
        </StyledPaperShadow>
    </TabPanel>
</Grid>
}
{tabValue ===3 &&

          <Grid item lg={9}> 
              <TabPanel value={tabValue} key={3} index={3} className='tabsPanel'>
                  <StyledPaperShadow sx={{display:'block'}}>
                      <Box>
                          <Grid container spacing={0}>
                              <Grid item lg={12}>
                                <AlertList datas={allAlertList} adminPayload={payload}/>
                              </Grid>
                          </Grid>
                      </Box>
                  </StyledPaperShadow>
              </TabPanel>
          </Grid>
}
{tabValue ===4 &&

<Grid item lg={4}> 
    <TabPanel value={tabValue} key={4} index={4} className='tabsPanel'>
        <StyledPaperShadow sx={{display:'block'}}>
            <Typography gutterBottom sx={{mb:1}}>Health Inspector</Typography>
            <Box>
                <Grid container spacing={1}>
                    <Grid item lg={12}>
                      <FormLabel required>Health Inspector</FormLabel>
                    </Grid>
                    <Grid
                              container
                              lg={12}
                              sx={{
                                borderBottom: `.5px solid ${alpha(
                                  theme.palette.divider,
                                  0.05
                                )}`,
                                px: 1,
                                py: 0.5,
                              }}
                            >
                              <Grid item xs={4}>
                                <ListItem dense={true}>
                                  <ListItemText primary="Health Inspector" />
                                </ListItem>
                              </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <StyledSwitch
                                      color="success"
                                      defaultChecked={healthInspectorVal === 0 ? false : true}
                                      name={`Health Inspector`}
                                      onChange={handleHealthSwitchChange}
                                    />
                                  </Grid>
                            </Grid>
                </Grid>
                <SoftButton variant="contained" color="success"  size="small" sx={{mt:1}} onClick={handleUpdateHealthInspector}>Update</SoftButton>
                <SoftButton variant="contained" color="success"  size="small" sx={{mt:1, mx:1}} onClick={cancelHealthStatus}>Cancel</SoftButton>
            </Box>
        </StyledPaperShadow>
    </TabPanel>
</Grid>
}
{tabValue ===5 &&
<MapSettings tabValue={tabValue} />
}
{/*tabValue ===5 &&

<Grid item lg={5}> 
    <TabPanel value={tabValue} key={5} index={5} className='tabsPanel'>
        <StyledPaperShadow sx={{display:'block'}}>
            <Typography gutterBottom sx={{mb:1}}>Vehicle Display</Typography>
            <Box>
                <Grid container spacing={1}>
                    <Grid item lg={12}>
                      <FormLabel required>Vehicle Display</FormLabel>
                    </Grid>
                    <Grid
                              container
                              lg={12}
                              sx={{
                                borderBottom: `.5px solid ${alpha(
                                  theme.palette.divider,
                                  0.05
                                )}`,
                                px: 1,
                                py: 0.5,
                              }}
                            >
                              <Grid item xs={4}>
                                <ListItem dense={true}>
                                  <ListItemText primary="Vehicle" />
                                </ListItem>
                              </Grid>
                                  <Grid
                                    item
                                    xs={8}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <StyledSwitch
                                      color="success"
                                      defaultChecked={vehicleDisplayVal === 0 ? false : true}
                                      name={`Vehicle`}
                                      onChange={(e) => {handleVehiclehSwitchChange(e, 'vehicle')}}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                <ListItem dense={true}>
                                  <ListItemText primary="Vin" />
                                </ListItem>
                              </Grid>
                                  <Grid
                                    item
                                    xs={8}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <StyledSwitch
                                      color="success"
                                      defaultChecked={vinDisplayVal === 0 ? false : true}
                                      name={`vin`}
                                      onChange={(e) => {handleVehiclehSwitchChange(e, 'vin')}}
                                    />
                                  </Grid>
                            </Grid>
                </Grid>
                <SoftButton variant="contained" color="success"  size="small" sx={{mt:1}} onClick={handleUpdateVehicleDisplay}>Update</SoftButton>
                <SoftButton variant="contained" color="success"  size="small" sx={{mt:1, mx:1}} onClick={cancelVehicleDisplay}>Cancel</SoftButton>
            </Box>
        </StyledPaperShadow>
    </TabPanel>
</Grid>
                                  */}


      </Grid>
    </Box>
  );
}
