 import { Box, Stack, Typography,} from '@mui/material'
import React,{useState,useEffect} from 'react'
import { SoftButton,StyledPaperShadow } from '../../theme/StyledEle'
import { FiPlus } from 'react-icons/fi'
import DrawerModel from '../../Utils/DrawerModel'
import { useNavigate } from 'react-router-dom'
import CreateFota from './CreateFota'
import FirmwareList from './GridView'
import { useDeleteFotaMutation, useGetFirmListMutation } from '../../store/slice/FotaSlice/FotaSlice'
import SingleDropdown from '../NewDropdown/SingleDropdown'
import { useDispatch } from 'react-redux'
import { snackService } from '../../store/slice/ControlsSlice'
import StyledModel from '../../Utils/StyledModel'


function ListFota() {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let orgType = userData.userOrgType;
  let userName = userData.userName;

  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const [firmwareList, setFirmwareList] = useState([]);
  const [payload, setPayload] = useState(null);
  const [fileType, setFileType] = useState("All");
  const [fileTypes, setFileTypes] = useState({
    content: [
      {
        key: "All",
        value: "All",
      },
      {
        key: "Apk",
        value: "Apk",
      },
      {
        key: "Configuration",
        value: "Configuration",
      },
      {
        key: "Zip",
        value: "Zip",
      },
    ],
  });
  const [commonFilter, setCommonFilter] = React.useState({
    delete: false,
  });
  const [deleteItem, setDeleteItem] = useState(null);

const navigate = useNavigate();
const getActionBtn = () => {
  if ("null" !== JSON.parse(localStorage.getItem("menuList")) && null !== JSON.parse(localStorage.getItem("menuList"))) {
  if (JSON.parse(localStorage.getItem("menuList")) !== null) {
    for ( let i = 0; i < JSON.parse(localStorage.getItem("menuList")).length; i++) 
    {
      if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) 
      {
        for (
          let j = 0;
          j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
          j++
        ) {
          if (
            JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
            "fota"
          ) {
            var dataStr = {};
          
            dataStr.delete = false;
        
            var strval;
            strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
              j
            ].button.split(",");
            for (var k = 0; k < strval.length; k++) {
              if (parseInt(strval[k]) === 3) {
                dataStr.delete = true;
              }
            }
            setCommonFilter(dataStr);
            break;
          }
        }
      }
    }
  } 
}
}
 //Slice Mution
 const [ getFirmwareList,  {data:FirmwareListData, isLoading:FirmwareListLoading, isSuccess:FirmwareListSuccess}] = useGetFirmListMutation();
 const [ deleteFota,  {data:deleteFotaData, isLoading:deleteFotaLoading, isSuccess:deleteFotaSuccess}] = useDeleteFotaMutation();
 let firmListPayload = {
 }

 const GetFirmware = async (fileValue) => {
  setNewPage(0);
  setRowsPerPage(25);
  firmListPayload.page = 0;
  firmListPayload.size = 25;
  firmListPayload.search = "";
  firmListPayload.orgId = orgId;
  firmListPayload.type = fileValue;

  setPayload(firmListPayload);
  getActionBtn()
  const GetFirmareResponse = await getFirmwareList(firmListPayload);
  if (
    GetFirmareResponse.data !== undefined &&
    GetFirmareResponse.data !== null
  ) {
    if (GetFirmareResponse.data.data.content !== null) {
      setFirmwareList(GetFirmareResponse.data.data.content);
      setTotalElements(GetFirmareResponse.data.data.totalElements);
      setTotalPages(GetFirmareResponse.data.data.totalPages);
    } else {
      setFirmwareList([]);
    }
  } else {
    setFirmwareList([]);
  }
};

//Onload Get Firmware List
useEffect(() => {
  GetFirmware('All');
}, []);


const GetFirmwareViaPayload = async (payload) => {
  const GetFirmareResponse = await getFirmwareList(payload);
  if (
    GetFirmareResponse.data !== undefined &&
    GetFirmareResponse.data !== null
  ) {
    if (GetFirmareResponse.data.data.content !== null) {
      setFirmwareList(GetFirmareResponse.data.data.content);
      setTotalElements(GetFirmareResponse.data.data.totalElements);
      setTotalPages(GetFirmareResponse.data.data.totalPages);
    } else {
      setFirmwareList([]);
    }
  } else {
    setFirmwareList([]);
  }
}

//handle pagination
const pageChange = async (newPage, rowsPerPage) => {
  setNewPage(newPage);
  setRowsPerPage(rowsPerPage);
  payload.page = newPage;
  payload.size = rowsPerPage;

  GetFirmwareViaPayload(payload)

};


  //Search Firmware
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      GetFirmwareViaPayload(payload)
    } else if (payload.search.length === 0) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      GetFirmwareViaPayload(payload)
    }
  };


  const getFileType = (fileValue) => {
    setFileType(fileValue);
    GetFirmware(fileValue)
  };


  const onDelete = (itemDeleted) =>{
    setDeleteItem(itemDeleted);
  
  }
  
  const deleteItems = () => {
    var payload={}
    payload.userId=userId;
    payload.id=deleteItem.id;
    deleteFota(payload);
  }
  const dispatch = useDispatch();
    useEffect(() => {
      if (deleteFotaSuccess) {
        setDeleteItem(null);
        dispatch(
          snackService([
            deleteFotaData?.resultMessage,
            deleteFotaData?.resultText,
            deleteFotaSuccess,
          ])
        );
        GetFirmware('All')
      }
    }, [deleteFotaSuccess]);

  return (
<>
    <Box>
        <StyledPaperShadow>
          <Stack direction={'row'} spacing={2} alignItems={'end'} justifyContent={'space-between'} sx={{width:'100%'}}>
          <SingleDropdown 
                  label={'Type'}
                  isSearch={false}
                  listItems={fileTypes}
                  handleChange={getFileType}
                  showAll={false}
                  value={fileType}
              />
              <Box>
                  <Stack direction={'row'} spacing={1}>
                      <SoftButton variant='contained' color='success' size='small' className='success-soft' startIcon={<FiPlus />} onClick={() => navigate('#create')}>
                          Create FOTA
                      </SoftButton>
                  </Stack>
              </Box>
          </Stack>
        </StyledPaperShadow>
        {/* Grid Start */}
        <StyledPaperShadow sx={{mt:2}}>
          <FirmwareList isLoading={FirmwareListLoading} data={firmwareList} totalElements={totalElements}
              totalPages={totalPages} newPage={newPage} rowsPerPage={rowsPerPage} pageChange={pageChange} handleSearch={handleSearch} onDelete={onDelete} commonFilter={commonFilter}/>
        </StyledPaperShadow>
        
        {/* Create Ticket */}
        <DrawerModel position={'right'} width={'35vw'} title={'Create FOTA'}>
            <CreateFota reload={GetFirmware}/>
        </DrawerModel>
        
    </Box>
     {deleteItem !== null && (
      <StyledModel data={deleteItem} deleteItem={deleteItems}>
        <Box sx={{ px: 2 }}>
          <Typography sx={{ textAlign: "center", my: 2 }}>
            File Name: {deleteItem.fileName}
          </Typography>
        </Box>
      </StyledModel>
    )}
  </>
  );
}

export default ListFota;
