import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import {
  Typography,
  Paper,
  Divider,
  Button,
  Grid,
  Stack,
  ButtonGroup,
  CardHeader,
  CardContent,
} from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
// import ReportDropDown from "../shared/ReportDropDown";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormLabel } from "@mui/material";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import exporticon from "../../assets/images/exporticon.png";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import DropDown from "../shared/DropDown";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import { FaFilePdf } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import {
  StyledCardShadow,
  StyledExportButtons,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import { HiFolderArrowDown } from "react-icons/hi2";
import addDays from "date-fns/addDays";
import { format } from "date-fns";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 180,
    },
  },
};
const status = ["5", "10", "15", "30"];

const text =
  " Idle report shows various instances when the vehicle was Idling beyond the threshold time. Idle status is when the vehicle is not moving but engine is running";

const IdleReport = () => {
  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [statusCon, setStatus] = useState(status[1]);
  const [vehicles, setVehicles] = useState([]);
  const [assignedval, setAssignedval] = useState([]);
  const [searchval, setSearchVal] = useState("");
  let [isshowAddress, setIsshowAddress] = useState(true);
  const [loading, setLoading] = useState("");
  let rowid = "";
  var address = "";
  const theme = useTheme();

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const showAddress = async (e) => {
    e.preventDefault();
    console.log(isshowAddress, e.target.value);

    if (isshowAddress === false) {
      isshowAddress = true;
    } else {
      isshowAddress = false;
    }

    var request = {};
    request.latLng = e.target.value;
    rowid = e.target.id;
    if (isshowAddress === true) {
      const responseData = (await ReportServices.getGeocodeAddress(request))
        .data;
      address = responseData;
      // setAddress(responseData);
    }
    setIsshowAddress(isshowAddress);
  };

  useEffect(async () => {
    await fetchIdleReportDetails();
  }, []);

  const labelStyle = {
    fontSize: theme.text.fontSize,
    color: "black",
    fontWeight: 600,
  };
  const menuStyles = {
    fontSize: theme.text.fontSize,
    lineHeight: "1",
  };
  const selectDropdown = {
    ".MuiOutlinedInput-input": {
      fontSize: theme.text.fontSize,
      padding: "1.5px 4px",
    },
  };

  let columns = useMemo(
    () => [
      {
        Header: "Vehicle No",
        accessor: "vehicleno",
      },
      {
        Header: "GPS Location (Click to view location in map)",
        accessor: "gpsLocation",
        // Cell: ({ row }) => <Button id ={row.original.vehicleno} >{row.original.gpsLocation}</Button>
        Cell: ({ row }) => (
          <>
            <ButtonAlign
              id={row.index}
              value={row.original.gpsLocation}
              onClick={showAddress}
            >
              {isshowAddress === false &&
              rowid.toString() === row.index.toString()
                ? "LatLong"
                : "Address"}
            </ButtonAlign>
            <span>
              {" "}
              {isshowAddress === false &&
              rowid.toString() === row.index.toString()
                ? address
                : row.original.gpsLocation}{" "}
            </span>
            {/* <span> {row.original.gpsLocation} </span> */}
          </>
        ),
      },
      {
        Header: "Start Time",
        accessor: "startDate",
      },
      {
        Header: "End Time",
        accessor: "endDate",
      },
      {
        Header: "Idle Time(hh:mm)",
        accessor: "idleminsinhrs",
      },
    ],
    []
  );

  var globalOrgId = 1;
  //  var globaluserId = 1;
  //  var globalRoleId = 0;
  var globalJson = {};
  var globalCustomerId = 0;
  var globalOrgType = 2;

  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    //  globaluserId = globalJson.id;
    //  globalRoleId = globalJson.roleId;
    globalCustomerId = globalJson.customerId;
  }
  var loadrequest = {};
  const setloadvalues = () => {
    switch (globalOrgType) {
      case 8: // login as superadmin and reseller
      case 2:
        loadrequest.regionId = -1;
        loadrequest.resellerId = globalOrgId;
        loadrequest.dealerId = -1;
        loadrequest.customerId = -1;
        loadrequest.dealerId = -1;
        loadrequest.orgId = -1; // fleet
        loadrequest.vehicleId = -1;

        break;
      // case 2: // login as reseller
      //     loadrequest.regionId= -1;
      //     loadrequest.reselId = globalOrgId;
      //     loadrequest.dealerId= -1;
      //     loadrequest.customerId = -1;
      //     loadrequest.dealerId= -1;
      //     loadrequest.orgId = -1; // fleet
      //     loadrequest.vehicleId = -1;
      //      break;

      case 5: // login as Dealer
        loadrequest.regionId = 0;
        loadrequest.dealerId = globalOrgId;
        loadrequest.customerId = -1;
        loadrequest.orgId = -1; // fleet
        loadrequest.vehicleId = -1;

        break;
      case 3: // login as customer
        loadrequest.regionId = 0;
        loadrequest.customerId = globalOrgId;
        loadrequest.dealerId = 0;
        loadrequest.orgId = -1; // fleet
        loadrequest.vehicleId = -1;

        break;
      case 1: // login as fleet
        loadrequest.regionId = 0;
        loadrequest.customerId = globalCustomerId;
        loadrequest.dealerId = 0;
        loadrequest.orgId = globalOrgId; // fleet
        loadrequest.vehicleId = -1;

        break;
    }
  };

  const fetchIdleReportDetails = async () => {
    // setNewPage(newPage);
    // setRowsPerPage(rowsPerPage);
    setloadvalues();
    loadrequest.size = rowsPerPage;
    loadrequest.page = newPage;

    const fromDate =
      format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00";
    const toDate = format(addDays(new Date(), -1), "yyyy-MM-dd") + " 23:59:59";

    loadrequest.search = "";
    loadrequest.startDate = fromDate;
    loadrequest.endDate = toDate;
    loadrequest.sortBy = "desc";

    setAssignedval(loadrequest);

    const responseData = (await ReportServices.IdleReport(loadrequest)).data;
    setResponseData(responseData.data.content);
    setLoading(responseData.resultText);
    setTotalElements(responseData.data.totalElements);
    setTotalPages(responseData.data.totalPages);
  };
  const callbackChangePage = async (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    // var request = {};
    // request.orgId = 1;
    // request.vehicleId = 25909;
    loadrequest = assignedval;
    loadrequest.size = rowsPerPage;
    loadrequest.page = newPage;

    setAssignedval(loadrequest);
    const responseData = (await ReportServices.IdleReport(loadrequest)).data
      .data;
    setResponseData([]);
    setResponseData(responseData.content);
    setLoading(responseData.resultText);
    // setTotalElements(5);
    // setTotalPages(0);
    setTotalElements(responseData.totalElements);
    setTotalPages(responseData.totalPages);
  };

  const onChangeXcl = async () => {
    //  console.log(assignedval);
    loadrequest = assignedval;
    // var request = {};
    // request.orgId = 1;
    // request.vehicleId = 25909;
    // request.size = 1;
    // request.page = 25;
    // request.startDate = "2022-10-02 00:00:00";
    // request.endDate = "2022-10-02 23:59:59";
    // request.sortBy = "desc";
    // request.threshold = 5;
    // request.startOdometer = "string";
    // request.endOdometer = "string";

    loadrequest.size = 1;
    loadrequest.page = 25;

    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "IdleReport/idleReportXLS.xls?regionId=" +
        loadrequest.regionId +
        "&resellerId=" +
        loadrequest.resellerId +
        "&size=" +
        loadrequest.size +
        "&page=" +
        loadrequest.page +
        "&startDate=" +
        loadrequest.startDate +
        "&endDate=" +
        loadrequest.endDate +
        "&sortBy=" +
        loadrequest.sortBy +
        "&dealerId=" +
        loadrequest.dealerId +
        "&orgId=" +
        loadrequest.orgId +
        "&vehicleId=" +
        loadrequest.vehicleId +
        "&customerId=" +
        loadrequest.customerId;

      //  var url=process.env.REACT_APP_API_BASE_URL+"IdleReport/idleReportXLS.xls?orgId="+loadrequest.orgId+
      //  "&vehicleId="+loadrequest.vehicleId+"&size="+loadrequest.size+"&page="+loadrequest.page+
      //  "&startDate="+loadrequest.startDate+"&endDate="+loadrequest.endDate+"&sortBy="+loadrequest.sortBy+
      //  "&threshold="+loadrequest.threshold+"&startOdometer="+loadrequest.startOdometer+
      //  "&endOdometer="+loadrequest.endOdometer+"";
      //  console.log("url:::"+url);

      window.open(url);
    } catch (error) {}
  };
  const handleSearch = async (searchText) => {
    setSearchVal(searchText);
    loadrequest = assignedval;
    loadrequest.search = searchText;
    setAssignedval(loadrequest);
    const responseData = (await ReportServices.IdleReport(loadrequest)).data;
    setResponseData(responseData.data.content);
  };
  const handleSubmit = async (data) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    loadrequest = assignedval;
    loadrequest.regionId = data.region;
    loadrequest.resellerId = data.reseller;
    // loadrequest.resellerId = 5;
    loadrequest.dealerId = data.dealer;
    loadrequest.customerId = data.customer;
    loadrequest.dealerId = data.dealer;
    loadrequest.orgId = data.fleet; // fleet
    loadrequest.vehicleId = data.vehicle;
    loadrequest.size = rowsPerPage;
    loadrequest.page = newPage;
    loadrequest.startDate = data.from_date;
    loadrequest.endDate = data.to_date;
    loadrequest.sortBy = "desc";
    loadrequest.search = searchval;
    setAssignedval(loadrequest);
    const responseData = (await ReportServices.IdleReport(loadrequest)).data;
    setResponseData(responseData.data.content);
    setLoading(responseData.resultText);
  };

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Reports" subDiv="Idle Report" includePopup={true}></Breadcrumb>
      <MouseOverPopover text={text} title={"Idle Report"} />
      <Box>
        <ReportNewDropDown
          onSubmit={handleSubmit}
          isShowVeh={true}
          displayDate={true}
        />
        <StyledCardShadow sx={{ mt: 2 }}>
          {/* <Typography
            sx={{
              fontSize: "12px",
            }}
          >
            Idle report shows various instances when the vehicle was Idling
            beyond the threshold time. Idle status is when the vehicle is not
            moving but engine is running.
          </Typography> */}
          {/* <Paper sx={{ backgroundColor: "#fff", marginTop: 2 }}> */}
          {/* <ReportDropDown /> */}
          <form>
            {/* <DropDown
              label="Vehicle"
              options={vehicles}
              // onChange={handleVehicleSelect}
              optionsDisplayKey="value"
              // onSearch={handleVehicleSearch}
              // isShow={isShowVeh}
              // selValue={seltdVehId}
              />
            <Button
              variant="contained"
              sx={{ color: "white", m: 3, mt: 3, textTransform: "none" }}
            >
              Submit
            </Button> */}
          </form>
          {/* </Paper> */}
          {/* <img alt="excel" style={{marginLeft: 850,width: 25, marginTop: 10, marginBottom: 10}}  title="Export Excel"  onClick={onChangeXcl} src={exporticon} />  */}
          <CardHeader
            title={<StyledTableTitle>Idle Summary Report</StyledTableTitle>}
          />
          {/* <ButtonGroup variant="contained" elevation={0}>
                <Button
                  startIcon={<FaFilePdf />}
                  color="error"
                  // onClick={onChangepdf}
                >
                  PDF
                </Button>
                <Button
                  startIcon={<RiFileExcel2Fill />}
                  color="success"
                  onClick={onChangeXcl}
                > 
                  Excel
                </Button>
              </ButtonGroup> */}

          {/* <Box
            display="flex"
            flexDirection="column"
            sx={{
              marginTop: 2,
            }}
          > */}
          <CardContent sx={{ py: 0 }}>
            <DataTable
              loading={loading}
              includeSearchBox={true}
              handleChange={handleSearch}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={callbackChangePage}
              totalElements={totalElements}
              totalPages={totalPages}
            />
          </CardContent>
          {/* </Box> */}
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default IdleReport;
