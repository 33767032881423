import React, { useMemo, useState, useEffect } from "react";
import { DataTable } from "../shared/data_table/NestedTable";
import { useGetGeoZoneDetailsMutation } from "../../store/slice/RouteTripSlice/RouteTripSlice";

const SlotTable = ({ routeId }) => {
  const [
    getGeozones,
    {
      data: getGeozoneData,
      isLoading: geozoneDataLoading,
      isSuccess: geozoneDataSuccess,
    },
  ] = useGetGeoZoneDetailsMutation();

  const [responseData, setResponseData] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sequenceNo",
        width:30
      },
      {
        Header: "Geozone",
        accessor: "routeName",
        width:120,
      },
      {
        Header: "Category",
        accessor: "zoneCategory",
        width:120,
      },
      {
        Header: "Address",
        accessor: "address",
        width:250
      },
      {
        Header: "Radius(m)",
        accessor: "radiusStr",
        width:40
      },
      {
        Header: "Within Duration ",
        accessor: "duration",
        width:53
      },
      {
        Header: "Between duration",
        accessor: "plannedDuration",
        width:56
      },
      {
        Header: "Map Distance (mm)",
        accessor: "distance",
        width:40
      },
    ],
    []
  );

  const fetchSlotDetails = async () => {
    var request = {};
    request.routeId = routeId.routeId;
    const responseData = await getGeozones(request);
    setResponseData(responseData?.data?.data);
  };

  useEffect(async () => {
    await fetchSlotDetails();
  }, [routeId]);

  return (
    <DataTable
      columns={columns}
      data={responseData}
      styles={{ maxWidth: "100vw" }}
      numberOfStickyColumns={1}
      includeExportButton={false}
    />
  );
};

export default SlotTable;
