import { dashboardApi } from "../ApiSlice";

const reportSlice = dashboardApi.injectEndpoints({
    endpoints: builder => ({
        vehicleData3KG: builder.mutation({ //FOR KG
            query: (body) => ({
              url: `/ManualCanDataReport/getvehicleDataReportForKg2`,
              method: "POST",
              body: body,
            }),
          }),
          vehicleData2KG: builder.mutation({ //FOR KG
            query: (body) => ({
              url: `/ManualCanDataReport/getvehicleDataReportForKg2`,
              method: "POST",
              body: body,
            }),
          }),

          vehicleDataMontra: builder.mutation({ //FOR KG
            query: (body) => ({
              url: `ManualCanDataReport/getvehicleDataReportForMontra`,
              method: "POST",
              body: body,
            }),
          }),

          //faultFeedDetail
          faultFeedDetail: builder.mutation({ //FOR KG
            query: (body) => ({
              url: `FaultCodeReport/faultFeedDetail`,
              method: "POST",
              body: body,
            }),
          }),


    vehicleData2KG: builder.mutation({ //FOR KG
      query: (body) => ({
        url: `/ManualCanDataReport/getvehicleDataReportForKg2`,
        method: "POST",
        body: body,
      }),
    }),

    vehicleDataMontra: builder.mutation({ //FOR KG
      query: (body) => ({
        url: `ManualCanDataReport/getvehicleDataReportForMontra`,
        method: "POST",
        body: body,
      }),
    }),

    //faultFeedDetail
    faultFeedDetail: builder.mutation({ //FOR KG
      query: (body) => ({
        url: `FaultCodeReport/faultFeedDetail`,
        method: "POST",
        body: body,
      }),
    }),
    //Trip History Report
    getTripHistory: builder.mutation({
      query: (body) => ({
        url: "/TripReport/gridViewHistory",
        method: "POST",
        body: body,
      }),
    }),

    vehicleDataSCV: builder.mutation({ //FOR SCV
      query: (body) => ({
        url: `ManualCanDataReport/getVehicleDataReportForSCV`,
        method: "POST",
        body: body,
      }),
    }),

    vehicleLatestDetail: builder.mutation({ //FOR LATEST DETAIL REPORT
      query: (body) => ({
        url: `VehicleLatestDetailReport/getLatestDetailGridView`,
        method: "POST",
        body: body,
      }),
    }),

    vehicleDetailVolt: builder.mutation({ //FOR LATEST DETAIL REPORT
      query: (body) => ({
        url: `VehicleLatestDetailReport/getVoltDetailGridView`,
        method: "POST",
        body: body,
      }),
    }),

    qcVerifiedVehicleReport: builder.mutation({ //FOR QC VERIFIED DETAIL REPORT
      query: (body) => ({
        url: `QCVerifiedReport/getQCVerifiedReport`,
        method: "POST",
        body: body,
      }),
    }),

    vehicleDetailBatteryHealth: builder.mutation({ //FOR LATEST DETAIL REPORT
      query: (body) => ({
        url: `VehicleLatestDetailReport/getBatteryDetailGridView`,
        method: "POST",
        body: body,
      }),
    }),
    vehicleDataPricol: builder.mutation({ //FOR Pricol
      query: (body) => ({
        url: `ManualCanDataReport/getVehicleDataReportForPricol`,
        method: "POST",
        body: body,
      }),
    }),
    vehicleDataEvHyundai: builder.mutation({ //FOR EV HYUNDAI
      query: (body) => ({
        url: `ManualCanDataReport/getVehicleDataReportForPricol1`,
        method: "POST",
        body: body,
      }),
    }),
    // vehicleDataTerra: builder.mutation({ //FOR Terra
    //   query: (body) => ({
    //     url: `ManualCanDataReport/getVehicleDataReportForTerraMoto`,
    //     method: "POST",
    //     body: body,
    //   }),
    // }),


    verifyVehicle: builder.mutation({ //FOR QC verified/onHold
      query: (body) => ({
        url: `QCVerifiedReport/qcVerifyVehicle`,
        method: "POST",
        body: body,
      }),
    }),

    getFeedDetails: builder.mutation({ //FOR Pricol
      query: (body) => ({
        url: `FeedLog/getFeedDetails`,
        method: "POST",
        body: body,
      }),
    }),

    getDealerVehicleList: builder.mutation({ //FOR QC verified/onHold
      query: (body) => ({
        url: `mobileService/getVehListv1`,
        method: "POST",
        body: body,
      }),
    }),


  }),
})

export const { useVehicleData3KGMutation, useVehicleDataMontraMutation, useVehicleData2KGMutation, useFaultFeedDetailMutation,useGetTripHistoryMutation,useVehicleDataSCVMutation, useVehicleLatestDetailMutation, useVehicleDetailVoltMutation, useVehicleDetailBatteryHealthMutation,useVehicleDataPricolMutation,useVehicleDataEvHyundaiMutation,useQcVerifiedVehicleReportMutation, useVerifyVehicleMutation, useGetDealerVehicleListMutation,useGetFeedDetailsMutation} = reportSlice;
