import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Hidden, IconButton, Stack, Typography, alpha, useTheme } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { IoMdClose } from 'react-icons/io'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Backdrop } from '@mui/material';
import { ClockLoader } from 'react-spinners';

function SummaryPdf({tripData, rawData, openPdf, closePDF}) {
    const startLocation = rawData?.playBackList[0]?.startLocation;
    const endLocation = rawData?.playBackList[0]?.endLocation;
    const [startLat, startLng] = startLocation ? startLocation.split(',') : [null, null];
    const [endLat, endLng] = endLocation ? endLocation.split(',') : [null, null];
    const theme = useTheme();
    const Statstic = ({label, value, icon, color}) => {
        return(
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Box sx={{fontSize:'16px'}}>{icon}</Box>
                <Box>
                    <Typography variant='body2' color={'text.secondary'} sx={{fontSize:'12px'}}>{label}</Typography>
                    <Typography variant='subtitle2' color={color}>{value}</Typography>
                </Box>
            </Stack>
        )
    }


    
    const mapRef = useRef(null);
    const mapElement = mapRef.current;




    const DownloadPdf = () => {
    const startLocation = rawData?.playBackList[0]?.startLocation;
    const endLocation = rawData?.playBackList[0]?.endLocation;
    const [startLat, startLng] = startLocation ? startLocation.split(',') : [null, null];
    const [endLat, endLng] = endLocation ? endLocation.split(',') : [null, null];
    const mapImageURL = `https://maps.googleapis.com/maps/api/staticmap?size=600x400&zoom=12&markers=label:S|color:red|${startLat},${startLng}&markers=label:E|color:red|${endLat},${endLng}&path=color:0x0000ff|weight:2|${startLat},${startLng}|${endLat},${endLng}&key=AIzaSyBx1k2yHWgbv2RS0NUVcYrqhcHD8sWr9eA`;
    const fetchMapImage = fetch(mapImageURL).then((response) => response.blob());
    const captureMapContent = html2canvas(mapRef.current, { scale: 1}).then((canvas) =>
      canvas.toDataURL("image/png")
    );
    Promise.all([fetchMapImage, captureMapContent])
      .then(([mapImageBlob, mapContentDataURL]) => {
        const pdf = new jsPDF();
        const img = new Image();
        pdf.text("Playback Summary Details", 15, 10);
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, img.width, img.height);
          pdf.addImage(mapContentDataURL, "PNG", 10, 15);
          const contentHeight = img.height + mapRef.current.offsetHeight;
          const pageHeight = pdf.internal.pageSize.getHeight();
  
          if (contentHeight > pageHeight) {
            pdf.addPage(); 
            pdf.text("Snapshot of the summary details", 15, 10);
            pdf.addImage(canvas.toDataURL("image/png"), "PNG", 15, 20);
          }
          pdf.save("playback_summary.pdf");
          closePDF();
        };
        img.src = URL.createObjectURL(mapImageBlob);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
    }

    useEffect(() => {
        if(openPdf){
            setTimeout(() => {
                DownloadPdf();
            },2000)
        }
    },[openPdf])


  return (
    <Backdrop open={openPdf} onClose={closePDF} sx={{'&.MuiBackdrop-root ':{display:'flex', flexDirection:'column', alignItems:'center', zIndex:999999}}}>
                {/* <DialogTitle sx={{display:"flex",flexDirection:"row",justifyContent:"space-between",backgroundColor:"#e7e7e7"}}>
                    <IconButton sx={{ borderRadius: "5px", border: "1px solid black" }} disabled={tripData === null} onClick={closePDF}>
                        <IoMdClose  fontSize="small" color="black"/>
                    </IconButton>
                    </DialogTitle> */}
                <Box sx={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)'}}>
                    <ClockLoader color="#36d7b7" />
                </Box>
                <DialogContent sx={{width:"580px", p:0, opacity:0}} id='colorComponent'>
                <Grid>
                         <Box>
                         <img
                            src={`https://maps.googleapis.com/maps/api/staticmap?size=560x200&zoom=8&markers=label:S|color:red|${startLat},${startLng}&markers=label:E|color:red|${endLat},${endLng}&path=color:0x0000ff|weight:2|${startLat},${startLng}|${endLat},${endLng}&key=AIzaSyBx1k2yHWgbv2RS0NUVcYrqhcHD8sWr9eA`}
                            alt="Google Map"
                        />
                               
                              </Box>
                    </Grid>
                    <div ref={mapRef} sx={{p:2}}>
                        <Grid container spacing={2} >
                                <Grid item lg={12}>
                                    <Box sx={{background:alpha(theme.palette.info.main, .12), p:1}}>
                                        <Typography>Trip Details</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={4}>
                                    <Statstic label={'Start Date'} value={rawData?.tripStartDate}/>
                                </Grid>
                                <Grid item lg={4}>
                                    <Statstic label={'End Date'} value={rawData?.tripEndDate}/>
                                </Grid>
                                <Grid item lg={4}>
                                    <Statstic label={'Start Location'} value={rawData?.startLocation}/>
                                </Grid>
                                <Grid item lg={4}>
                                    <Statstic label={'End Location'} value={rawData?.endLocation}/>
                                </Grid>
                                <Grid item lg={4}>
                                    <Statstic label={'Start Odometer (km)'} value={rawData?.summaryData[0]?.startOdometer}/>
                                </Grid>
                                <Grid item lg={4}>
                                    <Statstic label={'End Odometer (km)'} value={rawData?.summaryData[0]?.endOdometer}/>
                                </Grid>
                              
                                <Grid item lg={4}>
                                    <Statstic label={'Distance Covered (km)'} value={rawData?.distTraveled}/>
                                </Grid>
                                <Grid item lg={4}>
                                    <Statstic label={'Average Speed (km/h)'} value={rawData?.summaryData[0]?.avgSpeed}/>
                                </Grid>
                                <Grid item lg={4}>
                                    <Statstic label={'Maximum Speed (km/h)'} value={rawData?.summaryData[0]?.maxSpeed}/>
                                </Grid>
                                <Grid item lg={4}>
                                <Statstic label={'Moving (hh:mm)'} value={rawData?.summaryData[0]?.moveMinutes}/>
                                </Grid>
                                <Grid item lg={4}>
                                <Statstic label={'Stop (hh:mm)'} value={rawData?.summaryData[0]?.stopHours}/>
                                </Grid>
                                <Grid item lg={4}>
                                <Statstic label={'Offline (hh:mm)'} value={rawData?.summaryData[0]?.offlineHours}/>
                                </Grid>
                                <Grid item lg={12}>
                                    <Box sx={{background:alpha(theme.palette.info.main, .12), p:1}}>
                                        <Typography>Utilization Summary</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={4}>
                                <Statstic label={'Energy Consumed'} value={rawData?.summaryData[0]?.energyConsumed}/>
                                </Grid>
                                <Grid item lg={4}>
                                <Statstic label={'Energy Efficiency (kWh)'} value={rawData?.summaryData[0]?.batteryEfficiency}/>
                                </Grid>
                                <Grid item lg={12}>
                                    <Box sx={{background:alpha(theme.palette.info.main, .12), p:1}}>
                                        <Typography>Charing Summary</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={4}>
                                    <Statstic label={'Start SOC (%)'} value={rawData?.summaryData[0]?.startSoc}/>
                                </Grid>
                                <Grid item lg={4}>
                                <Statstic label={'End SOC (%)'} value={rawData?.summaryData[0]?.endSoc}/>
                                </Grid>
                                {/* <Grid item lg={4}>
                                <Statstic label={'Charge Count'} value={rawData?.summaryData[0]?.chargeCount}/>
                                </Grid> */}
                                <Grid item lg={4}>
                                <Statstic label={'Charge (hh:mm)'} value={rawData?.summaryData[0]?.chargeMins}/>
                                </Grid>

                                <Grid item lg={12}>
                                    <Box sx={{background:alpha(theme.palette.info.main, .12), p:1}}>
                                        <Typography>Driver Behaivour</Typography>
                                    </Box>
                                </Grid>

                                <Grid item lg={4}>
                                    <Statstic label={'Count'} value={rawData?.summaryData[0]?.overSpeedCount}/>
                                </Grid>
                                <Grid item lg={4}>
                                    <Statstic label={'Duration (hh:mm)'} value={rawData?.summaryData[0]?.overSpeedDuration}/>
                                </Grid>
                                 <Grid item lg={4}>
                                    <Statstic label={'Distance (km)'} value={rawData?.summaryData[0]?.overSpeedDistance}/>
                                </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                {/* <DialogActions>

                  <ButtonGroup variant="contained" aria-label="Basic button group" >
                      <Button onClick={onChangeXcl} style={{ padding: "5px 20px" }} >
                          <RiFileExcel2Fill fontSize="medium" color='white' />
                          <div style={{ color: "white", paddingLeft: "5px" }}> CSV</div>
                      </Button>
                      <Button onClick={downloadPDF} style={{ padding: "5px 20px",backgroundColor:"red" }}>
                          <BsFileEarmarkPdfFill fontSize="medium" color='white' />
                          <div style={{ color: "white", paddingLeft: "5px" }}> PDF</div>
                      </Button>

                  </ButtonGroup>
                </DialogActions> */}
    </Backdrop>

  )
}

export default SummaryPdf