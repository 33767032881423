import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab'
import { Box, Chip, Collapse, Grid, Icon, Stack, Tooltip, Typography, alpha, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { HiLocationMarker } from 'react-icons/hi'
import { StyledIconButton } from '../../theme/StyledEle';
import { PiChargingStationFill } from 'react-icons/pi';
import moment from 'moment';
import { AiFillThunderbolt } from 'react-icons/ai';
import { MdKeyboardArrowDown, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { BsFillSignStopFill } from 'react-icons/bs';

function TimeLineItem(props) {
    const { length, index, itemData } = props;
    let deleteObj = itemData;
    const theme = useTheme();
    let   [RenderData, setRenderData] = useState(null);
    const [TimeData, setTimeData] = useState(itemData);
    const [open, setOpen] = useState(true);

    
    useEffect(() => {
        const data = Object.fromEntries(Object.entries(itemData).filter(([_, v]) => v != null));
        setRenderData(Object.entries(data));
        let remainData = Object.entries(data)?.map(([key, value]) => value[0] !== 'zoneName' && value[0] !== 'type' && value[0] !== 'endTime' && value[0] !== 'startTime' && value[0] !== 'stime' &&  value[0] !== 'edate' && value[0] !== 'etime' && value[0] !== 'sdate')
        //Object.entries(data)?.map(([key, value]) => console.log('value', value))
        
        // Object.keys(itemData).forEach(key => {
        //     if(itemData[key] === '-'){
        //         delete itemData[key]
        //     }
        // })
    
    },[TimeData])
    
  return (
    <TimelineItem
        sx={{'&:before':{
            flex:0,
            padding:'0px 6px',
            display:'none'
        },
        minHeight:'auto',
        }}
    >
    <TimelineSeparator sx={{
        ml:itemData?.zoneName === 'NoZone' && '7px',
        position:'absolute',
        height:'110%',
        left:'10px'
        }}>
        <TimelineDot sx={{margin:'0px', mt:'8px'}} color={
            itemData?.zoneName !== 'NoZone' && itemData?.type === 'zone' ? 'info' :
            itemData?.type === 'charging' ? 'secondary' : itemData?.type === 'stoppage' ? 'error' : 'grey'}>
            {
                itemData?.zoneName !== 'NoZone' && itemData?.type === 'zone' ? 
                <Icon sx={{fontSize:'14px', display:'flex', color:'#fff'}} ><HiLocationMarker /></Icon> : 
                itemData?.type === 'charging' && itemData?.zoneName !== 'NoZone' ? <Icon sx={{fontSize:'14px', display:'flex', color:'#fff'}}><AiFillThunderbolt /></Icon> : 
                itemData?.type === 'stoppage' && itemData?.zoneName !== 'NoZone' ? <Icon sx={{fontSize:'14px', display:'flex', color:'#fff'}}><BsFillSignStopFill /></Icon> : ''
            }
        </TimelineDot>
        {
            length -1  !== index && <TimelineConnector sx={{background:alpha(theme.palette.info.main, .2)}}/>
        }
        
    </TimelineSeparator>
    <TimelineContent sx={{p:'0px 0px 0px 0px', }} variant='body2'>
        <Box sx={{p:'5px 0px 5px 50px'}}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Box>
                    <Typography variant='body1' sx={{fontSize:'12px', fontWeight:400}} color={'text.secondary'}>{itemData?.startTime?.split(' ')[1]} - {itemData?.endTime?.split(' ')[1]} - {itemData?.zoneDurationTime !== '0' && itemData?.zoneDurationTime} {itemData?.zoneDurationTime !== '0' && 'mins'}</Typography>
                    <Typography variant='subtitle2' sx={{textTransform:'capitalize'}}>{itemData?.zoneName !== 'NoZone' ? itemData?.zoneName : itemData?.type}</Typography>
                </Box>
                <Box>
                    <Stack direction={'row'} spacing={3}>
                        {/* <Typography variant='subtitle2'>{itemData?.interDuration}</Typography> */}
                        {
                            itemData.type !== 'zone' && itemData.zoneName !== 'NoZone'  && itemData.type !== '-' && <Chip label={itemData.type} size='small' color={itemData.type === 'charging' ? 'secondary' : 'error' }/>
                        }
                        <Tooltip title={'Hide Details'} arrow>
                            <StyledIconButton size='small' onClick={() => setOpen(!open)} color='success' sx=           {{background:alpha(theme.palette.success.main, .02)}}>
                                {
                                    open ?  <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown /> 
                                }
                                
                                </StyledIconButton>
                        </Tooltip>
                    </Stack>
                  
                </Box>
                {/* <Typography variant='caption'>{itemData?.interDuration}m</Typography> */}
            </Stack>
        </Box>
        
        <Collapse in={open} sx={{pb:2, borderTop:`.5px solid ${alpha(theme.palette.info.main, .3)}`, p:'5px 0px 15px 50px', background:alpha(theme.palette.info.main, 0.08)}}>
            <Grid container spacing={1}>
                {
                    RenderData !== null && 
                    Object.entries(RenderData)?.map(([key, value]) => 
                    value[0] !== 'zoneName' && value[0] !== 'type' && value[0] !== 'endTime' && value[0] !== 'startTime' && value[0] !== 'stime' &&  value[0] !== 'edate' && value[0] !== 'etime' && value[0] !== 'sdate' && value[0] !== 'zoneDurationTime' && 
                    <Grid item lg={6}>
                        <Typography variant='caption'>{
                        value[0] === 'stoppageDuration' ? 'Stoppage Duration (mins)' :
                        value[0] === 'stoppageCount' ? 'Stop Count' :
                        value[0] === 'stoppageEnergyConsumed' ? 'Stoppage Energy Consumed (kWh)' :
                        value[0] === 'chargingDuration' ? 'Charging Duration (mins)' :
                        value[0] === 'chargingCount' ? 'Charging Count' :
                        value[0] === 'chargingEnergyGained' ? 'Charging Energy Gained (kWh)' :
                        value[0] === 'stoppageEnergyConsumed' ? 'Stoppage Energy Consumed' :
                        value[0]}</Typography>
                        <Typography variant='body2' sx={{fontWeight:500}}>{value[1]}</Typography>
                    </Grid>)
                }  
            </Grid>
        </Collapse>
    </TimelineContent>
    </TimelineItem>
  )
}

export default TimeLineItem