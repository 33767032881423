import * as React from "react";
import { useState } from "react";
import {
  CardHeader,
  Paper,
  CardContent,
  Alert,
  Grid,
  List,
  Card,
  ListItemText,
  Checkbox,
  Button,
  Divider,
  ListItemButton,
  Stack,
  MenuItem,
  Select,
  Switch,
  Typography,
  useTheme,
  ListItem,
  Skeleton,
  InputBase,
  Tooltip,
  Collapse,
  ListItemIcon,
} from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import MuiToggleButton from "@mui/material/ToggleButton";
import CommonDropDown from "./alertsDropdown";
import alertServices from "../../services/alertServices";
import {
  SoftButton,
  StyledCardShadow,
  StyledDropDown,
  StyledIconButton,
  StyledPageLayout,
  StyledPaperShadow,
  StyledSwitch,
  StyledTableTitle,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../theme/StyledEle";
import { Box, alpha } from "@mui/system";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/styles";
import { StyledAccordion, StyledFiterButton } from "../../theme/StyledEle";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import MouseOverPopover from "../shared/PopOver";
import AlertDropdown from "../view-alerts/AlertsDropdown/AlertDropdown";
import {
  useAssignUserCallMutation,
  useAssignUserListMutation,
  useGetAssignAlertDetailsMutation,
  useGetAssignAlertsListMutation,
  useSaveAlertsMutation,
  useSaveUserMutation,
  useUnassignUserListMutation,
} from "../../store/slice/AlertSlice/AlertSlice";
import { ScaleLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { IoSearchOutline } from "react-icons/io5";
import { LuUser } from "react-icons/lu";
import { FiUserCheck } from "react-icons/fi";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";


const globalJson1 =
  localStorage.getItem("userData") !== null
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
var globalOrgId = 1;
var globaluserId = 1;
var globalRoleId = 0;
var globalJson = {};
var globalResellerId = 2;
var globalOrgType = 2;
var globalUserName = "";
var globalOrgName = "";

if (
  "null" === localStorage.getItem("userData") ||
  null === localStorage.getItem("userData")
) {
} else {
  globalJson =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : 0;
  globalOrgId = globalJson.orgId;
  globalOrgType = globalJson.userOrgType;
  globaluserId = globalJson.id;
  globalRoleId = globalJson.roleId;
  globalResellerId = globalJson.resellerId;
  globalUserName = globalJson.userName;
  globalOrgName = globalJson.orgName;
}


function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const Alerts = () => {
  const [hideDetails, setHidedetails] = useState("assign_users");
  const [alignment, setAlignment] = React.useState(1);
  const [pageStatus, setPageStatus] = useState([]);

  const [assignUser, setAssignUser] = React.useState([]);
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const [responseDataSwitch, setResponseDataSwitch] = useState({});
  const [responseAlertData, setResponseAlertData] = useState([]);
  const [orgAlertList, setOrgAlertList] = useState([]);
  const [deviceAlertList, setDeviceAlertList] = useState([]);
  const [systemAlertList, setSystemAlertList] = useState([]);
  const [objAssignData, setObjAssignData] = useState({});
  const [panel1, setPanel1] = useState(true);
  const [panel2, setPanel2] = useState(true);
  const [panel3, setPanel3] = useState(true);


const [auditLog] = useAuditLogMutation();
let auditPayload = {
  //Audit Common Payload
  orgId: globalOrgId,
  userId: globaluserId, 
  orgName: globalOrgName, 
  userName: globalUserName,
};

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(union(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    //compareArray();
  };

  const saveUser = async (e) => {
    var request = {};
    request.orgId = pageStatus.orgId;
    request.vehicleId = pageStatus.vehicleId;
    request.userId = "String";
    var obj = {};
    var rightCheck = [];
    rightCheck = [...rightChecked];
    var userIds = "";
    for (var i = 0; i < rightCheck.length; i++) {
      if (i === 0) {
        userIds = rightCheck[i].id;
      } else {
        userIds = userIds + "," + rightCheck[i].id;
      }
    }
    obj.userId = userIds;
    obj.vehicleId = pageStatus.vehicleId;
    obj.orgId = pageStatus.orgId;
    const responseData = await alertServices.saveUser(obj);
    setAssignUser(responseData);
   
   
    };

  const handleUserCancel = async () => {
    var request = {};
    request.orgType = pageStatus.orgType;
    request.resellerId = pageStatus.resellerId;
    request.customerId = pageStatus.customerId;
    request.fleetId = pageStatus.fleetId;
    request.vehicleId = pageStatus.vehicleId;
    request.orgId = pageStatus.orgId;
    request.regionId = pageStatus.regionId;
    request.dealerId = pageStatus.dealerId;
    setPageStatus(request);
    try {
      const responseUserData = (await alertServices.getUnaasignedUser(request))
        .data;
      setLeft(responseUserData);
      const responseAssignData = (await alertServices.getAssignedUser(request))
        .data;
      setRight(responseAssignData);
    } catch (error) {}
  };

  const handleAlertCancel = async () => {
    var request = {};
    request.orgType = orgType;
    request.resellerId = resellerId;
    request.customerId = payload.customerId;
    request.fleetId = payload.fleetId;
    request.vehicleId = payload.vehicleId;
    request.orgId = payload.fleetId;
    request.regionId = payload.regionId;
    request.dealerId = payload.dealerId;
    setPageStatus(request);

    try {
      const responseData = (await alertServices.getAlertDetails(request)).data;
      setResponseDataSwitch(responseData.listAlert[0]);
      const responseAlert = (await alertServices.getAlertOrg(request)).data;
      setResponseAlertData(responseAlert);
      setOrgAlertList(responseAlert.orgAlert);
      setDeviceAlertList(responseAlert.deviceAlert);
      setSystemAlertList(responseAlert.systemAlert);
    } catch (error) {}
  };

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary {...props} />
  ))(({ theme }) => ({
    backgroundColor: theme.palette.action.hover,
    flexDirection: "row-forward",
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const handleSelectChange = (e, selectVal) => {
    var responseDataSwtch = {};
    responseDataSwtch = { ...responseDataSwitch };
    Object.keys(responseDataSwtch).forEach(function (k) {
      if (k === selectVal) {
        responseDataSwtch[selectVal] = e.target.value;
      }
    });
    setResponseDataSwitch(responseDataSwtch);
  };

  const handleSwitchChange = (e, val) => {
    var responseDataSwtch = {};
    responseDataSwtch = { ...responseDataSwitch };
    var assignData = { ...objAssignData };
    if (e.target.checked === false) {
      Object.keys(responseDataSwtch).forEach(function (k) {
        if (k === val) {
          responseDataSwtch[val] = 0;
          assignData[k] = 0;
          setObjAssignData(assignData);
        }
      });
      setResponseDataSwitch(responseDataSwtch);
    } else if (e.target.checked === true) {
      var assignData = { ...objAssignData };
      Object.keys(responseDataSwtch).forEach(function (k) {
        if (k === val) {
          responseDataSwtch[val] = 1;
          assignData[k] = 1;
          setObjAssignData(assignData);
        }
      });
      setResponseDataSwitch(responseDataSwtch);
    }
  };

  const handleChange = async (event) => {
    setHidedetails(event.target.value);
    var orgType = pageStatus.orgType;
    var resellerId = pageStatus.resellerId;
    var customerId = pageStatus.customerId;
    var fleetId = pageStatus.fleetId;
    var vehicleId = pageStatus.vehicleId;
    var orgId = pageStatus.orgId;
    var regionId = pageStatus.regionId;
    var dealerId = pageStatus.dealerId;
    handleSubmitChange(
      orgType,
      resellerId,
      customerId,
      fleetId,
      vehicleId,
      orgId,
      regionId,
      dealerId,
      event.target.value
    );

  };

  const callbackLoadFn = async (
    selectedOrgTypeId,
    selectedResellerId,
    selectedCustomerId,
    selectedFleetId,
    selectedVehicleId,
    selectedLastOrgId,
    selectedRegionId,
    selectedDealerId
  ) => {
    if (hideDetails === "assign_users") {
      // var request = {};
      // request.orgType = selectedOrgTypeId;
      // request.resellerId = selectedResellerId;
      // request.customerId = selectedCustomerId;
      // request.fleetId = selectedFleetId;
      // request.vehicleId = selectedVehicleId;
      // request.orgId = selectedLastOrgId;
      // request.regionId = selectedRegionId;
      // request.dealerId = selectedDealerId;
      // setPageStatus(request);
      // try {
      //   const responseUserData = (
      //     await alertServices.getUnaasignedUser(request)
      //   ).data;
      //   setLeft(responseUserData);
      //   const responseAssignData = (
      //     await alertServices.getAssignedUser(request)
      //   ).data;
      //   setRight(responseAssignData);
      // } catch (error) {}
    } else if (hideDetails === "assign_alerts") {
      var request = {};
      request.orgType = selectedOrgTypeId;
      request.resellerId = selectedResellerId;
      request.customerId = selectedCustomerId;
      request.fleetId = selectedFleetId;
      request.vehicleId = selectedVehicleId;
      request.orgId = selectedLastOrgId;
      request.regionId = selectedRegionId;
      request.dealerId = selectedDealerId;
      setPageStatus(request);
      try {
        const responseData = (await alertServices.getAlertDetails(request))
          .data;
        setResponseDataSwitch(responseData.listAlert[0]);
        const responseAlert = (await alertServices.getAlertOrg(request)).data;
        setResponseAlertData(responseAlert);
        setOrgAlertList(responseAlert.orgAlert);
        setDeviceAlertList(responseAlert.deviceAlert);
        setSystemAlertList(responseAlert.systemAlert);
      } catch (error) {}
    }
  };
  // const handleSubmit = async (seltdOrgTypeId,seltdResellerId,seltdCustomerId,seltdFleetId,seltdVehicleId,seltdLastOrgId,seltdRegionId,seltdDealerId) => {
  //   if (hideDetails === "assign_users") {
  //     // var request = {};
  //     // request.orgType = seltdOrgTypeId;
  //     // request.resellerId = seltdResellerId;
  //     // request.customerId = seltdCustomerId;
  //     // request.fleetId = seltdFleetId;
  //     // request.vehicleId = seltdVehicleId;
  //     // request.orgId = seltdLastOrgId;
  //     // request.regionId = seltdRegionId;
  //     // request.dealerId = seltdDealerId;
  //     // setPageStatus(request);
  //     // try {
  //     //   const responseUserData = (await alertServices.getUnaasignedUser(request)).data;
  //     //   setLeft(responseUserData);
  //     //   const responseAssignData = (await alertServices.getAssignedUser(request)).data;
  //     //   setRight(responseAssignData);
  //     // } catch (error)
  //     // {

  //     // }
  //   } else if (hideDetails === "assign_alerts") {
  //     var request = {};
  //     request.orgType = seltdOrgTypeId;
  //     request.resellerId = seltdResellerId;
  //     request.customerId = seltdCustomerId;
  //     request.fleetId = seltdFleetId;
  //     request.vehicleId = seltdVehicleId;
  //     request.orgId = seltdLastOrgId;
  //     request.regionId = seltdRegionId;
  //     request.dealerId = seltdDealerId;
  //     setPageStatus(request);
  //     try {
  //       const responseData = (await alertServices.getAlertDetails(request)).data;
  //       setResponseDataSwitch(responseData.listAlert[0]);
  //       const responseAlert = (await alertServices.getAlertOrg(request)).data;
  //       setResponseAlertData(responseAlert);
  //       setOrgAlertList(responseAlert.orgAlert);
  //       setDeviceAlertList(responseAlert.deviceAlert);
  //       setSystemAlertList(responseAlert.systemAlert);
  //     } catch (error) {}
  //   }
  // };

  const handleSubmitChange = async (
    seltdOrgTypeId,
    seltdResellerId,
    seltdCustomerId,
    seltdFleetId,
    seltdVehicleId,
    seltdLastOrgId,
    seltdRegionId,
    seltdDealerId,
    event
  ) => {
    if (event === "assign_users") {
      var request = {};
      request.orgType = seltdOrgTypeId;
      request.resellerId = seltdResellerId;
      request.customerId = seltdCustomerId;
      request.fleetId = seltdFleetId;
      request.vehicleId = seltdVehicleId;
      request.orgId = seltdLastOrgId;
      request.regionId = seltdRegionId;
      request.dealerId = seltdDealerId;
      setPageStatus(request);
      try {
        const responseUserData = (
          await alertServices.getUnaasignedUser(request)
        ).data;
        setLeft(responseUserData);
        const responseAssignData = (
          await alertServices.getAssignedUser(request)
        ).data;
        setRight(responseAssignData);
      } catch (error) {}
    } else if (event === "assign_alerts") {
      var request = {};
      request.orgType = seltdOrgTypeId;
      request.resellerId = seltdResellerId;
      request.customerId = seltdCustomerId;
      request.fleetId = seltdFleetId;
      request.vehicleId = seltdVehicleId;
      request.orgId = seltdLastOrgId;
      request.regionId = seltdRegionId;
      request.dealerId = seltdDealerId;
      setPageStatus(request);
      try {
        const responseData = (await alertServices.getAlertDetails(request))
          .data;
        setResponseDataSwitch(responseData.listAlert[0]);
        const responseAlert = (await alertServices.getAlertOrg(request)).data;
        setResponseAlertData(responseAlert);
        setOrgAlertList(responseAlert.orgAlert);
        setDeviceAlertList(responseAlert.deviceAlert);
        setSystemAlertList(responseAlert.systemAlert);
      } catch (error) {}
    }
  };

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let roleId = userData.roleId;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType;

  const text = "Email's which are already verified will be listed.";
  let CommonPayload = {
    resellerId: resellerId,
  };

  const dispatch = useDispatch();
  const isSuccess = useSelector((state) => state.controls.alert);

  React.useEffect(() => {
    if (isSuccess.isSuccess) {
      setTimeout(() => dispatch(snackService([null, null, null])), 6000);
    }
  }, [isSuccess.isSuccess]);

  const [
    getUnassignUser,
    {
      data: unassignUserData,
      isLoading: unAssignUserLoading,
      isSuccess: unAssingUserSuccess,
    },
  ] = useUnassignUserListMutation();
  const [
    getassignUser,
    {
      data: assignUserData,
      isLoading: assignUserLoading,
      isSuccess: assingUserSuccess,
    },
  ] = useAssignUserListMutation();

  const [
    assignUsers,
    {
      data: saveUserData,
      isLoading: saveUserLoading,
      isSuccess: saveUserSuccess,
    },
  ] = useSaveUserMutation();

  const [assingn, { isLoading: assignLoading, isSuccess: assingnSuccess }] =
    useAssignUserCallMutation();

  const [
    assignAlerts,
    {
      data: assignAlertData,
      isLoading: assignAlertLoading,
      isSuccess: assignAlertSuccess,
      isError: assignAlertError,
    },
  ] = useGetAssignAlertsListMutation();
  const [
    assignAlertDetails,
    {
      data: assignAlertDetailsData,
      isLoading: assignAlertDetailsLoading,
      isSuccess: assignAlertDetailsSuccess,
      isError: assignAlertDetailsError,
    },
  ] = useGetAssignAlertDetailsMutation();

  const [
    saveAlertList,
    {
      data: saveAlertData,
      isLoading: saveAlertLoading,
      isSuccess: saveAlertSuccess,
    },
  ] = useSaveAlertsMutation();

  const [payload, setPayload] = useState(null);

  const handleTabValue = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      
    auditPayload.message = newAlignment === 1 ? "Admin > Alerts > Assign Users":"Admin > Alerts > Assign Alerts";
    auditPayload.action = "Select";
    auditPayload.featureName = "Alerts";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    //auditLog(auditPayload);
      
    }
  };

  //getUnassignUsers
  const getUnassignUserList = async () => {
    setLeft([]);
    const unAssignUser = await getUnassignUser(CommonPayload);
    if(unAssignUser.data !== undefined){
      setLeft(unAssignUser.data);
    }else{
      setLeft([]);
    }
  }
  const getAssignUsersList = async () => {
    setRight([]);
    const assignUser = await getassignUser(CommonPayload);
    if(assignUser.data !== undefined){
      setRight(assignUser.data);
    }else{
      setRight([]);
    }
  }  


  const getData = React.useCallback(
    async (data) => {
      setPayload(null);
      CommonPayload.orgType = orgType;
      CommonPayload.customerId = data.customerId;
      CommonPayload.fleetId = data.fleetId;
      CommonPayload.vehicleId = data.vehicleId;
      CommonPayload.orgId = data.fleetId;
      CommonPayload.regionId = data.regionId;
      CommonPayload.dealerId = data.dealerId;
      CommonPayload.searchText = ""
      setPayload(CommonPayload);
      if (alignment === 1 && data.vehicleId !== undefined) {
        
       
        getUnassignUserList();
        getAssignUsersList();
            //Audit Log
    auditPayload.message = "Alerts > Assign Users & Alerts > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Alerts";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
      } else if (alignment === 2 && data.vehicleId !== undefined) {
        setOrgAlertList(null);
        setDeviceAlertList(null);
        setSystemAlertList(null);
        setResponseAlertData(null);
        assignAlerts(CommonPayload);
        assignAlertDetails(CommonPayload);
            //Audit Log
    auditPayload.message = "Alerts > Assign Users & Alerts > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Alerts";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
      } else {
        setLeft([]);
        setRight([]);
        setOrgAlertList(null);
        setDeviceAlertList(null);
        setSystemAlertList(null);
        setResponseAlertData(null);
      }
    },
    [alignment]
  );

  React.useEffect(() => {
    if (payload !== null) {
      getData(payload);
      auditPayload.message = "Alerts > Assign Users";
      auditPayload.action = "View";
      auditPayload.featureName = "Alerts";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
    }
  }, [alignment]);



  React.useEffect(() => {
    if (assignAlertSuccess && assignAlertDetailsSuccess) {
      setResponseDataSwitch(assignAlertDetailsData.listAlert[0]);
      setOrgAlertList(assignAlertData.orgAlert);
      setDeviceAlertList(assignAlertData.deviceAlert);
      setSystemAlertList(assignAlertData.systemAlert);
      setResponseAlertData(assignAlertData);
    }
  }, [assignAlertSuccess, assignAlertDetailsSuccess]);

  //Save User
  const assignUserEdit = async () => {
    var rightCheck = [];
    rightCheck = [...rightChecked];

    var userIds = "";
    for (var i = 0; i < rightCheck.length; i++) {
      if (i === 0) {
        userIds = rightCheck[i].id;
      } else {
        userIds = userIds + "," + rightCheck[i].id;
      }
    }
    let assginPayload = {
      userId: userIds,
      vehicleId: payload.vehicleId,
      roleId: roleId,
      loggedUserId: userId,
      orgId: orgId,
     orgType : orgType,
    };
    const assignData = await assingn(assginPayload);
    if (assignData.data.resultCode === 0) {
      dispatch(snackService(["User assigned successfully", 0, true]));
    } else {
      dispatch(snackService(["User assign failed", 1, true]));
    }  
  };

  //Reset User
  const resetUsers = () => {
    getData(payload);
    //compareArray();
  };

  const theme = useTheme();
  const customList = (title, items) => (
    <Card elevation={0} sx={{ border: `0.5px solid ${theme.palette.divider}` }}>
      {unAssignUserLoading && assignUserLoading ? (
        <CardContent sx={{ textAlign: "center" }}>
          <ScaleLoader color={theme.palette.secondary.main} height={25} />
        </CardContent>
      ) : (
        <>
          <CardHeader
            avatar={
              <Checkbox
                onClick={handleToggleAll(items)}
                title="select all"
                checked={
                  numberOfChecked(items) === items.length && items.length !== 0
                }
                indeterminate={
                  numberOfChecked(items) !== items.length &&
                  numberOfChecked(items) !== 0
                }
                disabled={items.length === 0}
                inputProps={{
                  "aria-label": "all items selected",
                }}
              />
            }
            title={title}
            subheader={`${numberOfChecked(items)}/${items.length} selected`}
          />
          <Divider />
          <CardContent
            sx={{
              height: "300px",
              overflowY: "scroll",
              p: 0,
              paddingBottom: "0px !important",
            }}
          >
            {items.length > 0 ? (
              <List dense role="list" style={{ borderRadius: "5px" }}>
                {items.map((value, index) => {
                  const labelId = `transfer-list-all-item-${value}-label`;
                  return (
                    <ListItemButton
                      key={index}
                      role="listitem"
                      onClick={handleToggle(value)}
                      value={value.userId}
                      selected={checked.indexOf(value) !== -1 && true}
                    >
                      {/* <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon> */}
                      <ListItemText primary={value.userName} />
                    </ListItemButton>
                  );
                })}
              </List>
            ) : (
              <ListItemButton disabled>No Users</ListItemButton>
            )}
          </CardContent>
        </>
      )}
    </Card>
  );

  //Save Alerts

  const handleSaveAlert = (e) => {
    e.preventDefault();
    const { target } = e;
    onSaveAlert(e, Object.fromEntries(new FormData(target)));
  };

  const onSaveAlert = async (e, obj) => {
    e.preventDefault();
    var objData = { ...obj, ...objAssignData };
    objData.orgId = orgId;
    objData.resellerId = resellerId;
    objData.orgType = orgType;
    objData.vehicleId = payload.vehicleId;
    objData.logoName = "";
    //const responseData = await alertServices.saveAlert(objData);
    const responseData = await saveAlertList(objData);

    if (responseData.data.resultCode === 0) {
      dispatch(snackService(["Alert saved successfully", 0, true]));
    } else {
      dispatch(snackService(["Alert save is failed", 1, true]));
    }
  };

  const compareArray = (searchUser) => {
      let matchArry = searchUser.data.filter(leftId => 
          !right.some(rightId => leftId.id === rightId.id) 
      )
      setLeft(matchArry);
  }

  //search users
  const searchUsers = async (value, searchBy) => {
      if(searchBy === "unassign"){
        payload.searchText = value;
        const searchUser = await getUnassignUser(payload);
        compareArray(searchUser);
      }
  }

  const waitTime = 1000;
  let timer;
  //detect typing
  const detectTyping = (value, searchBy) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
          searchUsers(value, searchBy);
      }, waitTime);
  }





  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Alerts"
        subDiv="Assign Users & Alerts"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Assign Users & Alerts"} />
      {/* <CommonDropDown handleSmt={handleSubmit}  callbackLoadFn={callbackLoadFn}/> */}
      <AlertDropdown getData={getData} isShowAll={false} />

      <StyledToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleTabValue}
        size="small"
        sx={{ mt: 2 }}
      >
        <StyledToggleButton value={1} key={1}>
          Assign Users
        </StyledToggleButton>
        <StyledToggleButton value={2} key={2}>
          Assign Alerts
        </StyledToggleButton>
      </StyledToggleButtonGroup>

      {/* Assign Users */}
      <Box>
        {alignment === 1 && (
          <Box sx={{ my: 2 }}>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid item lg={3}>
                {/* {customList("Users", left)} */}
                <Card elevation={0} sx={{ border: `0.5px solid ${theme.palette.divider}`}}>
                    {
                      <>
                      <CardHeader
                        avatar={
                          <Checkbox
                          onClick={handleToggleAll(left)}
                          title="select all"
                          checked={
                          numberOfChecked(left) === left?.length && left?.length !== 0
                          }
                          indeterminate={
                          numberOfChecked(left) !== left?.length &&
                          numberOfChecked(left) !== 0
                          }
                          disabled={left?.length === 0}
                          inputProps={{
                          "aria-label": "all items selected",
                          }}
                          />
                        }
                        title={"Users"}
                        subheader={`${numberOfChecked(left)}/${left?.length} selected`}
                      />
                       <Divider />
                        <Box sx={{px:2, py:1, background:theme.palette.grey[100]}}>
                            <StyledPaperShadow sx={{px:1, py:.5}}>
                              <InputBase placeholder="Search User" sx={{width:'100%'}} onKeyUp={(event) => detectTyping(event.target.value, 'unassign')}/>
                            </StyledPaperShadow>
                        </Box>
                        <CardContent
                          sx={{
                          height: "300px",
                          overflowY: "scroll",
                          p: 0,
                          paddingBottom: "0px !important",
                          }}
                        >
                          {
                            unAssignUserLoading &&
                            <Box>
                              <ScaleLoader color={theme.palette.secondary.main}/>
                            </Box>
                          }
                        
                        {left.length > 0 ? (
                        <List dense role="list" style={{ borderRadius: "5px" }}>
                        {left.map((value, index) => {
                        const labelId = `transfer-list-all-item-${value}-label`;
                        return (
                        <ListItemButton
                        key={index}
                        role="listitem"
                        onClick={handleToggle(value)}
                        value={value.userId}
                        selected={checked.indexOf(value) !== -1 && true}
                        dense
                        >
                        <ListItemIcon sx={{minWidth:'0px', mr:1}}>
                        <LuUser />
                        {/* <Checkbox
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                        'aria-labelledby': labelId,
                        }}
                        /> */}
                        </ListItemIcon>
                        <ListItemText primary={value.userName} />
                        </ListItemButton>
                        );
                        })}
                        </List>
                        ) : (
                        <ListItemButton disabled>No Users</ListItemButton>
                        )}
                        </CardContent>
                      </>
                    }
                </Card>
              </Grid>
              <Grid item lg={1}>
                <Grid container direction="column" alignItems="center">
                  <Button
                    sx={{
                      my: 0.5,
                      border: 1,
                      py: 0.5,
                      fontWeight: 600,
                      fontSize: 22,
                    }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                    color="secondary"
                  >
                    <BsArrowRightShort />
                  </Button>
                  <Button
                    sx={{
                      my: 0.5,
                      border: 1,
                      py: 0.5,
                      fontWeight: 600,
                      fontSize: 22,
                    }}
                    variant="outlined"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                    color="secondary"
                  >
                    <BsArrowLeftShort />
                  </Button>
                </Grid>
              </Grid>
              <Grid item lg={3}>
                {/* {customList("Assigned Users", right)} */}
                <Card elevation={0} sx={{ border: `0.5px solid ${theme.palette.divider}`}}>
                    {
                      <>
                      <CardHeader
                        avatar={
                          assignUserLoading ? <Skeleton variant="rounded" width={20} height={20} />:  
                          <Checkbox
                          onClick={handleToggleAll(right)}
                          title="select all"
                          checked={
                          numberOfChecked(right) === right.length && right.length !== 0
                          }
                          indeterminate={
                          numberOfChecked(right) !== right.length &&
                          numberOfChecked(right) !== 0
                          }
                          disabled={right.length === 0}
                          inputProps={{
                          "aria-label": "all items selected",
                          }}
                          />
                        }
                        title={ assignUserLoading ? <Skeleton variant="text"  sx={{fontSize: '1rem'}} /> : "Assign Users"}
                        subheader={assignUserLoading ? <Skeleton variant="text"  sx={{fontSize: '.5rem'}} /> : `${numberOfChecked(right)}/${right.length} selected`}
                      />
                       <Divider />
                        {/* <Box sx={{px:2, py:1, background:theme.palette.grey[100]}}>
                            <StyledPaperShadow sx={{px:1, py:.5}}>
                              <InputBase placeholder="Search User" sx={{width:'100%'}}/>
                            </StyledPaperShadow>
                        </Box> */}
                        <CardContent
                          sx={{
                          height: "300px",
                          overflowY: "scroll",
                          p: 0,
                          paddingBottom: "0px !important",
                          }}
                        >
                        {right.length > 0 ? (
                        <List dense role="list" style={{ borderRadius: "5px" }}>
                        {right.map((value, index) => {
                        const labelId = `transfer-list-all-item-${value}-label`;
                        return (
                        <ListItemButton
                        key={index}
                        role="listitem"
                        onClick={handleToggle(value)}
                        value={value.userId}
                        selected={checked.indexOf(value) !== -1 && true}
                        dense
                        >
                        <ListItemIcon sx={{minWidth:'0px', mr:1}}>
                        <FiUserCheck />
                        {/* <Checkbox
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                        'aria-labelledby': labelId,
                        }}
                        /> */}
                        </ListItemIcon>
                        <ListItemText primary={value.userName} />
                        </ListItemButton>
                        );
                        })}
                        </List>
                        ) : (
                        <ListItemButton disabled>No Users</ListItemButton>
                        )}
                        </CardContent>
                      </>
                    }
                </Card>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ my: 2 }}
                >
                  <SoftButton
                    variant="contained"
                    className="error"
                    color="error"
                    size="small"
                    onClick={resetUsers}
                  >
                    Reset
                  </SoftButton>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={assignUserEdit}
                    // disabled={rightChecked.length > 0 ? false : true}
                  >
                    {assignLoading ? (
                      <ScaleLoader
                        color={theme.palette.primary.contrastText}
                        height={14}
                      />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>

      {/* Assign Alerts */}
      <Box>
        {alignment === 2 && (
          <Grid container spacing={2} sx={{ my: 2, justifyContent: "center" }}>
            <Grid item lg={7}>
              <form onSubmit={handleSaveAlert}>
                <StyledPaperShadow sx={{ my: 1 }}>
                  <StyledAccordion
                    disableGutters
                    expanded={panel1}
                    onChange={() => setPanel1(!panel1)}
                    className="themeAccordian"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      List Of Alerts
                    </AccordionSummary>
                    <AccordionDetails sx={{ position: "relative" }}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid
                          container
                          lg={12}
                          sx={{
                            borderBottom: `.5px solid ${alpha(
                              theme.palette.divider,
                              0.05
                            )}`,
                            px: 1,
                            py: 0.5,
                            position: "sticky",
                            top: "-15px",
                            background: theme.palette.background.paper,
                            zIndex: 999,
                          }}
                        >
                          <Grid item xs={4} sx={{ px: 2 }}>
                            <Typography variant="body2" color={"secondary"}>
                              Alert Names
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sx={{ textAlign: "center" }}>
                            <Typography variant="body2" color={"secondary"}>
                              Active / Inactive
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sx={{ textAlign: "center" }}>
                            <Typography variant="body2" color={"secondary"}>
                              Limits
                            </Typography>
                          </Grid>
                        </Grid>
                        <CardHeader
                          title={
                            <Typography
                              sx={{ textAlign: "left" }}
                              variant="subtitle1"
                            >
                              Application Alerts
                            </Typography>
                          }
                          sx={{
                            textAlign: "left",
                            width: "100%",
                            background: theme.palette.background.default,
                            py: 1,
                            px: 2,
                          }}
                        />

                        {orgAlertList !== null && orgAlertList.length > 0 ? (
                          orgAlertList?.map((org) => (
                            <Grid
                              container
                              lg={12}
                              sx={{
                                borderBottom: `.5px solid ${alpha(
                                  theme.palette.divider,
                                  0.05
                                )}`,
                                px: 1,
                                py: 0.5,
                              }}
                            >
                              <Grid item xs={4}>
                                <ListItem dense={true}>
                                  <ListItemText primary={org.value} />
                                </ListItem>
                              </Grid>
                              {responseAlertData !== null &&
                                responseDataSwitch[`${org.key}Enable`] !=
                                  null && (
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <StyledSwitch
                                      color="success"
                                      value={
                                        responseDataSwitch[
                                          `${org.key}Enable`
                                        ] === 1
                                          ? 1
                                          : 0
                                      }
                                      checked={
                                        responseDataSwitch[
                                          `${org.key}Enable`
                                        ] === 1
                                          ? true
                                          : false
                                      }
                                      name={`${org.key}Enable`}
                                      onChange={(e) => {
                                        handleSwitchChange(
                                          e,
                                          `${org.key}Enable`
                                        );
                                      }}
                                    />
                                  </Grid>
                                )}

                              {responseAlertData !== null &&
                                responseDataSwitch[`${org.key}Limit`] !==
                                  null &&
                                responseDataSwitch[`${org.key}Limit`] !==
                                  undefined && (
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{ textAlign: "center" }}
                                  >
                                    <StyledDropDown
                                      sx={{ m: 1, minWidth: 120 }}
                                      size="small"
                                      value={
                                        responseDataSwitch[
                                          `${org.key}Limit`
                                        ] !== null
                                          ? responseDataSwitch[
                                              `${org.key}Limit`
                                            ]
                                          : ""
                                      }
                                      name={`${org.key}Limit`}
                                      onChange={(e) => {
                                        handleSelectChange(
                                          e,
                                          `${org.key}Limit`
                                        );
                                      }}
                                    >
                                      {responseAlertData !== null &&
                                        responseAlertData[org.key]?.map(
                                          (list) => (
                                            <MenuItem
                                              value={list.key}
                                              onMouseEnter={(e) =>
                                                (e.target.style.backgroundColor =
                                                  "#bdbdbd")
                                              }
                                              onMouseLeave={(e) =>
                                                (e.target.style.backgroundColor =
                                                  "")
                                              }
                                            >
                                              {list.value}
                                            </MenuItem>
                                          )
                                        )}
                                    </StyledDropDown>
                                  </Grid>
                                )}
                            </Grid>
                          ))
                        ) : (
                          <Typography
                            variant="subtitle1"
                            sx={{
                              background: theme.palette.background.paper,
                              width: "100%",
                              textAlign: "center",
                              padding: "15px",
                            }}
                          >
                            No Alerts
                          </Typography>
                        )}

                        <CardHeader
                          title={
                            <Typography
                              sx={{ textAlign: "left" }}
                              variant="subtitle1"
                            >
                              Device Alerts
                            </Typography>
                          }
                          sx={{
                            textAlign: "left",
                            width: "100%",
                            background: theme.palette.background.default,
                            py: 1,
                            px: 2,
                          }}
                        />
                        {deviceAlertList !== null &&
                        deviceAlertList.length > 0 ? (
                          deviceAlertList.map((org1) => (
                            <Grid
                              container
                              lg={12}
                              sx={{
                                borderBottom: `.5px solid ${alpha(
                                  theme.palette.divider,
                                  0.05
                                )}`,
                                px: 1,
                                py: 0.5,
                              }}
                            >
                              <Grid item xs={4}>
                                <ListItem dense={true}>
                                  <ListItemText primary={org1.value} />
                                </ListItem>
                              </Grid>
                              {responseDataSwitch[`${org1.key}Enable`] !=
                                null && (
                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <StyledSwitch
                                    color="success"
                                    value={
                                      responseDataSwitch[
                                        `${org1.key}Enable`
                                      ] === 1
                                        ? 1
                                        : 0
                                    }
                                    checked={
                                      responseDataSwitch[
                                        `${org1.key}Enable`
                                      ] === 1
                                        ? true
                                        : false
                                    }
                                    name={`${org1.key}Enable`}
                                    onChange={(e) => {
                                      handleSwitchChange(
                                        e,
                                        `${org1.key}Enable`
                                      );
                                    }}
                                  />
                                </Grid>
                              )}
                              {responseDataSwitch[`${org1.key}Limit`] !==
                                null &&
                                responseDataSwitch[`${org1.key}Limit`] !==
                                  undefined && (
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{ textAlign: "center" }}
                                  >
                                    <Select
                                      sx={{ m: 1, minWidth: 120 }}
                                      size="small"
                                      value={
                                        responseDataSwitch[
                                          `${org1.key}Limit`
                                        ] !== null
                                          ? responseDataSwitch[
                                              `${org1.key}Limit`
                                            ]
                                          : ""
                                      }
                                      name={`${org1.key}Limit`}
                                      onChange={(e) => {
                                        handleSelectChange(
                                          e,
                                          `${org1.key}Limit`
                                        );
                                      }}
                                    >
                                      {responseAlertData !== null &&
                                        responseAlertData[org1.key].map(
                                          (list) => (
                                            <MenuItem
                                              value={list.key}
                                              onMouseEnter={(e) =>
                                                (e.target.style.backgroundColor =
                                                  "#bdbdbd")
                                              }
                                              onMouseLeave={(e) =>
                                                (e.target.style.backgroundColor =
                                                  "")
                                              }
                                            >
                                              {list.value}
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>
                                  </Grid>
                                )}
                            </Grid>
                          ))
                        ) : (
                          <Typography
                            variant="subtitle1"
                            sx={{
                              background: theme.palette.background.paper,
                              width: "100%",
                              textAlign: "center",
                              padding: "15px",
                            }}
                          >
                            No Alerts
                          </Typography>
                        )}

                        <CardHeader
                          title={
                            <Typography
                              sx={{ textAlign: "left" }}
                              variant="subtitle1"
                            >
                              System Alerts
                            </Typography>
                          }
                          sx={{
                            textAlign: "left",
                            width: "100%",
                            background: theme.palette.background.default,
                            py: 1,
                            px: 2,
                          }}
                        />
                        {systemAlertList !== null &&
                        systemAlertList.length > 0 ? (
                          systemAlertList.map((org1) => (
                            <Grid container xs={12}>
                              <Grid item xs={4}>
                                <ListItem dense={true}>
                                  <ListItemText primary={org1.value} />
                                </ListItem>
                              </Grid>
                              {responseDataSwitch[`${org1.key}Enable`] !=
                                null && (
                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <StyledSwitch
                                    color="success"
                                    value={
                                      responseDataSwitch[
                                        `${org1.key}Enable`
                                      ] === 1
                                        ? 1
                                        : 0
                                    }
                                    checked={
                                      responseDataSwitch[
                                        `${org1.key}Enable`
                                      ] === 1
                                        ? true
                                        : false
                                    }
                                    name={`${org1.key}Enable`}
                                    onChange={(e) => {
                                      handleSwitchChange(
                                        e,
                                        `${org1.key}Enable`
                                      );
                                    }}
                                  />
                                </Grid>
                              )}
                              {responseDataSwitch[`${org1.key}Limit`] !==
                                null &&
                                responseDataSwitch[`${org1.key}Limit`] !==
                                  undefined && (
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{ textAlign: "center" }}
                                  >
                                    <Select
                                      sx={{ m: 1, minWidth: 120 }}
                                      size="small"
                                      value={
                                        responseDataSwitch[
                                          `${org1.key}Limit`
                                        ] !== null
                                          ? responseDataSwitch[
                                              `${org1.key}Limit`
                                            ]
                                          : ""
                                      }
                                      name={`${org1.key}Limit`}
                                      onChange={(e) => {
                                        handleSelectChange(
                                          e,
                                          `${org1.key}Limit`
                                        );
                                      }}
                                    >
                                      {responseAlertData !== null &&
                                        responseAlertData[org1.key].map(
                                          (list) => (
                                            <MenuItem
                                              value={list.key}
                                              onMouseEnter={(e) =>
                                                (e.target.style.backgroundColor =
                                                  "#bdbdbd")
                                              }
                                              onMouseLeave={(e) =>
                                                (e.target.style.backgroundColor =
                                                  "")
                                              }
                                            >
                                              {list.value}
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>
                                  </Grid>
                                )}
                            </Grid>
                          ))
                        ) : (
                          <Typography
                            variant="subtitle1"
                            sx={{
                              background: theme.palette.background.paper,
                              width: "100%",
                              textAlign: "center",
                              padding: "15px",
                            }}
                          >
                            No Alerts
                          </Typography>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </StyledAccordion>
                </StyledPaperShadow>

                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ my: 2 }}
                >
                  <StyledFiterButton
                    size="small"
                    onClick={handleAlertCancel}
                    color="error"
                    disabled={payload.vehicleId === undefined}
                  >
                    Reset
                  </StyledFiterButton>
                  <StyledFiterButton
                    variant="contained"
                    size="small"
                    type="submit"
                    //disabled={payload.vehicleId === undefined}
                  >
                    {saveAlertLoading ? (
                      <ScaleLoader
                        color={theme.palette.primary.contrastText}
                        height={12}
                      />
                    ) : (
                      "Save"
                    )}
                  </StyledFiterButton>
                </Stack>
              </form>
            </Grid>
          </Grid>
        )}
      </Box>
      {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )}
    </StyledPageLayout>
  );
};

export default Alerts;



