import React, { useEffect } from 'react'
import { StyledPaperShadow } from '../../theme/StyledEle'
import { Box, Typography, Divider, Stack } from '@mui/material'
import { Timeline } from '@mui/lab'
import TimelineNewItem from './TimelineNewItem'
import { useRouteTimelineMutation } from '../../store/slice/TimelineSlice/TimelineSlice'
import TimelineDateWrapper from './TimelineDateWrapper'

function TimelineBase() {

    const [ getRouteTimeline, {data, isLoading, isSuccess, isError} ] = useRouteTimelineMutation();

    const getTimeline = async () => {
        let timelinePayload = {
            "startDate": "2024-04-03 00:00:00",
            "endDate": "2024-04-03 23:59:59",
            "vehicleId": 26778,
            "orgId": "110385",
            "page": 0,
            "size": 25,
            "routeId": "-1",
            "searchText": "",
            "customerId": "110384"
        }
        const timelineData = await getRouteTimeline(timelinePayload);
        console.log('timelineData', timelineData);
    }

    useEffect(() => {
        getTimeline();
    },[])

    console.log('data', data);


   const  TimelineWrapper = () => {
        return(
            <Timeline sx={{p:0, m:0}}>
                {
                    data != undefined && data.data !== null && data.data.map((item, index) => {
                        return (
                            <TimelineDateWrapper data={item}/>
                            // <TimelineNewItem key={index} item={item} length={data?.data?.length} index={index}/>
                        )
                    })
                }
            </Timeline>
        )
   } 


  return (
    <Box>
        <StyledPaperShadow sx={{display:'block', p:0}}>
            <Stack sx={{p:1}}>
                <Typography>Timeline</Typography>
            </Stack>
            <Divider />
            
            <Box>
                <TimelineWrapper />
            </Box>
        </StyledPaperShadow>
    </Box>
  )
}

export default TimelineBase