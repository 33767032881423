import React, { useState, useMemo, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Typography,
  Link,
  CardHeader,
  CardContent,
  Stack,
  CardMedia,
  Chip,
  Collapse,
} from "@mui/material";
import { Paper } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import { styled } from "@mui/material/styles";
import DeviceData from "../../xls/device/DeviceTemplateNew.xls";
import DevicStatus from "../../xls/device/deviceStatus.xls";
import DeviceSimMap from "../../xls/device/DeviceSimMapNew.xls";
import DeviceLotNumber from "../../xls/device/DeviceLotNumber.xls";
import DeviceSim from "../../xls/device/DeviceActivate.xls";
import VinDealerMap from "../../xls/device/DeviceLotUpdate.xls";
import DraggableDialog from "../shared/DailogDrag";
import deviceServices from "../../services/deviceService";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";


import { FormControl, FormHelperText, Select, MenuItem } from "@mui/material";
import {
  FileUploadBox,
  StyledCardShadow,
  StyledFilterDropDown,
  StyledFilterFormControl,
  StyledFormControlLabel,
  StyledPaperShadow,
  StyledTableTitle,
} from "../../theme/StyledEle";
import uploadcsv from "../../assets/images/uploadcsv.png";
import { useDeviceValidateMutation } from "../../store/slice/DeviceSlice/DeviceSlice";
import { ActionMockData, CplanMockData, TplanMockData } from "./Constans";
import SingleDropdown from "../NewDropdown/SingleDropdown";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { useValidateDeviceMutation } from "../../store/slice/UploadSlice";
import { useDispatch } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
function DeviceUpload({ onCancel }) {
  const [file, setFile] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [dataTable, setDataTable] = useState(false);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState(0);
  const [template, setTemplate] = useState(4);
  const [commercialPlan, setCommercialPlan] = useState(0);
  const [testPlan, setTestPlan] = useState(0);
  const [cols, setCols] = useState(null);
  const [validate, setValidate] = useState(false);
  const globalJson2 =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
  var globaldisplayview = "";
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;
  var globalUserName = "";
  var globalOrgName = "";


  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson.resellerId;
    globaldisplayview = globalJson.displayView;
    globalUserName =  globalJson.userName;
    globalOrgName = globalJson.orgName;
  }

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: globalOrgId,
    userId: globaluserId, 
    orgName: globalOrgName, 
    userName: globalUserName,
  };

  //Validate Excel
  const [
    validateXls,
    {
      data: validateData,
      isLoading: validateLoading,
      isSuccess: validateSuccess,
    },
  ] = useDeviceValidateMutation();

  //Activate Columns
  const activateCols = useMemo(() => [
    {
      Header: "Device IMEI",
      accessor: "imei",
    },
    {
      Header: "Sim Number",
      accessor: "sim",
    },
  ]);

  //Device Status
  const deviceStatusCols = useMemo(() => [
    {
      Header: "Device IMEI",
      accessor: "imei",
    },
    {
      Header: "Sim",
      accessor: "sim",
    },
  ]);

  //Device lot Update
  const deviceLotUpdate = useMemo(() => [
    {
      Header: "Lot Number",
      accessor: "lotNumber",
    },
    {
      Header: "InvioceNumber",
      accessor: "invoiceNumber",
    },
    {
      Header: "Invioce Date",
      accessor: "invoiceDate",
    },
    {
      Header: "Dispatch Date",
      accessor: "dispatchDate",
    },
  ]);

  //Device Status
  const lotNumber = useMemo(() => [
    {
      Header: "Device IMEI",
      accessor: "imei",
    },
    {
      Header: "Lot Number",
      accessor: "lotNumber",
    },
  ]);

  //Device Sim Mapping
  const deviceSimMap = useMemo(() => [
    {
      Header: "Device IMEI",
      accessor: "imei",
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ]);

  //Device Sim Mapping
  const deviceStatus = useMemo(() => [
    {
      Header: "Device IMEI",
      accessor: "imei",
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ]);

  //SetCols
  const setColumns = (columnId) => {
    if (columnId === 1) {
      // Activate
      setCols(activateCols);
    } else if (columnId === 2) {
      //device status
      setCols(deviceStatus);
    } else if (columnId === 6) {
      // Lot Number Update
      setCols(deviceLotUpdate);
    } else if (columnId === 4) {
      // Device Upload
      setCols(deviceStatusCols);
    } else if (columnId === 5) {
      // Device Sim Map
      setCols(deviceSimMap);
    } else if (columnId === 3) {
      // Device Lot Numvber
      setCols(lotNumber);
    }
  };

  const handleSelectChange = (actionId) => {
    setOption(actionId);
    setTemplate(actionId);
    setColumns(actionId);
    setFile([]);
    setIsFilePicked(false);
    setResponseData([]);
    setValidate(false);
    setDataTable(false);

      auditPayload.message = "Admin > Device > Import Device Details > Action";
      auditPayload.action = "Select";
      auditPayload.featureName = "Device";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = globalUserName;
  
      auditLog(auditPayload);
  };


const [ validateDevice, {data:validateDeviceData, isLoading:validateDeviceLoading, isSuccess:validateDeviceSuccess} ] = useValidateDeviceMutation()
const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      planCode: commercialPlan,
      testPlan: testPlan,
    };

    const formData = new FormData();
    formData.append("file", file[0]);
    formData.append("params", JSON.stringify(payload));

    const response = await deviceServices.uploadDevice(formData);
    var obj1 = {};

    obj1.dialogButtonText = "Upload Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = "Uploaded Successfully";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(response.data.resultText);
    setOpen(true);
    if (responseData.status === 200) {
      setOpen(false);
      setTimeout(() => onCancel(), 2000);
    }

    auditPayload.message = "Admin > Device > Import Device Details > Upload";
    auditPayload.action = "Upload";
    auditPayload.featureName = "Device";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;

    auditLog(auditPayload);
  };

  const handleValidate = async (e) => {
    e.preventDefault();
    const payload = {
      file: file[0],
      planCode: commercialPlan,
      testPlan: testPlan,
    };
    let formData = new FormData();
    formData.append(`file`, file[0]);
    formData.append(`planCode`, commercialPlan);
    formData.append(`testPlan`, testPlan);

    //validateXls(payload);
    //const responseData = await deviceServices.validateDevice(payload);
    const responseData = await validateDevice(formData);
    dispatch(snackService([responseData.data.isValidateSuccess ? 'Validate Successfully' : 'Device Already Exists' , responseData.data.isValidateSuccess? 0 : 1 , true]))
    if (
      responseData.data !== "invalidtemplate" &&
      responseData.data !== "excesscount" &&
      Object.keys(responseData.data).length > 0
    ) {
      setResponseData(responseData.data.result);
      setDataTable(true);
      if (responseData.data.isValidateSuccess == true) {
        setValidate(true);
      } else {
        setValidate(false);
      }
    } else {
      setResponseData([]);
    }

    var obj1 = {};

    obj1.dialogText =
      responseData.data === "excesscount"
        ? "Please upload valid count"
        : responseData.data === "invalidtemplate"
        ? "Please Upload Valid Template"
        : "Validated Successfully";

    //setDataStatus(true);
    //setDialogContent(obj1);
    // setDataStatusConfirm(
    //   responseData.data !== "excesscount" &&
    //     responseData.data !== "invalidtemplate"
    //     ? "Success"
    //     : "Failed"
    // );
    //setOpen(true);

    if (responseData.status === 200) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }

    auditPayload.message = "Admin > Device > Import Device Details > Validate";
    auditPayload.action = "Validate";
    auditPayload.featureName = "Device";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;

    auditLog(auditPayload);

  };


  const [isFilePicked, setIsFilePicked] = useState(false);
  const FileRef = useRef();

  const handleOnChange = (e) => {
    setFile([e.target.files[0]]);
    setIsFilePicked(true);
  };

  const handleCommercialChange = (cplanId) => {
    setCommercialPlan(cplanId);
  };

  const handleTestChange = (testPlanId) => {
    setTestPlan(testPlanId);
  };


  const handleTemplateClick = () =>{
    auditPayload.message = "Admin > Device > Import Device Details > Download Template";
    auditPayload.action = "Download";
    auditPayload.featureName = "Device";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;

    auditLog(auditPayload);
  }

  function temp() {
    switch (template) {
      case 1:
        return (
          <Button
            href={DeviceSim}
            variant="contained"
            size="small"
            component={Link}
            onClick={handleTemplateClick}
          >
            Download Activate
          </Button>
        );
        break;
      case 2:
        return (
          <Button
            href={DevicStatus}
            variant="contained"
            size="small"
            component={Link}
            onClick={handleTemplateClick}
          >
            Download Device Status{" "}
          </Button>
        );
        break;
      case 3:
        return (
          <Button
            href={DeviceLotNumber}
            variant="contained"
            size="small"
            component={Link}
          >
            Download Device Lot Number
          </Button>
        );
        break;
      case 4:
        return (
          <Button
            href={DeviceData}
            variant="contained"
            size="small"
            component={Link}
          >
            Download Device Upload
          </Button>
        );
        break;
      case 5:
        return (
          <Button
            href={DeviceSimMap}
            variant="contained"
            size="small"
            component={Link}
          >
            Download Device Sim Map
          </Button>
        );
        break;
      case 6:
        return (
          <Button
            href={VinDealerMap}
            variant="contained"
            size="small"
            component={Link}
          >
            Download Lot Update
          </Button>
        );
        break;
    }
  }

  useEffect(() => {
    temp();
  }, [template]);

  useEffect(async () => {
    auditPayload.message = "Admin > Device > Import Device Details";
    auditPayload.action = "View";
    auditPayload.featureName = "Device";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;

    auditLog(auditPayload);
  }, []);

  return (
    <>
      <StyledPaperShadow>
        <Stack direction="row">
          <SingleDropdown
            label={"Action"}
            listItems={{ content: ActionMockData }}
            handleChange={handleSelectChange}
            value={option}
            isSearch={false}
            showAll={false}
          />
          {/* <StyledFilterFormControl sx={{minWidth:'165px'}}>
                <StyledFormControlLabel>Action</StyledFormControlLabel>
                <StyledFilterDropDown
                value={option}
                onChange={handleSelectChange}
                displayEmpty
                size='small'
                >
                <MenuItem value={1}>Activate</MenuItem>
                <MenuItem value={2}>Device Status</MenuItem>
                <MenuItem value={3}>Lot Number</MenuItem>
                <MenuItem value={4}>Device upload</MenuItem>
                <MenuItem value={5}>Device Sim Map</MenuItem>
                <MenuItem value={6}>Lot Update</MenuItem>
                </StyledFilterDropDown>
                </StyledFilterFormControl> */}
          {
            template === 1 && (
              <SingleDropdown
                label={"Commercial Plan"}
                listItems={{ content: CplanMockData }}
                handleChange={handleCommercialChange}
                value={commercialPlan}
                isSearch={false}
                showAll={false}
              />
            )
            // <StyledFilterFormControl sx={{minWidth:'165px'}}>
            //     <StyledFormControlLabel>Commercial Plan</StyledFormControlLabel>
            //     <StyledFilterDropDown
            //     value={commercialPlan}
            //     onChange={handleCommercialChange}
            //     displayEmpty
            //     size='small'
            //     >
            //     <MenuItem value={0}>None</MenuItem>
            //     <MenuItem value={'PKG_28684'}>Plus</MenuItem>
            //     <MenuItem value={'PKG_28683'}>Basic</MenuItem>
            //     </StyledFilterDropDown>
            // </StyledFilterFormControl>
          }
          {
            template === 1 && (
              <SingleDropdown
                label={"Commercial Plan"}
                listItems={{ content: TplanMockData }}
                handleChange={handleTestChange}
                value={testPlan}
                isSearch={false}
                showAll={false}
              />
            )
            // <StyledFilterFormControl sx={{minWidth:'165px'}}>
            //       <StyledFormControlLabel>Test Plan</StyledFormControlLabel>
            //       <StyledFilterDropDown
            //       value={testPlan}
            //       onChange={handleTestChange}
            //       displayEmpty
            //       size='small'
            //       >
            //       <MenuItem value={0}>None</MenuItem>
            //       <MenuItem value={'PKG_36396'}>Test</MenuItem>
            //       </StyledFilterDropDown>
            // </StyledFilterFormControl>
          }
        </Stack>
      </StyledPaperShadow>
      <Collapse in={option !== 0 ? true : false}>
        <StyledCardShadow sx={{ my: 2 }}>
          <CardHeader
            title={<StyledTableTitle>Upload List</StyledTableTitle>}
            action={temp()}
          />
          <form onSubmit={handleSubmit}>
            <CardContent sx={{ py: 0 }}>
              <FileUploadBox>
                <input
                  type="file"
                  accept="application/vnd.ms-excel"
                  onChange={handleOnChange}
                  className="fileupload"
                  ref={FileRef}
                />
                <CardMedia
                  component="img"
                  src={uploadcsv}
                  sx={{ width: "60px", height: "60px", my: 2 }}
                />
                <Typography sx={{ mt: 2 }} variant="h6" color="primary">
                  Select a XLSs file to upload
                </Typography>
                {isFilePicked && (
                  <Chip
                    sx={{ ml: 1 }}
                    label={
                      FileRef?.current !== undefined &&
                      FileRef?.current?.files[0].name
                    }
                    color="warning"
                  />
                )}
              </FileUploadBox>
              <Stack
                direction="row"
                sx={{ mt: 2 }}
                spacing={2}
                justifyContent="center"
              >
                {file?.length > 0 && (
                  <Button variant="contained" onClick={handleValidate}>
                    Validate
                  </Button>
                )}
                {validate && dataTable === true && (
                  <Button variant="contained" color="success" type="submit">
                    Upload
                  </Button>
                )}
              </Stack>
            </CardContent>
          </form>
        </StyledCardShadow>
      </Collapse>
      <Box>
        {dataTable === true && file?.length > 0 && (
          <StyledCardShadow>
            <CardContent>
              <DataTable
                includePagination = {false}
includeExportButton={false}
                includeSearchBox={false}
                columns={cols}
                data={responseData}
                styles={{ maxWidth: "50vw" }}
              />
            </CardContent>
          </StyledCardShadow>
        )}
      </Box>
      {open && (
        <StyledSnackBar
          open={open}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )}
    </>
  );
}

export default DeviceUpload;
