import React, {useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import {CardContent, Button } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import MouseOverPopover from "../shared/PopOver";
import {
  StyledCardShadow,
  StyledPageLayout,
} from "../../theme/StyledEle";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import {
  useAuditLogMutation,
  useOverSpeedMutation,
} from "../../store/slice/ApiSlice";

const text =
  "This report shows various instances when the vehicle crossed the configured speed limit.";

const OverSpeedReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Reports > OverSpeed";
    auditPayload.action = "View";
    auditPayload.featureName = "OverSpeed";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

 
  const columns = React.useMemo(() => {
    const commonColumns = [
      {
        Header: "Vehicle Number",
        accessor: "vehicleNo",
        width:150,
      },
      {
        Header: "Customer Name",
        accessor: "customerName",
        width:120,
      },
      {
        Header: "Speed Limit (km/h)",
        accessor: "speedLimit",
        width:150,
      },
      {
        Header: "Max Speed (km/h)",
        accessor: "maxSpeed",
        width:100,
      },
      {
        Header: "Start Time",
        accessor: "startDate",
        width:120,
      },
      {
        Header: "End Time",
        accessor: "createdDate",
        width:120,
      },
      {
        Header: "Duration (hh:mm)",
        accessor: "duration",
        width:150,
      },
      // {
      //   Header: "Over Speed Count",
      //   accessor: "overspeedcount",
      // },

      {
        Header: "Start Location (Click to view location in map)",
        accessor: "startLoc",
        width:300,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.startLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.startLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.startLoc}
                >
                  {row.original.startLoc}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLoc}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.startLoc}
                  key={row.original.startLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },

      {
        Header: "End Location (Click to view location in map)",
        accessor: "gpsLocation",
        width:300,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.gpsLocation}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.gpsLocation}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.gpsLocation}
                >
                  {row.original.gpsLocation}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.gpsLocation}
                  key={row.original.gpsLocation}
                ></a>
              </Box>
            </Box>
          );
        },
      },
    ];
  
    if (resellerId === 110224) {
      const additionalColumns = [
        {
          Header: "SOC Difference (%)",
          accessor: "socConsumed",
          width: 120,
        },
        {
          Header: "Energy Consumed (kWh)",
          accessor: "energyConsumed",
          width: 150,
        },
        {
          Header: "Distance Traveled (km)",
          accessor: "overSpeedDistance",
          width: 150,
        },
        {
          Header: "Voltage at Max speed (v)",
          accessor: "voltageAtMaxSpeed",
          width: 150,
        },
        {
          Header: "Current at Max speed (A)",
          accessor: "currentAtMaxSpeed",
          width: 150,
        },
        {
          Header: "Temperature at Max speed (°C)",
          accessor: "temperatureAtMaxSpeed",
          width: 150,
        },
      ];
  
      return [...commonColumns, ...additionalColumns];
    }
  
    return commonColumns;
  }, [resellerId]);

  let initialState1 = {};
  var arr = [];

  if (resellerId !== 110224) {
 
    arr.push("customerName");
    initialState1.hiddenColumns = arr;
  }
  // Get Data from New Dropdown
  const [getOverSpeed, { data: oversppedData, isLoading, isSuccess }] =
    useOverSpeedMutation();
  let commonPayload = {};

  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.sortBy = "desc";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    
    //Service Call
    setPayload(commonPayload);
    getOverSpeed(commonPayload);

    auditPayload.message = "Reports > Overspeed > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Overspeed";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "OverSpeedReport/overSpeedXLS.xls?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&dealerId=" +
        payload.dealerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&customerId=" +
        payload.customerId;
      window.open(url);

      auditPayload.message = "Reports > OverSpeed > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "OverSpeed";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };
  const onChangePDF = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "OverSpeedReport/overSpeedPDF.pdf?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&dealerId=" +
        payload.dealerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&customerId=" +
        payload.customerId;

      window.open(url);

      auditPayload.message = "Reports > OverSpeed > PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "OverSpeed";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {}
  };
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getOverSpeed(payload);
    } else if (payload.search.length === 0) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getOverSpeed(payload);
      
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getOverSpeed(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getOverSpeed(payload);
    }
  };

  
  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (oversppedData.data !== null) {
        setResponseData(oversppedData.data.content);
        setTotalElements(oversppedData.data.totalElements);
        setTotalPages(oversppedData.data.totalPages);
      }
    }
  }, [isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="Overspeed"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Overspeed"} />
      <Box>
        <FilterDropdown
          getData={onSubmitForm}
          status={false}
          allowDays={resellerId === 110224 ? 31 : 7}
          allowToday={true}
          last6Month={true}
          time = {true}
          allowedMonth = {resellerId === 110224 ? 6 : 3}
          last31={resellerId === 110224 ? true : false}
          getDataLoading={isLoading}
        />

        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              initialState={initialState1}
              payload={payload}
              exportWithAll={false}
              columns={columns}
              includeSearchBox={true}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePDF}
              handleChange={handleSearch}
              includeExportButton={true}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={isLoading}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default OverSpeedReport;
