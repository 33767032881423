import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { CardContent, CardHeader} from "@mui/material";
import KYCUpload from "./KYCImport";
import { StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import KycLogDropdown from "../LogDropdown/KYCDropdown";
import { useListKYCFeedsMutation } from "../../store/slice/LogsSlice/LogsSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";

const ListKycFeed = ({ tabValue }) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [kycImport, setKycImport] = useState(false);

  //   await fetchKYCFeedDetails();
  // }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Portal Received Time",
        accessor: "createdDate",
      },
      {
        Header: "KYC Sent Time",
        accessor: "kycSentTime",
        width:180,
      },
      {
        Header: "KYC Captured Time",
        accessor: "kyccapturedTime",
        width:180,
      },
      {
        Header: "Vehicle Details",
        columns: [
          {
            Header: "VIN",
            accessor: "vin",
          },
          {
            Header: "Engine Number",
            accessor: "engineNumber",
          },
          {
            Header: "Year Of MFG",
            accessor: "yearOfmg",
          },
          {
            Header: "Vehicle Make",
            accessor: "vehicleMake",
          },
          {
            Header: "Vehicle Model",
            accessor: "vehicleModel",
          },
          // {
          //   Header: "Fuel Type",
          //   accessor: "fuelType",
          // },
        ],
      },
      {
        Header: "Customer Details",
        columns: [
          {
            Header: "Customer ID",
            accessor: "customerID",
          },
          {
            Header: "Name",
            accessor: "customerName",
          },
          {
            Header: "Mobile",
            accessor: "customerMobile1",
          },
          {
            Header: "Retail Invoice Number",
            accessor: "retailInvoiceNumber",
          },
          {
            Header: "Email",
            accessor: "customerEmail",
          },
          {
            Header: "Address",
            accessor: "customerAddress",
          },
          {
            Header: "KYC Proof1",
            accessor: "proofOfAddress",
          },
          {
            Header: "KYC Proof2 (Optional)",
            accessor: "proofOfIdentity",
          },
        ],
      },
      {
        Header: "Dealer Details",
        columns: [
          {
            Header: "Code",
            accessor: "dealerCode",
          },
          {
            Header: "Name",
            accessor: "dealerName",
          },
          {
            Header: "Address",
            accessor: "dealerAddress",
          },
          {
            Header: "Town",
            accessor: "dealerTown",
          },
          {
            Header: "Contact name",
            accessor: "dealerContactName",
          },
          {
            Header: "Mobile",
            accessor: "dealerContactNumber",
          },
        ],
      },

      {
        Header: "KYC Mode",
        accessor: "kycType",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Message",
        accessor: "errorMessage",
      },
    ],
    []
  );

  const handleOnClick = () => {
    setKycImport(true);
  };

  const [
    listKYCFeeds,
    { data: ListKycFeed, isLoading: ListKycLoading, isSuccess: ListKycSuccess },
  ] = useListKYCFeedsMutation();
  let commonPayload = {};
  const [payload, setPayload] = useState(null);
  const getData = (data) => {
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.sortBy = "desc";
    commonPayload.selectedType = data.searchType;
    commonPayload.vinVal = 0;
    commonPayload.state = data.statusId;
    commonPayload.retailVal = data.searchType;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.resellerId = resellerId;
    setPayload(commonPayload);
    listKYCFeeds(commonPayload);
  };

  useEffect(() => {
    if (ListKycSuccess) {
      setResponseData([]);
      if (ListKycFeed.data !== null) {
        setResponseData(ListKycFeed.data.content);
        setTotalElements(ListKycFeed.data.totalElements);
        setTotalPages(ListKycFeed.data.totalPages);
      }
    }

    auditPayload.message = "Reports > KYC Log > KYC Feeds";
    auditPayload.action = "View";
    auditPayload.featureName = "KYC Log";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  }, [ListKycSuccess]);

  const handleSearch = (searchText) => {
    setNewPage(0);
    setRowsPerPage(50);
    payload.search = searchText;
    if (payload.search.length >= 3) {
      listKYCFeeds(payload);
    } else if (payload.search.length === 0) {
      listKYCFeeds(payload);
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    listKYCFeeds(payload);
  };

  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "KYCLog/ListKYCfeedsXLS.csv?resellerId=" +
        payload.resellerId +
        "&selectedType=" +
        payload.selectedType +
        "&vinVal=" +
        payload.vinVal +
        "&state=" +
        payload.state +
        "&retailVal=" +
        payload.retailVal +
        "&sortBy=" +
        payload.sortBy +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        " ";
      window.open(url);

      auditPayload.message = "Logs > KYC Log > Export > Excel";
      auditPayload.action = "Download";
      auditPayload.featureName = "Logs";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;

      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => { })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };


  return (
    <Box>
      <KycLogDropdown
        getData={getData}
        isShowAll={false}
        showCalendar={false}
        tabValue={tabValue}
      />
      <StyledCardShadow sx={{ my: 2 }}>
      <CardHeader title={<StyledTableTitle>KYC Log</StyledTableTitle>} />
        <CardContent>
          {kycImport ? (
            <KYCUpload />
          ) : (
            <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
              <DataTable
                isLoading={ListKycLoading}
                includeSearchBox={true}
                handleChange={handleSearch}
                columns={columns}
                data={responseData}
                styles={{ maxWidth: "100vw" }}
                numberOfStickyColumns={1}
                page={newPage}
                rowsPerPage={rowsPerPage}
                callbackChangePage={pageChange}
                totalElements={totalElements}
                totalPages={totalPages}
                onChangeXcl={onChangeXcl}
              />
            </Box>
          )}
        </CardContent>
      </StyledCardShadow>
    </Box>
  );
};

export default ListKycFeed;
