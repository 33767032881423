import { Box, ClickAwayListener, Collapse, ListItem, ListItemIcon, ListItemText, Menu, Slide, Tooltip, Typography, alpha, useTheme } from '@mui/material'
import React, { createRef, useEffect, useRef, useState } from 'react'
import { SidebarListItem, StyledSidebarListBtn, StyledSidebarMenu, StyledSidebarSubLinkBox } from '../../../theme/StyledEle';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { RxTable, RxDashboard } from 'react-icons/rx';
import { RiLogoutCircleRLine} from 'react-icons/ri';
import { TbTableAlias, TbHeadset } from 'react-icons/tb';
import { BiCog, BiBell } from 'react-icons/bi';
import { MdOutlineNotificationsActive } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { FiChevronRight } from 'react-icons/fi';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { NavLink, useLocation } from 'react-router-dom';
import { Fade, ListItemButton } from "@mui/material";
import { BiSubdirectoryRight } from 'react-icons/bi'
import { HiOutlineChip } from 'react-icons/hi'
import { currentPage } from '../../../store/slice/ControlsSlice';
export const Menus = (props) => {
    const { menuItem, number, themeCustom, sidebarText,sidebarBg, open, handleChange, colTitle, colapseOpen, onClose, anchorEl  } = props;
    const mini = useSelector((state) => state.controls.customizer.mini);

//Menu Icons Store
var ex = <HiOutlineLocationMarker />;
    if (menuItem.icon === "LocationOnIcon") {
      ex = <HiOutlineLocationMarker />;
    } else if (menuItem.icon === "LogoutIcon") {
      ex = <RiLogoutCircleRLine />;
    } else if (menuItem.icon === "DashboardIcon") {
      ex = <RxDashboard />;
    } else if (menuItem.icon === "TableViewIcon") {
      ex = <TbTableAlias />;
    } else if (menuItem.icon === "GridOnIcon") {
      ex = <RxTable />;
    } else if (menuItem.icon === "SettingsIcon") {
      ex = <BiCog />;
    } else if (menuItem.icon === "NotificationsNoneIcon") {
      ex = <BiBell />;
    } else if (menuItem.icon === "HelpCenterIcon") {
      ex = <TbHeadset />;
    } else if (menuItem.icon === "NotificationsIcon") {
      ex = <MdOutlineNotificationsActive />;
    }
    else if (menuItem.icon === "fa-ota") {
      ex = <HiOutlineChip />
    }

    //Open SUb
    const theme = useTheme();
    const dispatch = useDispatch();
    //OnClick menu open
      const menuTitleTextStyles = {
        opacity: open ? 1 : 0,
        color: "#fff",
        ml: 1,
      };
      
      const location = useLocation();
    ///getSubLink
    const currentURL = location.pathname;
    var pathName = currentURL.split("/")[1];
    var test = null;
    let array = menuItem.sub;
    let mapped = array.map((ele) => ele.route);
    let found = mapped.includes(pathName);
    test = found;
    //open function
    let menuParentRef = useRef();
    useEffect(() => {
      let res = menuItem.sub.filter(item => item.route === pathName);
      if(res.length > 0){
        dispatch(currentPage(res[0].button))
      } 
    },[location.pathname])

    
    return(
        // <ClickAwayListener onClickAway={() => setOpenSub(false)} disableReactTree={true} touchEvent={false}>
    <SidebarListItem
            disableGutters
            disablePadding
            sx={{color: themeCustom === false ? '#fff' : sidebarBg === null ? '#fff' : sidebarText}}
            >
        <StyledSidebarListBtn onClick={handleChange(menuItem.title)} id={number} key={number} selected={test} className='mainListItem' ref={menuParentRef} data-select={test} data-label={menuItem.title} sidebarColor={theme.sidebar.background}>
                <Tooltip title={menuItem.title} placement="right" arrow disableTouchListener enterTouchDelay={1000}>
                <ListItemIcon sx={{color: themeCustom === false ? '#fff' : sidebarBg === null ? '#fff' : sidebarText}}>
                    {ex}
                </ListItemIcon>
                </Tooltip>
                <ListItemText sx={menuTitleTextStyles}>
                    {menuItem.title}
                </ListItemText>
                {colTitle === menuItem.title && colapseOpen ? ( <ExpandLess sx={{ marginLeft: "auto" }} className='arrowIcon'/>) : ( <ExpandMore sx={{ marginLeft: "auto" }} className='arrowIcon'/>)}
                <span className='topCurve'></span>
                <span className='bottomCurve'></span>
        </StyledSidebarListBtn>
        {menuItem.sub.length > 0 &&
        (!open && colapseOpen  ? (
            <StyledSidebarMenu
            elevation={1}
            anchorEl={anchorEl}
            open={colTitle === menuItem.title ? colapseOpen : false}
            onClose={onClose}
            TransitionComponent={Collapse}
            autoFocus={false}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                padding:"0px !important",
                overflow: 'visible',
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 25,
                  left: -6,
                  width: 12,
                  height: 12,
                  background:alpha(theme.palette.primary.dark, .9),
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                  border:`0.5px solid ${theme.palette.divider}`
                },
                '& .MuiList-root':{
                  py:0, 
                  borderRadius:'5px',
                  overflow:'hidden'
                }
              },
            }}
          >
            {menuItem.sub.map((subMenu, index) => ( //Menu Items
              <StyledSidebarSubLinkBox key={index} onClick={onClose} >
                {/* <KeyboardDoubleArrowRightIcon sx={{ fontSize: "14px" }} /> */}
                <SidebarListItem 
                disableGutters
                disablePadding
                id={menuItem.title}
                className='submenu'
                >
                  <StyledSidebarListBtn to={subMenu.route} component={NavLink}>
                  <ListItemText sx={{fontWeight:500}}>{subMenu.title}</ListItemText>
                  </StyledSidebarListBtn>
                </SidebarListItem>
              </StyledSidebarSubLinkBox>
            ))}
          </StyledSidebarMenu>
        ) : 
        <Collapse 
        in={colTitle === menuItem.title && colapseOpen && true} 
        unmountOnExit
        className='sidebarCollabse'
        >
  {
    menuItem.sub.map((subLinks, index) => {
        return(
            <SidebarListItem 
                disableGutters
                disablePadding
                id={menuItem.title}
                className='submenu'
                key={index}
                >
                    <StyledSidebarListBtn to={subLinks.route} component={NavLink} data-label={subLinks.title}>
                        <ListItemText sx={menuTitleTextStyles}>{subLinks.title}</ListItemText>
                    </StyledSidebarListBtn>
            </SidebarListItem>
       
        )
    })
}
 </Collapse>
        )}
        
        
    </SidebarListItem>
    // </ClickAwayListener>
    )
}