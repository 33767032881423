import React, { useState } from 'react'
import { SoftButton, StyledCardShadow, StyledDropDown, StyledFormControl, StyledSearchBarFilter, StyledTableTitle, StyledTextField } from '../../theme/StyledEle'
import { Autocomplete, Box, CardContent, CardHeader, Grid, MenuItem, Stack, Typography, alpha, useTheme } from '@mui/material'
import { useForm } from 'react-hook-form';
import CommonDropdown from './CommonDropdown';
import HelpdeskSettingOrgDropdown from './HelpdeskSettingOrgDropdown';

function Escalation(props) {
    const { orgType, userList=null, categoryist, register, setValue, reset, watch, resellerId, selectedOrgType, handleChangeDrodown, newUserList, list, userLevelHandleChange, level1, level2, level3, level4, level5, level6, handleSubmit, onSubmit, escalationTimeData } = props;
    const theme = useTheme();

    //set Empty
    const getOptionValue = (value, changedDropdown) => {
    }

    const EscalationRemainderDropdown = (props) => {
        const { registerValue,  } = props;
        return (
            <StyledFormControl fullWidth>
                <StyledDropDown size='small'  
                {...register(
                    `escalation_remainder_${registerValue}`, 
                    { required: {value:true, message:'Required Field'},})} 
                    onChange={(e) => { 
                        setValue(`escalation_remainder_${registerValue}`, e.target.value);
                        getOptionValue(e.target.value, `escalation_remainder_${registerValue}`);
                     }} 
                    value={watch(`escalation_remainder_${registerValue}`)} 
                    defaultValue={'-1'} >
                    <MenuItem value={'-1'}>Select</MenuItem>
                    {
                        escalationTimeData !== null && escalationTimeData !== undefined && escalationTimeData.map((item) => <MenuItem value={item.value}>{item.title}</MenuItem>)
                        
                    }
                    
                </StyledDropDown>
            </StyledFormControl>
        )
    }

    const EscalationToDropdown = (props) => {
        const { registerValue,  } = props;
        return(
            <StyledFormControl fullWidth>
                <StyledDropDown size='small' {...register(`escalation_to_${registerValue}`, { required: {value:true, message:'Required Field'},})} onChange={(e) => { setValue(`escalation_remainder_${registerValue}`, e.target.value) }} value={watch(`escalation_remainder_${registerValue}`)} defaultValue={'-1'} >
                    <MenuItem value={'-1'}>Select</MenuItem>
                    {
                        userList !== null && userList.length > 0 && userList.map((item, index) => {
                            return(
                                <MenuItem value={item.id}>{item.userName}</MenuItem>
                            )
                        })
                    }
                    
                </StyledDropDown>
            </StyledFormControl>
        )
    }

    let acceptUserLength = 5
  return (
    <StyledCardShadow>
        <CardHeader
            title={
                <StyledTableTitle sx={{ display: "block" }}>
                    Escalation Notification
                </StyledTableTitle>
            }
        />
        <CardContent sx={{p:0, paddingBottom:'0px !important'}} component={'form'} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{py:1, px:1}}>
            <HelpdeskSettingOrgDropdown categoryist={categoryist} register={register} setValue={setValue} reset={reset} watch={watch} resellerId={resellerId} selectedOrgType={selectedOrgType} handleChangeDrodown={handleChangeDrodown} userList={newUserList}/>
            </Box>
            <Grid container spacing={0} columnGap={3} sx={{p:1, background:alpha(theme.palette.divider, .05)}}>
                <Grid item lg={2}>
                    <Typography>Escalation Level</Typography>
                </Grid>
                <Grid item lg={4}>
                    <Typography>Reminder</Typography>
                </Grid>
                <Grid item lg={5}>
                    <Typography>To</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1} columnGap={3} sx={{p:1}}>
                <Grid item lg={2}>
                    <Typography>Level 1</Typography>
                </Grid>
                <Grid item lg={4}>
                    <EscalationRemainderDropdown registerValue={'l1'}/>
                </Grid>
                <Grid item lg={5}>
                    <Autocomplete
                        limitTags={1}
                        multiple
                        disableCloseOnSelect
                        id="multiple-limit-tags"
                        options={list.length > 0 && list}
                        value={level1}
                        getOptionLabel={(option) => option?.name || ''}
                        getOptionDisabled={(options) => (level1.length >= acceptUserLength ? true : false)}
                        onChange={(event, value) => userLevelHandleChange(value, 1)}
                        disabled={watch(`escalation_remainder_l1`) !== '-1' ? false : true}
                        //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                        renderInput={(params) => (
                        <StyledTextField {...params} placeholder={watch(`escalation_remainder_l1`) !== '-1' ? "Select Level 1 user" : 'Select Remainder'} size="small" fullWidth/>
                        )}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} columnGap={3} sx={{p:1}}>
                <Grid item lg={2}>
                    <Typography>Level 2</Typography>
                </Grid>
                <Grid item lg={4}>
                    <EscalationRemainderDropdown registerValue={'l2'}/>
                </Grid>
                <Grid item lg={5}>
                <Autocomplete
                        multiple
                        getOptionDisabled={(options) => (level2.length >= acceptUserLength ? true : false)}
                        limitTags={1}
                        disableCloseOnSelect
                        id="multiple-limit-tags"
                        options={list.length > 0 && list}
                        value={level2}
                        getOptionLabel={(option) => option?.name || ''}
                        onChange={(event, value) => userLevelHandleChange(value, 2)}
                        disabled={watch(`escalation_remainder_l2`) !== '-1' ? false : true}
                        //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                        renderInput={(params) => (
                        <StyledTextField {...params} placeholder={watch(`escalation_remainder_l2`) !== '-1' ? "Select Level 2 user" : 'Select Remainder'} size="small" fullWidth/>
                        )}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} columnGap={3} sx={{p:1}}>
                <Grid item lg={2}>
                    <Typography>Level 3</Typography>
                </Grid>
                <Grid item lg={4}>
                    <EscalationRemainderDropdown registerValue={'l3'}/>
                </Grid>
                <Grid item lg={5}>
                <Autocomplete
                        limitTags={1}
                        multiple
                        getOptionDisabled={(options) => (level3.length >= acceptUserLength ? true : false)}
                        disableCloseOnSelect
                        id="multiple-limit-tags"
                        options={list.length > 0 && list}
                        value={level3}
                        getOptionLabel={(option) => option?.name || ''}
                        onChange={(event, value) => userLevelHandleChange(value, 3)}
                        disabled={watch(`escalation_remainder_l3`) !== '-1' ? false : true}
                        //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                        renderInput={(params) => (
                        <StyledTextField {...params} placeholder={watch(`escalation_remainder_l3`) !== '-1' ? "Select Level 3 user" : 'Select Remainder'} size="small" fullWidth/>
                        )}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} columnGap={3} sx={{p:1}}>
                <Grid item lg={2}>
                    <Typography>Level 4</Typography>
                </Grid>
                <Grid item lg={4}>
                    <EscalationRemainderDropdown registerValue={'l4'}/>
                </Grid>
                <Grid item lg={5}>
                    <Autocomplete
                        limitTags={1}
                        multiple
                        getOptionDisabled={(options) => (level4.length >= acceptUserLength ? true : false)}
                        disableCloseOnSelect
                        id="multiple-limit-tags"
                        options={list.length > 0 && list}
                        value={level4}
                        getOptionLabel={(option) => option?.name || ''}
                        onChange={(event, value) => userLevelHandleChange(value, 4)}
                        disabled={watch(`escalation_remainder_l4`) !== '-1' ? false : true}
                        //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                        renderInput={(params) => (
                        <StyledTextField {...params} placeholder={watch(`escalation_remainder_l4`) !== '-1' ? "Select Level 4 user" : 'Select Remainder'} size="small" fullWidth/>
                        )}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} columnGap={3} sx={{p:1}}>
                <Grid item lg={2}>
                    <Typography>Level 5</Typography>
                </Grid>
                <Grid item lg={4}>
                    <EscalationRemainderDropdown registerValue={'l5'}/>
                </Grid>
                <Grid item lg={5}>
                <Autocomplete
                        limitTags={1}
                        multiple
                        getOptionDisabled={(options) => (level5.length >= acceptUserLength ? true : false)}
                        disableCloseOnSelect
                        id="multiple-limit-tags"
                        options={list.length > 0 && list}
                        value={level5}
                        getOptionLabel={(option) => option?.name || ''}
                        onChange={(event, value) => userLevelHandleChange(value, 5)}
                        disabled={watch(`escalation_remainder_l5`) !== '-1' ? false : true}
                        //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                        renderInput={(params) => (
                        <StyledTextField {...params} placeholder={watch(`escalation_remainder_l5`) !== '-1' ? "Select Level 5 user" : 'Select Remainder'} size="small" fullWidth/>
                        )}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} columnGap={3} sx={{p:1}}>
                <Grid item lg={2}>
                    <Typography>Critical Level</Typography>
                </Grid>
                <Grid item lg={4}>
                    <EscalationRemainderDropdown registerValue={'cl1'}/>
                </Grid>
                <Grid item lg={5}>
                    <Autocomplete
                        limitTags={1}
                        multiple
                        getOptionDisabled={(options) => (level6.length >= acceptUserLength ? true : false)}
                        disableCloseOnSelect
                        id="multiple-limit-tags"
                        options={list.length > 0 && list}
                        value={level6}
                        getOptionLabel={(option) => option?.name || ''}
                        onChange={(event, value) => userLevelHandleChange(value, 6)}
                        disabled={watch(`escalation_remainder_cl1`) !== '-1' ? false : true}
                        //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                        renderInput={(params) => (
                        <StyledTextField {...params} placeholder={watch(`escalation_remainder_cl1`) !== '-1' ? "Select critical Level user" : 'Select Remainder'} size="small" fullWidth/>
                        )}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} columnGap={3} sx={{p:1}}>
                <Stack sx={{px:1, width:'100%'}} direction={'row'} justifyContent={'flex-end'}>
                    <SoftButton
                    variant="contained"
                    className="success"
                    color="success"
                    disableElevation
                    type="submit"
                    >Save</SoftButton>
                </Stack>
            </Grid>
        </CardContent>
    </StyledCardShadow>
  )
}

export default Escalation