import React, { useEffect, useLayoutEffect, useState } from "react";
import SingleDropdown from "../NewDropdown/SingleDropdown";
import { StyledFiterButton, StyledPaperShadow } from "../../theme/StyledEle";
import { LogDropDownData } from "./dropDownData";
import { useLocation } from "react-router-dom";
import {
  useAuditOrgListMutation,
  useAuditUserListMutation,
  useGetDropdownListMutation,
  useGetVehicleDropdownListMutation,
  useAuditOrganizationListMutation,
  useGetUserListMutation
} from "../../store/slice/ApiSlice";
import {
  SearchByVin,
  actionData,
  alertModeDataMock,
  searchByDataAirtel,
  userLogAccessData,
  vinLogStatus,
  typeStatus
} from "./MockData";
import Item from "antd/lib/list/Item";
import moment from "moment";
import DatepickerDropdown from "../AdminDropdown/DatepickerDropdown";

function LogDropdown(props) {
  const { getData, time=false, allowDays, allowedMonth } = props;
  //user data form local storage
  const userDataLocal = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userDataLocal.resellerId;
  let orgId = userDataLocal.orgId;
  let RoleId = userDataLocal.roleId;
  let userOrgType = userDataLocal.userOrgType;
  let userId = userDataLocal.id;

  const menuList = JSON.parse(localStorage.getItem("menuList"));

  //All Dropdown State
  const [region, setRegion] = useState("-1");
  const [dealer, setDealer] = useState("-1");
  const [customer, setCustomer] = useState("-1"); // Audit Log
  const [fleet, setFleet] = useState("-1"); // Auit Log
  const [vehicle, setVehicle] = useState("-1");
  const [iemi, setIemi] = useState("-1");
  const [userOrg, setUserOrg] = useState("-1"); //Audit Log
  const [userList, setUserList] = useState("-1"); // Audit Log
  const [org, setOrg] = useState("-1"); //Audit Log
  const [alertMode, setAlertMode] = useState("0"); // Sent Log
  const [user, setUser] = useState("-1");
  const [mainMenu, setMainMenu] = useState("-1"); // Audit Log
  const [subMenu, setSubMenu] = useState("-1"); // Audit Log
  const [access, setAccess] = useState("All"); // Audit Log / User Log /
  const [searchby, setSearchby] = useState(null); // Vin Log
  const [status, setStatus] = useState("All"); // Vin Log
  const [vin, setVin] = useState("-1"); // Vin Log
  const [action, setAction] = useState(null); // Airtel Log
  const [type, setType] = useState("0"); // Vin Log

  //All Dropdown State Hide Show
  const [regionShow, setRegionShow] = useState(false);
  const [dealerShow, setDealerShow] = useState(false);
  const [customerShow, setCustomerShow] = useState(false); //Audit Log
  const [fleetShow, setFleetShow] = useState(false); //Audit Log
  const [vehicleShow, setVehicleShow] = useState(false);
  const [iemiShow, setIemiShow] = useState(false);
  const [userOrgShow, setUserOrgShow] = useState(false); //Audit Log
  const [userListShow, setUserListShow] = useState(false); // Audit Log
  const [resellerShow, setResellerShow] = useState(false);//Audit log-Super Admin
  const [alertModeShow, setAlertModeShow] = useState(false); // Sent Log
  const [userShow, setUserShow] = useState(false);
  const [mainMenuShow, setMainMenuShow] = useState(false); //Audit Log
  const [subMenuShow, setSubMenuShow] = useState(false); //Audit Log
  const [accessShow, setAccessShow] = useState(false); // Audit Log / User Log /
  const [searchbyShow, setSearchbyShow] = useState(false); // Vin Log
  const [statusShow, setStatusShow] = useState(false); // Vin Log
  const [vinShow, setVinShow] = useState(false); // Vin Log
  const [dateShow, setDateShow] = useState(true);
  const [actionShow, setActionShow] = useState(false); // airtel Log
  const [typeShow, setTypeShow] = useState(false); // Vin Log

  //All Dropdown State Data List
  const [regionData, setRegionData] = useState(null);
  const [dealerData, setDealerData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [fleetData, setFleetData] = useState(null);
  const [vehicleData, setVehicleData] = useState(null);
  const [iemiData, setIemiData] = useState({ content: null });
  const [userOrgData, setUserOrgData] = useState({ content: null }); //Audit Log
  const [userListData, setUserListData] = useState({ content: null }); // Audit Log
  const [orgData, setOrgData] = useState({ content: null }); //Audit Log
  const [alertModeData, setAlertModeData] = useState({
    content: alertModeDataMock,
  });
  const [userData, setUserData] = useState({ content: null });
  const [mainMenuData, setMainMenuData] = useState({ content: null });
  const [subMenuData, setSubMenuData] = useState({ content: null });
  const [accessData, setAccessData] = useState({ content: null });
  const [searchbyData, setSearchbyData] = useState({ content: null });
  const [statusData, setStatusData] = useState({ content: null });
  const [typeData, setTypeData] = useState({ content: null });


  const [regionName, setRegionName] = useState("All");
  const [dealerName, setDealerName] = useState("All");
  const [customerName, setCustomerName] = useState("All");
  const [fleetName, setFleetName] = useState("All");
  const [userName, setUserName] = useState("All");
  const [accessName, setAccessName] = useState("All");
  const [mainMenuName, setMainMenuName] = useState("All");
  const [subMenuName, setSubMenuName] = useState("All");
  const [userOrgName, setUserOrgName] = useState("All");
  const [userListName, setUserListName] = useState("All");


  //Payload
  let commonPaylod = {
    resellerId: resellerId,
    searchText: "",
  };

  //Slice Start
  const [
    getRegion,
    { data: regionList, isSuccess: regionListSuccess, isError },
  ] = useGetDropdownListMutation();
  const [getDealer, { data: dealerList, isSuccess: dealerListSuccess }] =
    useGetDropdownListMutation();
  const [getCustomer, { data: customerList, isSuccess: customerListSuccess }] =
    useGetDropdownListMutation();
  const [getFleet, { data: fleetList, isSuccess: fleetListSuccess }] =
    useGetDropdownListMutation();
  const [getVehicle, { data: vehicleList, isSuccess: vehicleListSuccess }] =
    useGetVehicleDropdownListMutation();
  const [getUsers, { data: usersData, isSuccess: usersDataSuccess }] =
  useGetUserListMutation(); // User Log
  //const [ getUserOrg, {data:userOrgData, isSuccess:userOrgDataSuccess } ] = useGetDropdownListMutation(); // User Log
  const [getUserOrg, { data: userOrgDataList, isSuccess: userOrgDataSuccess }] =
    useAuditOrgListMutation(); // Audit Log

  const [getUserList, { data: userDataList, isSuccess: userDataListSuccess }] =
    useAuditUserListMutation(); // Audit Log
    const [getAuditOrg, { data: orgDataList, isSuccess: orgDataSuccess }] =
    useAuditOrganizationListMutation(); // Audit Log

    
  const [load, setLoad] = useState(false);
  // Show Dropdown based on Role IDs
  const location = useLocation();
  let pathname = location.pathname.split("/")[1];
  useEffect(() => {
    if (userOrgType === 8 && RoleId === 1) {
      // Super Admin
      setResellerShow(true);
      getAuditOrg();
      setRegionShow(true);
      setDealerShow(true);
      setUserOrgShow(true);
      setUserListShow(true);
      setCustomerShow(true);
      setFleetShow(true);
      setMainMenuShow(true);
      setSubMenuShow(true);
      setAccessShow(true);

     
      commonPaylod.value = "region";
      getRegion(commonPaylod);
      setAccessData({ content: userLogAccessData });
    } else if (userOrgType === 2) {
      if (pathname === "UserLog") {
        // User Log Page Start
        setRegionShow(true);
        setDealerShow(true);
        setCustomerShow(true);
        setUserShow(true);
        setAccessShow(true);

        //Onload Get Customer list
        // commonPaylod.dealerId = 0;
        // commonPaylod.value = "customer";
        // getCustomer(commonPaylod);
        
         //Get Region onLoad
         commonPaylod.value = "region";
         getRegion(commonPaylod);

        //Access Data
        setAccess("All");
        setAccessData({ content: userLogAccessData });
      } else if (pathname === "SendLog" || pathname === "FeedLog") {
        // Sent Log
        setRegionShow(true);
        setDealerShow(true);
        setCustomerShow(true);
        setFleetShow(true);
        setVehicleShow(true);
        setAlertModeShow(true);
        if (pathname === "FeedLog") {
          setIemiShow(true);
        }
        //Get Region onLoad
        commonPaylod.value = "region";
        getRegion(commonPaylod);
      } else if (pathname === "AuditLog") {
        setRegionShow(true);
        setDealerShow(true);
        setUserOrgShow(true);
        setUserListShow(true);
        setCustomerShow(true);
        setFleetShow(true);
        setMainMenuShow(true);
        setSubMenuShow(true);
        setAccessShow(true);

        //Onload
        //User Org List
        let payload = {
          id: resellerId,
          orgType: userOrgType,
        };
        getUserOrg(payload);
        // commonPaylod.value = "customer";
        // commonPaylod.dealerId = 0;
        // getCustomer(commonPaylod);

        // let fleetActionPayload = {
        //   customerId: "0",
        //   value: "fleet",
        //   resellerId: resellerId,
        // };
        // getFleet(fleetActionPayload);
        commonPaylod.value = "region";
        getRegion(commonPaylod);
        setAccessData({ content: userLogAccessData });
      } else if (pathname === "VinLog") {
        setSearchbyShow(true);
        setStatusShow(true);
        setTypeShow(true);
        setIemi("");
        setVin("");
        //Onload
        setSearchby("Date");
        setSearchbyData({ content: SearchByVin });
        setStatusData({ content: vinLogStatus });
        setTypeData({ content: typeStatus });
      } else if (pathname === "AirtelLog") {
        setActionShow(true);
        setSearchbyShow(true);
        setSearchby("0");
        setAction("All");
        setSearchbyData({ content: searchByDataAirtel });
        setDateShow(false);
      }
    }else if (userOrgType === 5) {
      if (pathname === "UserLog") {
        // User Log Page Start
        setCustomerShow(true);
        setUserShow(true);
        setAccessShow(true);
        //Onload Get Customer list
        commonPaylod.dealerId = 0;
        commonPaylod.value = "customer";
        getCustomer(commonPaylod);
        //Access Data
        setAccess("All");
        setAccessData({ content: userLogAccessData });
      } else if (pathname === "SendLog" || pathname === "FeedLog") {
        // Sent Log
        setCustomerShow(true);
        setFleetShow(true);
        setVehicleShow(true);
        setAlertModeShow(true);
        if (pathname === "FeedLog") {
          setIemiShow(true);
        }
        //Get Region onLoad
        commonPaylod.value = "region";
        getRegion(commonPaylod);
      } else if (pathname === "AuditLog") {
        setUserOrgShow(true);
        setUserListShow(true);
        setCustomerShow(true);
        setFleetShow(true);
        setMainMenuShow(true);
        setSubMenuShow(true);
        setAccessShow(true);

        //Onload
        //User Org List
        let payload = {
          id: resellerId,
          orgType: userOrgType,
        };
        getUserOrg(payload);
        commonPaylod.value = "customer";
        commonPaylod.dealerId = 0;
        getCustomer(commonPaylod);

        let fleetActionPayload = {
          customerId: "0",
          value: "fleet",
          resellerId: resellerId,
        };
        getFleet(fleetActionPayload);
        setAccessData({ content: userLogAccessData });
      } else if (pathname === "VinLog") {
        setSearchbyShow(true);
        setStatusShow(true);
        setTypeShow(true);
        setIemi("");
        setVin("");
        //Onload
        setSearchby("Date");
        setSearchbyData({ content: SearchByVin });
        setStatusData({ content: vinLogStatus });
        setTypeData({ content: typeStatus });
      } else if (pathname === "AirtelLog") {
        setActionShow(true);
        setSearchbyShow(true);
        setSearchby("0");
        setAction("All");
        setSearchbyData({ content: searchByDataAirtel });
        setDateShow(false);
      }
    }else if (userOrgType === 3) {
      if (pathname === "UserLog") {
        // User Log Page Start
        setUserShow(true);
        setAccessShow(true);
        //Onload Get Customer list
        commonPaylod.dealerId = 0;
        commonPaylod.value = "customer";
        getCustomer(commonPaylod);
        //Access Data
        setAccess("All");
        setAccessData({ content: userLogAccessData });
      } else if (pathname === "SendLog" || pathname === "FeedLog") {
        // Sent Log
        setFleetShow(true);
        setVehicleShow(true);
        setAlertModeShow(true);
        if (pathname === "FeedLog") {
          setIemiShow(true);
        }
        //Get Region onLoad
        commonPaylod.value = "region";
        getRegion(commonPaylod);
      } else if (pathname === "AuditLog") {
        setUserOrgShow(true);
        setUserListShow(true);
        setFleetShow(true);
        setMainMenuShow(true);
        setSubMenuShow(true);
        setAccessShow(true);

        //Onload
        //User Org List
        let payload = {
          id: resellerId,
          orgType: userOrgType,
        };
        getUserOrg(payload);
        commonPaylod.value = "customer";
        commonPaylod.dealerId = 0;
        getCustomer(commonPaylod);

        let fleetActionPayload = {
          customerId: "0",
          value: "fleet",
          resellerId: resellerId,
        };
        getFleet(fleetActionPayload);
        setAccessData({ content: userLogAccessData });
      } else if (pathname === "VinLog") {
        setSearchbyShow(true);
        setStatusShow(true);
        setTypeShow(true);
        setIemi("");
        setVin("");
        //Onload
        setSearchby("Date");
        setSearchbyData({ content: SearchByVin });
        setStatusData({ content: vinLogStatus });
        setTypeData({ content: typeStatus });
      } else if (pathname === "AirtelLog") {
        setActionShow(true);
        setSearchbyShow(true);
        setSearchby("0");
        setAction("All");
        setSearchbyData({ content: searchByDataAirtel });
        setDateShow(false);
      }
    }else if (userOrgType === 1) {
      if (pathname === "UserLog") {
        // User Log Page Start
        setUserShow(false);
        setAccessShow(false);
        //Onload Get Customer list
        commonPaylod.dealerId = 0;
        commonPaylod.value = "customer";
        getCustomer(commonPaylod);
        //Access Data
        setAccess("All");
        setAccessData({ content: userLogAccessData });
      } else if (pathname === "SendLog" || pathname === "FeedLog") {
        // Sent Log
        setRegionShow(false);
        setDealerShow(false);
        setCustomerShow(false);
        setFleetShow(false);
        setVehicleShow(true);
        setAlertModeShow(true);
        if (pathname === "FeedLog") {
          setIemiShow(true);
        }
        //Get Region onLoad
        commonPaylod.value = "region";
        getRegion(commonPaylod);
      } else if (pathname === "AuditLog") {
        setUserOrgShow(true);
        setUserListShow(true);
        setFleetShow(true);
        setMainMenuShow(true);
        setSubMenuShow(true);
        setAccessShow(true);

        //Onload
        //User Org List
        let payload = {
          id: resellerId,
          orgType: userOrgType,
        };
        getUserOrg(payload);
        commonPaylod.value = "customer";
        commonPaylod.dealerId = 0;
        getCustomer(commonPaylod);

        let fleetActionPayload = {
          customerId: "0",
          value: "fleet",
          resellerId: resellerId,
        };
        getFleet(fleetActionPayload);
        setAccessData({ content: userLogAccessData });
      } else if (pathname === "VinLog") {
        setSearchbyShow(true);
        setStatusShow(true);
        setTypeShow(true);
        setIemi("");
        setVin("");
        //Onload
        setSearchby("Date");
        setSearchbyData({ content: SearchByVin });
        setStatusData({ content: vinLogStatus });
        setTypeData({ content: typeStatus });
      } else if (pathname === "AirtelLog") {
        setActionShow(true);
        setSearchbyShow(true);
        setSearchby("0");
        setAction("All");
        setSearchbyData({ content: searchByDataAirtel });
        setDateShow(false);
      }
    }
  }, []);

  //User Org
  const handleOrg = (resellerOrgId) => {
    setOrg(resellerOrgId);
    if (resellerOrgId !== "-1") {
      commonPaylod.orgId = resellerOrgId;
      commonPaylod.userOrgType = 8;
      getUserOrg(commonPaylod);
    }
   
  };

  //User Org
  const handleUserOrg = (userOrgId) => {
    setUserOrg(userOrgId);
    if (userOrgId !== "-1") {
      commonPaylod.customerId = userOrgId;
      commonPaylod.orgId = userOrgId;
      commonPaylod.userOrgType = userOrgType;
      commonPaylod.value = "user";
      getUserList(commonPaylod);
    }
  };
  useLayoutEffect(() => {
    if (userOrgDataSuccess) {
      setUserOrgData({ content: userOrgDataList });
    }
  }, [userOrgDataSuccess]);

  useLayoutEffect(() => {
    if (orgDataSuccess) {
      setOrgData({ content: orgDataList });
    }
  }, [orgDataSuccess]);

  const handleRegion = (regionId) => {
    setRegion(regionId);
    if (regionId !== "-1") {
      //get dealer
      commonPaylod.regionId = regionId;
      commonPaylod.value = "dealer";
      getDealer(commonPaylod);
    } else {
      setDealer("-1");
      setDealerData(null);
      setCustomer("-1");
      setCustomerData(null);
    }
  };
  useLayoutEffect(() => {
    if (dealerListSuccess) {
      setDealerData(dealerList.data);
    }
  }, [dealerListSuccess]);

  //Dealer
  const handleDealer = (dealerId) => {
    setDealer(dealerId);
    if (dealerId !== "-1") {
      //get dealer
      commonPaylod.dealerId = dealerId;
      commonPaylod.value = "customer";
      getCustomer(commonPaylod);

      if (pathname === "UserLog") {
        commonPaylod.resellerId = userDataLocal.resellerId;
        commonPaylod.orgId = dealerId;
        getUsers(commonPaylod);
      }

    } else {
      setCustomer("-1");
      setCustomerData(null);
    }
  };
  useLayoutEffect(() => {
    if (customerListSuccess) {
      setCustomerData(customerList.data);
    }
  }, [customerListSuccess]);

  //handleCustomer
  const handleCustomer = (customerId) => {
    setCustomer(customerId);
    if (customerId !== "-1") {
      if (pathname === "UserLog") {
        commonPaylod.resellerId = userDataLocal.resellerId;
        commonPaylod.orgId = customerId;
        getUsers(commonPaylod);
      } else {
        commonPaylod.customerId = customerId;
        commonPaylod.dealerId = "0";
        commonPaylod.value = "fleet";
        getFleet(commonPaylod);
      }
    } else {
      if (pathname === "AuditLog") {
        commonPaylod.customerId = customerId;
        commonPaylod.dealerId = "0";
        commonPaylod.value = "fleet";
        getFleet(commonPaylod);
      } else {
        setFleet("-1");
        setFleetData(null);
      }
      setUser("-1");
    }
  };
  useLayoutEffect(() => {
    if (fleetListSuccess) {
      setFleetData(fleetList.data);
    }
  }, [fleetListSuccess]);


  useLayoutEffect(() => {
    if (usersDataSuccess) {
      setUserListData({content:usersData});
    }
  }, [usersDataSuccess]);

    
  let vehilePayload = {
    value: "orgId",
    searchText: ""
  };

  //handleFleet
  const handleFleet = (fleetId) => {
    setFleet(fleetId);
    if (fleetId !== "-1") {
      vehilePayload.paramValue = fleetId;

      getVehicle(commonPaylod);
    } else {
      setVehicle("-1");
      setVehicleData(null);
    }
  };


  useLayoutEffect(() => {
    if (vehicleListSuccess) {
      setVehicleData(vehicleList.data);
    }
  }, [vehicleListSuccess]);



  //handleVehicle
  const handleVehicle = (vehicleId) => {
    setVehicle(vehicleId);
  };

  //handleiemi

  const handleiemi = (iemiNo) => {
    setIemi(iemiNo);
  };

  //handleAlertMode
  const handleAlertMode = (alertModeId) => {
    setAlertMode(alertModeId);
  };

  //handleAccess
  const handleAccess = (accessId) => {
    setAccess(accessId);
  };

  const handleUserList = (userListId) => {
    setUserList(userListId);
  };

  useEffect(() => {
    getMenuList();
    setLoad(true);
  }, []);

  //List menu
  const getMenuList = () => {
    //const result = menuList.filter(Item => Item.title === Item.title);
    let mainMenu = [];
    for (let i = 0; menuList.length > i; i++) {
      let menu = {
        value: menuList[i].title,
        key: menuList[i].title,
      };
      mainMenu.push(menu);
    }
    setMainMenuData({ content: mainMenu });
    //console.log('menuListss', result.title);
  };

  //handleMainMenu
  const handleMainMenu = (mainMenuId) => {
    setMainMenu(mainMenuId);
    if (mainMenuId !== "-1") {
      const subMenu = menuList.filter((item) => item.title === mainMenuId);
      if (subMenu[0].sub.length > 1) {
        setSubMenu("-1");
        let subMenuList = [];
        for (let i = 0; subMenu[0].sub.length > i; i++) {
          let menu = {
            value: subMenu[0].sub[i].title,
            key: subMenu[0].sub[i].title,
          };
          subMenuList.push(menu);
        }
        setSubMenuData({ content: subMenuList });
      } else {
        setSubMenu("-1");
        setSubMenuData({ content: null });
      }
    } else {
      setSubMenu("-1");
      setSubMenuData({ content: null });
    }
  };

  //handleSubmenu
  const handleSubmenu = (subMenuId) => {
    setSubMenu(subMenuId);
  };

  //handleSubmenu
  const handleSearchBy = (searchbyId) => {
    setSearchby(searchbyId);
    if (searchbyId === "IMEI") {
      setIemiShow(true);
      setDateShow(false);
      setVinShow(false);
      setIemi("-1");
      setVin("");
    } else if (searchbyId === "Date" || searchbyId === "1") {
      // Vin & Airtel Logs Only
      setIemiShow(false);
      setDateShow(true);
      setVinShow(false);
      setIemi("");
      setVin("");
    } else if (searchbyId === "VIN") {
      setIemiShow(false);
      setDateShow(false);
      setVinShow(true);
      setIemi("");
      setVin("-1");
    } else {
      setIemiShow(false);
      setDateShow(false);
      setVinShow(false);
      setIemi("0");
      setVin("0");
    }
  };

  //handleSubmenu
  const handleAction = (actionId) => {
    setAction(actionId);
  };

  //handleStatus
  const handleStatus = (statusId) => {
    setStatus(statusId);
  };

    //handleType
    const handleType = (typeId) => {
      setType(typeId);
    };

  //handleVinno
  const handleVinno = (vinNoId) => {
    setVin(vinNoId);
  };

  //GetDate
  const [fromDate, setFromDate] = useState(
    moment().format("YYYY-MM-DD 00:00:00")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));
  const [activeLabel, setActiveLabel] = useState("today");

  const GetDate = (fromDate, toDate) => {
    setFromDate(fromDate);
    setToDate(toDate);
  };

  const getInnerText = (value, label) =>{
    if(label === "Region"){
      setRegionName(value);
    }else if(label === "Dealer"){
      setDealerName(value);
    }else if(label === "Customer"){
      setCustomerName(value);
    } else if(label === "Fleet"){
      setFleetName(value);
    }else if(label === "User"){
      setUserName(value);
    }else if(label === "Access"){
      setAccessName(value);
    }else if(label === "Main Menu"){
      setMainMenuName(value);
    }else if(label === "Submenu"){
      setSubMenuName(value);
    }else if(label === "User Organization"){
      setUserOrgName(value);
    }else if(label === "User List"){
      setUserListName(value);
    }
  }

  //Onsubmit
  const onSubmit = () => {
    let Formdata = {
      regionId: region,
      dealerId:  userOrgType === 5 ? orgId : dealer,
      customerId: userOrgType === 3 ? orgId : customer,
      fleetId: fleet,
      vehicleId: vehicle,
      alertmodeId: alertMode,
      userId: user,
      accessId: access,
      userOrgId: userOrg,
      useListId: userList,
      mainMenuId: mainMenu,
      subMenuId: subMenu,
      fromData: fromDate,
      toDate: toDate,
      searchbyId: searchby,
      statusId: status,
      iemiId: iemi,
      vinId: vin,
      actionId: action,
      orgType:userOrgType,
      typeId: type,
      regionName: regionName,
      dealerName: dealerName,
      customerName: customerName,
      fleetName: fleetName,
      userName: userName,
      selectedPeriod:activeLabel,
      accessName:accessName,
      mainMenuName:mainMenuName,
      subMenuName:subMenuName,
      userOrgName:userOrgName,
      userListName:userListName,
    };
    getData(Formdata);

  };

  useEffect(() => {
    if (load) {
      onSubmit();
    }
  }, [load]);

  return (
    <StyledPaperShadow
      elevation={0}
      sx={{ display: "inline-block", width: "100%" }}
    >
     {resellerShow && (
        <SingleDropdown
          label={"Organization"}
          listItems={orgData}
          handleChange={handleOrg}
          value={org}
          //isSearch={false}
          showAll={true}
          getInnerText={getInnerText}
        />
      )}
      {userOrgShow && (
        <SingleDropdown
          label={"User Organization"}
          listItems={userOrgData}
          handleChange={handleUserOrg}
          value={userOrg}
          //isSearch={false}
          showAll={true}
          getInnerText={getInnerText}
        />
      )}
      {userListShow && (
        <SingleDropdown
          label={"User List"}
          listItems={{ content: userDataList }}
          handleChange={handleUserList}
          value={userList}
          //isSearch={false}
          showAll={true}
          getInnerText={getInnerText}
        />
      )}

      {regionShow && (
        <SingleDropdown
          label={"Region"}
          listItems={!isError && regionList?.data}
          handleChange={handleRegion}
          value={region}
          showAll={true}
          getInnerText={getInnerText}
        />
      )}

      {dealerShow && (
        <SingleDropdown
          label={"Dealer"}
          listItems={dealerData}
          handleChange={handleDealer}
          value={dealer}
          //isSearch={false}
          showAll={true}
          getInnerText={getInnerText}
        />
      )}

      {customerShow && (
        <SingleDropdown
          label={"Customer"}
          listItems={customerData}
          handleChange={handleCustomer}
          value={customer}
          //isSearch={false}
          showAll={true}
          getInnerText={getInnerText}
        />
      )}

      {fleetShow && (
        <SingleDropdown
          label={"Fleet"}
          listItems={fleetData}
          handleChange={handleFleet}
          value={fleet}
          //isSearch={false}
          showAll={true}
          getInnerText={getInnerText}
        />
      )}

      {vehicleShow && (
        <SingleDropdown
          label={"Vehicle"}
          listItems={vehicleData}
          handleChange={handleVehicle}
          value={vehicle}
          //isSearch={false}
          showAll={true}
          getInnerText={getInnerText}
        />
      )}

      {actionShow && (
        <SingleDropdown
          label={"Action"}
          listItems={{ content: actionData }}
          handleChange={handleAction}
          value={action}
          isSearch={false}
          showAll={true}
          customAllValue={"All"}
          getInnerText={getInnerText}
        />
      )}

      {searchbyShow && (
        <SingleDropdown
          label={"Search By"}
          listItems={searchbyData}
          handleChange={handleSearchBy}
          value={searchby}
          isSearch={false}
          showAll={pathname === "AirtelLog" ? true : false}
          customAllValue={pathname === "AirtelLog" ? "0" : "-1"}
          getInnerText={getInnerText}
        />
      )}

      {iemiShow && (
        <SingleDropdown
          label={"IMEI"}
          listItems={iemiData}
          handleChange={handleiemi}
          value={iemi}
          //isSearch={false}
          showAll={true}
          getInnerText={getInnerText}
        />
      )}

      {alertModeShow && (
        <SingleDropdown
          label={"Alert Mode"}
          listItems={alertModeData}
          handleChange={handleAlertMode}
          value={alertMode}
          isSearch={false}
          showAll={true}
          customAllValue={"0"}
          getInnerText={getInnerText}
        />
      )}

      {userShow && (
        <SingleDropdown
          label={"User"}
          // Show User Log
          listItems={userListData}
          handleChange={handleUserList}

          value={userList}
          //isSearch={false}
          showAll={true}
          getInnerText={getInnerText}
        />
      )}

      {mainMenuShow && (
        <SingleDropdown
          label={"Main Menu"}
          listItems={mainMenuData}
          handleChange={handleMainMenu}
          value={mainMenu}
          //isSearch={false}
          showAll={true}
          getInnerText={getInnerText}
        />
      )}

      {subMenuShow && (
        <SingleDropdown
          label={"Submenu"}
          listItems={subMenuData}
          handleChange={handleSubmenu}
          value={subMenu}
          //isSearch={false}
          showAll={true}
          getInnerText={getInnerText}
        />
      )}

      {accessShow && (
        <SingleDropdown
          label={"Access"}
          listItems={accessData}
          handleChange={handleAccess}
          value={access}
          isSearch={false}
          showAll={true}
          customAllValue={"All"}
          getInnerText={getInnerText}
        />
      )}

      {vinShow && (
        <SingleDropdown
          label={"Vin"}
          //This Vin Log
          listItems={vin}
          handleChange={handleVinno}
          value={vin}
          //isSearch={false}
          showAll={true}
          getInnerText={getInnerText}
        />
      )}
      {dateShow && (
        <DatepickerDropdown
          getDate={GetDate}
          activeLabel={activeLabel}
          showAll={false}
          time={time}
          allowDays={allowDays}
          allowedMonth={allowedMonth}
          createDateStatus={true}
          createDateValue={1}
        />
      )}
      {statusShow && (
        <SingleDropdown
          label={"Status"}
          //This Vin Log
          listItems={statusData}
          handleChange={handleStatus}
          value={status}
          //isSearch={false}
          showAll={true}
          customAllValue={"All"}
          getInnerText={getInnerText}
        />
      )}
      {typeShow && (
        <SingleDropdown
          label={"Type"}
          //This Vin Log
          listItems={typeData}
          handleChange={handleType}
          value={type}
          //isSearch={false}
          showAll={true}
          customAllValue={"0"}
          getInnerText={getInnerText}
        />
      )}
      <StyledFiterButton
        type="button"
        variant="contained"
        size="small"
        color="success"
        onClick={onSubmit}
        sx={{ marginTop: "25px" }}
      >
        Submit
      </StyledFiterButton>
    </StyledPaperShadow>
  );
}

export default LogDropdown;
