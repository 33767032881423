import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "../shared/data_table/DataTable";
import {
  Backdrop,
  Box,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Divider,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  SoftButton,
  StyledCardShadow,
  StyledDropDown,
  StyledFormControl,
  StyledFormControlLabel,
  StyledTableTitle,
  StyledTextField,
} from "../../theme/StyledEle";
import logServices from "../../services/logService";
import AdminDropdown from "../AdminDropdown/AdminDropdown";
import {
  useSimVendorDeleteMutation,
  useSimListMutation,
  useSimActivateMutation,
  useSimSafeCustodyMutation,
  useSimOutOfSafeCustodyMutation,
  useSimDisconnectMutation,
  useResumeFromTempDisconnectMutation,
  useRefreshOrderMutation,
  useSaveWhitelistMutation,
  useSimOnBoardListMutation,
  useGetDropdownListMutation,
  useSimMovetoVendorMutation,
  useAuditLogMutation,
} from "../../store/slice/ApiSlice";
import StyledModel from "../../Utils/StyledModel";
import { useDispatch } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import { useLocation, useNavigate } from "react-router";
import { InvisableLoader } from "../shared/TableLoaders";
import { BeatLoader } from "react-spinners";
import SingleDropdown from "../NewDropdown/SingleDropdown";
import { useLayoutEffect } from "react";

const globalJson1 =
  localStorage.getItem("userData") !== null
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
var globalOrgId = 1;
var globaluserId = 1;
var globalRoleId = 0;
var globalJson = {};
var globalResellerId = 2;
var globalOrgType = 2;
var globalOrgName = "";
var globalUserName = "";
if (
  "null" === localStorage.getItem("userData") ||
  null === localStorage.getItem("userData")
) {
} else {
  globalJson =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : 0;
  globalOrgId = globalJson.orgId;
  globalOrgType = globalJson.userOrgType;
  globaluserId = globalJson.id;
  globalRoleId = globalJson.roleId;
  globalResellerId = globalJson.resellerId;
  globalOrgName = globalJson.orgName;
  globalUserName = globalJson.userName;
}



const ListSim = ({ onViewSim, onEditSim, menuUser, tabValue }) => {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [savedData, setSavedData] = useState({});
  const [loading, setLoading] = useState("");
  const [dialogContent, setDialogContent] = useState({});
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [open, setOpen] = useState(false);
  const [loadStatus, setLoadStatus] = useState(true);
  const [arraySortOrder, setArraySortOrder] = useState({});
  const [selectedDropdownAry, setSelectedDropdownAry] = useState([]);
  const [commonFilter, setCommonFilter] = useState({
    excel: false,
    pdf: false,
  });
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [deleteItem, setDeleteItem] = useState(null);
  const [vendorList, setVendorList] = useState(null);
  const [selectedvendor, setSelectedVendor] = useState(null);
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(null);

const [auditLog] = useAuditLogMutation();
let auditPayload = {
  //Audit Common Payload
  orgId: globalOrgId,
  userId: globaluserId, 
  orgName: globalOrgName, 
  userName: globalUserName,
};

  const columns = useMemo(
    () => [

      {
        Header: "SIM Number",
        accessor: "simNumber",
      },
      {
        Header: "Mobile Number",
        accessor: "mobileNumber",
      },
      {
        Header: "Provider Name",
        accessor: "providerName",
      },
      {
        Header: "Plan Name",
        accessor: "planName",
      },
      {
        Header: "Status",
        accessor: "simStatusval",
      },
      {
        Header: "Assign To Device",
        accessor: "imei",
      },
      {
        Header: "Last Connected",
        accessor: "vehGPSdate",
      },
      {
        Header: "Vehicle Status",
        accessor: "gpsStatus",
      },
      {
        Header: "Created Date",
        accessor: "strCreatedDate",
      },
      {
        Header: "SIM WhiteListed",
        accessor: "simWhiteListing",
      },
      {
        Header: "SIM WhiteListed No",
        accessor: "simWhiteListNo",
      },
      {
        Header: "Assigned to Vendor",
        accessor: "vendorName",
      },
      {
        Header: "Action",
        accessor: "buttonEditDeleteView",
      },
    ],
    []
  );

  const onView = async (handleSimView) => {
    onViewSim(handleSimView);

    auditPayload.message = "Admin > Sim > List > View";
    auditPayload.action = "View";
    auditPayload.featureName = "Sim";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  const onEdit = async (handleSimEdit) => {
    onEditSim(handleSimEdit);

    auditPayload.message = "Admin > Sim > List > Edit";
    auditPayload.action = "Edit";
    auditPayload.featureName = "Sim";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  };

  const handleSort = (e, sortColumn, sortVal) => {
    var obj1 = {};
    obj1 = { ...arraySortOrder };
    var obj = { sortColumn };
    var orderdir = "desc";
    var seltdsrtngcolumn = "";
    if (obj.sortColumn.id === "simNumber") {
      if (obj1.simNumber !== undefined && obj1.simNumber === "asc") {
        obj1.simNumber = "desc";
        orderdir = "desc";
      } else {
        obj1.simNumber = "asc";
        orderdir = "asc";
      }
      seltdsrtngcolumn = "SIM_NUMBER";
    } else if (obj.sortColumn.id === "mobileNumber") {
      if (obj1.mobileNumber !== undefined && obj1.mobileNumber === "asc") {
        obj1.mobileNumber = "desc";
        orderdir = "desc";
      } else {
        obj1.mobileNumber = "asc";
        orderdir = "asc";
      }
      seltdsrtngcolumn = "MOBILE_NUMBER";
    }
    setArraySortOrder(obj1);
    //fetchOrgDetails("", seltdsrtngcolumn, orderdir);
  };
  const onDelete = (itemDeleted) => {
    setDeleteItem(itemDeleted);
    navigate('#deviceDelte');

  };

  
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "simOnBoard/simListXLS.xls?vendorOrgId=" +
        payload.vendorOrgId +
        "&deviceId=" +
        payload.deviceId +
        "&statusId=" +
        payload.statusId +
        "&orderdir=" +
        payload.orderdir +
        "&columnName=" +
        payload.columnName +
         "&resellerId=" +
        payload.resellerId +
        "&orgId=" +
        payload.orgId +
        "&orgType=" +
        payload.orgType +
        " ";
      window.open(url);

      auditPayload.message = "Admin > Sim > List > Export > Excel";
      auditPayload.action = "Download";
      auditPayload.featureName = "Sim";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {
          if (undefined !== blob) {
            var _url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            var link = document.body.appendChild(a);
            link.download = "SimList";
            link.href = _url;
            link.method = "post";
            link.click();
            window.URL.revokeObjectURL(_url);
            a.removeAttribute("download");
            a.removeAttribute("post");
            a.removeAttribute("href");
            document.body.removeChild(a);
          } else {
            //alert("unAuthorized error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };
  const onChangePdf = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "simOnBoard/simListPDF.pdf?vendorOrgId=" +
        payload.vendorOrgId +
        "&deviceId=" +
        payload.deviceId +
        "&statusId=" +
        payload.statusId +
        "&orderdir=" +
        payload.orderdir +
        "&columnName=" +
        payload.columnName +
        "&orgType=" +
        payload.orgType +
        " ";
      window.open(url);

      auditPayload.message = "Admin > Sim > List > Export > PDF";
      auditPayload.action = "Download";
      auditPayload.featureName = "Sim";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {
          if (undefined !== blob) {
            var _url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            var link = document.body.appendChild(a);
            link.download = "SimList";
            link.href = _url;
            link.method = "post";
            link.click();
            window.URL.revokeObjectURL(_url);
            a.removeAttribute("download");
            a.removeAttribute("post");
            a.removeAttribute("href");
            document.body.removeChild(a);
          } else {
            //alert("unAuthorized error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType;

  const [
    getSimList,
    { data: simlistData, isLoading: getSimLoading, isSuccess: getSimSuccess },
  ] = useSimOnBoardListMutation();

  const [
    getVendorList,
    {
      data: movetovendordata,
      isLoading: vendorLoading,
      isSuccess: vendorSuccess,
    },
  ] = useGetDropdownListMutation();

  let commonPayload = {};
  let [payload, setPayload] = useState(null);
  // Onload and Submit
  const getData = (data) => {
    var dataStr = {};
    dataStr.edit = false;
    dataStr.delete = false;
    dataStr.view = false;
    dataStr.excel = false;
    dataStr.pdf = false;
    var strval;
    strval = menuUser.split(",");
    for (var i = 0; i < strval.length; i++) {
      if (parseInt(strval[i]) === 4) {
        dataStr.edit = true;
      }
      if (parseInt(strval[i]) === 2) {
        dataStr.view = true;
      }
      if (parseInt(strval[i]) === 3) {
        dataStr.delete = true;
      }
      if (parseInt(strval[i]) === 6) {
        dataStr.pdf = true;
      }
      if (parseInt(strval[i]) === 5) {
        dataStr.excel = true;
      }
    }
    setCommonFilter(dataStr);
    commonPayload.statusId = 0;
    commonPayload.columnName = "created_date";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.orderdir = "desc";
    commonPayload.search = "";
    commonPayload.deviceId = 0;
    commonPayload.orgType = orgType;
    commonPayload.resellerId = orgId;
    commonPayload.orgId =orgId;
    commonPayload.vendorOrgId =data.vendor!== null && data.vendor!== "-1" ? data.vendor:0;

    getSimList(commonPayload);
    setPayload(commonPayload);

    let vendorPayload = {
      id: orgId,
      value: "vendor",
      orgType: orgType,
      searchText: "",
    };
    getVendorList(vendorPayload);
  };

  const handleSelectedVendor = (vendorId) => {
    setSelectedVendor(vendorId);
  };

  useEffect(() => {
    if (getSimSuccess && vendorSuccess) {
      setResponseData([]);
      if (simlistData.data !== null) {
      setResponseData(simlistData.data.content);
      setTotalElements(simlistData.data.totalElements);
      setTotalPages(simlistData.data.totalPages);

      setVendorList(movetovendordata?.data);

      setSelectedVendor(movetovendordata?.data?.content[0].key);
    }
  }
  }, [getSimSuccess, vendorSuccess]);

  useEffect(() => {
    auditPayload.message = "Admin > Sim > List > View";
    auditPayload.action = "View";
    auditPayload.featureName = "Sim";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  }, []);


  const pageChange = (newPage, rowsPerPage) => {
    
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    getSimList(payload);
  };

  const handleSearch = (searchText) => {

    payload.search = searchText;
    payload.columnName = "PLAN_NAME";
    if (payload.search.length >= 3) {
      payload.page = 0;
      payload.size = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getSimList(payload);
    } else if (payload.search.length === 0) {
      getSimList(payload);
      payload.page = 0;
      payload.size = 25;
      setNewPage(0);
      setRowsPerPage(25);
    }
  };

  const [
    delteItem,
    {
      data: deleteData,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useSimVendorDeleteMutation();

  const deleteItems = async() => {
    let payload = {
      id: deleteItem.id,
    };
    navigate(location.pathname);
    const deleteres = await delteItem(payload);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (isDeleteSuccess) {
      setDeleteItem(null);
      dispatch(
        snackService([
          deleteData?.resultMessage,
          deleteData?.resultText,
          isDeleteSuccess,
        ])
      );
      getSimList(payload);
    }
  }, [isDeleteSuccess]);

  //Sim Send & White List Function
  const [commondType, setCommonType] = useState(0);
  const [simCommond, setSimCommond] = useState(0);
  const [plan, setPlan] = useState(0);
  const [testPlan, setTestPlan] = useState(0);
  const [selectRows, setSeletedRows] = useState(null);
  const [selectRowId, setSelectRowId] = useState(null);
  const [supportType, setSupportType] = useState("support");
  const [supportNo, setSupportNo] = useState("9790706090");

  const [openDel, setOpenDel] = useState(false);

  //get row data from data table
  const getRow = (selectRow, rowId) => {
    setSelectRowId(rowId);
    setSeletedRows(selectRow);
  };

  const [
    saveSimActivation,
    {
      data: simsaveactdata,
      isLoading: getSimActLoading,
      isSuccess: getSimActSuccess,
    },
  ] = useSimActivateMutation();

  const [
    saveSimSafe,
    {
      data: simsaveSafedata,
      isLoading: getSimSafeLoading,
      isSuccess: getSimSafeSuccess,
    },
  ] = useSimSafeCustodyMutation();

  const [
    saveSimOutOfSafe,
    {
      data: simsaveOutOfSafedata,
      isLoading: getSimOutOfSafeLoading,
      isSuccess: getSimOutOfSafeSuccess,
    },
  ] = useSimOutOfSafeCustodyMutation();

  const [
    saveSimDisconnect,
    {
      data: simsaveDisconnectdata,
      isLoading: getSimDisconnectLoading,
      isSuccess: getSimDisconnectSuccess,
    },
  ] = useSimDisconnectMutation();

  const [
    saveSimResumeFromTempDisconnect,
    {
      data: simsaveResumeFromTempDisconnectdata,
      isLoading: getSimResumeFromTempDisconnectLoading,
      isSuccess: getSimResumeFromTempDisconnectSuccess,
    },
  ] = useResumeFromTempDisconnectMutation();

  const [
    saveMovetoVendor,
    {
      data: saveMovetoVendorData,
      isLoading: getWhitelistLoading,
      isSuccess: MovetoVendorSuccess,
    },
  ] = useSimMovetoVendorMutation();

  const handleSend = () => {
    let mobArry = [];
    let simArry = [];
    let imeiArry = [];

    var payload = {};
    payload.deviceSimNo = simArry.toString();
    payload.deviceMobileNo = mobArry.toString();
    payload.devIMEI = imeiArry.toString();
    payload.planCode = plan;
    payload.testPlan = testPlan;
    payload.orgId = resellerId;
    payload.orgType = orgType;
    payload.userId = userId;
    if (simCommond === 0) {
      // simActivate
      saveSimActivation(payload);
    } else if (simCommond === 1) {
      // simSafeCustody
      saveSimSafe(payload);
    } else if (simCommond === 2) {
      // simOutOfSafeCustody
      saveSimOutOfSafe(payload);
    } else if (simCommond === 3) {
      // simDisconnect
      saveSimDisconnect(payload);
    } else if (simCommond === 4) {
      // resumeFromTempDisconnect
      saveSimResumeFromTempDisconnect(payload);
    }
  };
  const [movetoVendor, setMovetoVendor] = useState(false);

  const handleWhiteList = () => {
    setMovetoVendor(true);
    navigate('#move');
  };

  // const whiteListItems = () => {
  //   let payload = {
  //     simMobNum: selectRows[0]?.values.mobileNumber,
  //     userMobNum: "",
  //     supportMobileNum: supportNo,
  //     whiteListVal: supportType,
  //   };
  //   saveWhitelist(payload);
  // };

  const moveSimtoVendor = () => {
    let mobArry = [];
    for (let i = 0; i < selectRows.length; i++) {
      mobArry.push(selectRows[i].values.simNumber);
    }
    console.log(JSON.stringify(mobArry));

    const formData = new FormData();
    formData.append("simNumber", mobArry);
    formData.append("vendorId", selectedvendor);

    saveMovetoVendor(formData);
  };
  //Success
  useEffect(() => {
    if (MovetoVendorSuccess) {
      setMovetoVendor(false);
      setMessage(saveMovetoVendorData?.resultMessage);
      setStatus(saveMovetoVendorData?.resultText);
      dispatch(
        snackService([
          saveMovetoVendorData?.resultMessage,
          saveMovetoVendorData?.resultText,
          MovetoVendorSuccess,
        ])
      );
      getData(payload);
    }
  }, [MovetoVendorSuccess]);

  return (
    <>
      <InvisableLoader
        isLoading={
          getSimActLoading ||
          getSimSafeLoading ||
          getSimOutOfSafeLoading ||
          getSimDisconnectLoading ||
          getSimResumeFromTempDisconnectLoading ||
          getWhitelistLoading
        }
      />
      <AdminDropdown getData={getData} vendorDrop={true} createDateStatus={false}/>

      <StyledCardShadow elevation={0} sx={{ mt: 2 }}>
        {/* <CardHeader title={<StyledTableTitle>Sim List</StyledTableTitle>} /> */}
        <Box sx={{ padding: "15px" }}>
          <Collapse
            in={selectRows?.length > 0}
            sx={{
              border: `.5px solid ${theme.palette.divider}`,
              borderRadius: "10px",
            }}
          >
            <Box sx={{ px: 2, py: 1, my: 0.5 }}>
              <Stack
                direction={"row"}
                spacing={1}
                alignItems={"end"}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Stack
                  direction={"row"}
                  alignItems={"end"}
                  spacing={1.5}
                  sx={{ Width: "100%" }}
                ></Stack>
                <SoftButton
                  color="info"
                  className="info"
                  onClick={handleWhiteList}
                >
                  Move To Vendor
                </SoftButton>
              </Stack>
            </Box>
          </Collapse>
        </Box>
        <CardContent sx={{ py: 0 }}>
          <DataTable
            includeSearchBox={true}
            handleChange={handleSearch}
            onChangeXcl={onChangeXcl}
            onChangePdf={onChangePdf}
            onEdit={onEdit}
            onView={onView}
            onDelete={onDelete}
            columns={columns}
            data={responseData}
            styles={{ maxWidth: "100vw" }}
            numberOfStickyColumns={1}
            page={newPage}
            rowsPerPage={rowsPerPage}
            callbackChangePage={pageChange}
            totalElements={totalElements}
            callbck={handleSort}
            totalPages={totalPages}
            commonFilter={commonFilter}
            isLoading={getSimLoading}
            checkbox={tabValue === 0 ? false : true}
            selectRow={getRow}
          />
        </CardContent>
      </StyledCardShadow>
      {location.hash === '#deviceDelte' && (
        <StyledModel data={deleteItem} deleteItem={deleteItems} open={openDel}>
          <Box sx={{ px: 2 }}>
            <Typography sx={{ textAlign: "center", my: 2 }}>
              Sim No: {deleteItem.simNumber}
            </Typography>
          </Box>
        </StyledModel>
      )}
      {location.hash === '#move' && (
        <StyledModel
          // data={movetoVendor}
          movetoVendor={true}
          deleteItem={moveSimtoVendor}
        >
          <Box sx={{ px: 2 }}>
            <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
              <SingleDropdown
                label={"Vendor"}
                listItems={vendorList}
                handleChange={handleSelectedVendor}
                value={selectedvendor}
                // isSearch={dropDownSearch}
              />
            </StyledFormControl>
          </Box>
        </StyledModel>
      )}
    </>
  );
};

export default ListSim;
